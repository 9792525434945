<script setup>
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const props = defineProps({
  activeSection: Array,
});

const techs = [
  {
    img: 'https://oss-cn-beijing.galbot.com/online/portal/img/work2.jpg',
    isPlaying: false,
  },
  {
    img: 'https://oss-cn-beijing.galbot.com/online/portal/img/work1.jpg',
    isPlaying: false,
  },
];
</script>

<template>
  <div class="applic" :class="{ 'applic-zh': locale === 'zh' }">
    <div class="title" id="title-1">
      <div :class="{ 'fade-up': props.activeSection.includes('title-1') }">
        {{ t('careers.working.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-1') }"
    >
      {{ t('careers.working.decs') }}
    </div>
    <div
      v-for="(item, index) in techs"
      class="content"
      :key="index"
      :class="{ 'fade-up': props.activeSection.includes('title-1') }"
    >
      <img :src="item.img" alt="" class="picture" />
      <div class="imgDecs">
        {{ t(`careers.working.list[${index}]`) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.applic {
  .title {
    width: 60%;
    margin: 0 auto;
    color: #fff;
    font-family: Arial;
    font-size: 0.33rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-bottom: 0.14rem;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    margin-top: 0.14rem;
    font-family: MyRegular;
    color: rgba(180, 188, 208, 0.55);
    font-size: 0.15rem;
    font-style: italic;
    font-weight: 600;
    line-height: 0.2rem;
    margin: 0 auto;
    opacity: 0;
    text-align: center;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .content {
    padding: 0.16rem 0.24rem 0 0.24rem;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 2s, transform 1.5s;
    .picture {
      // width: 100%;
      // height: 100%;
      display: block;
      width: 3.82rem;
      height: 2.62rem;
      border-radius: 0.1rem;
    }
    .imgDecs {
      margin: 0.18rem 0 0.08rem;
      font-family: MyRegular;
      font-size: 0.14rem;
      line-height: 0.21rem;
      color: #b4bcd0;
      font-weight: 300;
      opacity: 0.9;
    }
    &:last-of-type .imgDecs {
      margin-bottom: 0.5rem;
    }
  }
}
.applic-zh {
  .title {
    font-size: 0.3rem;
    font-family: MyNotoRegular;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-size: 0.14rem;
    font-weight: 400;
    font-style: normal;
  }
  .content .imgDecs {
    font-family: MyNoto;
    font-size: 0.14rem;
    line-height: 0.22rem;
  }
}
</style>
