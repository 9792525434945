<script setup lang="ts">
import { onMounted, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';

import Read from '@/assets/ic_read_normal.png';
import Arrow from '@/assets/ic_arrow_hover.png';

import { getNewsList } from '@/api';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});
const router = useRouter();

const news: any = ref([]);

const pageObj = reactive({ page: 1, page_size: 100000 });

const itemsToShowInitially = 3;

const displayedItems: any = ref([]);

const canLoadMore = ref(false);
const upMore = ref(false);

/**
 * @description 获取新闻列表
 */
const getNews = async () => {
  try {
    let res: any = await getNewsList(pageObj);
    if (res.code === 200) {
      news.value = res.data.list;
      displayedItems.value = res.data.list.slice(0, itemsToShowInitially);
      canLoadMore.value = displayedItems.value.length < news.value.length;
    }
  } catch (error) {
    console.log(error);
  }
};

const jumpLink = (item: any) => {
  window.open(item.url);
};

const jumpHandle = () => {
  router.replace('/news');
};

onMounted(() => {
  getNews();
});
</script>

<template>
  <div class="news" :class="{ 'news-zh': locale === 'zh' }">
    <div class="title" id="title-6">
      <div :class="{ 'fade-up': props.activeSection.includes('title-6') }">
        {{ t('about.news.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-6') }"
    >
      {{ t('about.news.decs') }}
    </div>
    <div class="news-list">
      <div
        class="list-slide"
        v-for="(item, index) in displayedItems"
        :key="index"
        @click="jumpLink(item)"
      >
        <div class="slide-title">
          {{ locale === 'en' ? item.eng_title : item.title }}
        </div>
        <div class="slide-content">
          {{ locale === 'en' ? item.eng_content : item.content }}
        </div>
        <a class="slide-footer" target="_blank">
          <span>{{ locale === 'en' ? 'Read' : '阅读' }}</span>
          <img :src="Arrow" alt="" />
        </a>
      </div>
    </div>
    <div v-if="canLoadMore" class="more" @click="jumpHandle">
      <span v-if="!upMore">{{ locale === 'en' ? 'More' : '查看更多' }}</span>
      <img
        class="more"
        :class="`${upMore ? 'upMore' : ''}`"
        :src="Read"
        alt=""
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.news {
  margin-top: 0.39rem;
  .title {
    color: #fff;
    font-family: Arial;
    font-size: 0.33rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    width: 3rem;
    padding: 0.12rem 0.24rem 0 0.24rem;
    font-family: MyRegular;
    color: rgba(180, 188, 208, 0.55);
    font-size: 0.15rem;
    font-style: italic;
    font-weight: 600;
    line-height: 0.2rem;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .news-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.1rem 0.24rem 0.21rem 0.24rem;
    box-sizing: border-box;
    .list-slide {
      cursor: pointer;
      position: relative;
      width: 3.82rem;
      min-height: 1.9rem;
      padding: 0.24rem 0.3rem;
      box-sizing: border-box;
      background: url('../../../../assets/phone_bg4.png') no-repeat;
      background-size: 100% 100%;
      margin-bottom: 0.1rem;
      transition: background 0.3s ease;
      -webkit-tap-highlight-color: transparent;
      tap-highlight-color: transparent;
      outline: none;
      &:focus {
        outline: none;
        background: transparent;
        -webkit-tap-highlight-color: transparent;
      }
      &:active {
        background: transparent;
        outline: none;
        -webkit-tap-highlight-color: transparent;
      }
      .slide-title {
        width: 100%;
        margin-bottom: 0.12rem;
        font-family: MyRegular;
        font-size: 0.24rem;
        color: #d6dbe8;
        line-height: 0.28rem;
        font-weight: 600;
        white-space: nowrap; /* 强制在一行显示 */
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .slide-content {
        font-family: MyRegular;
        font-size: 0.14rem;
        color: #b4bcd0;
        line-height: 0.2rem;
        font-weight: 300;
        opacity: 0.9;
      }
      .slide-footer {
        cursor: pointer;
        position: absolute;
        z-index: 9999;
        z-index: 1;
        font-family: MyRegular;
        font-size: 0.14rem;
        bottom: 0.21rem;
        right: 0.13rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        justify-self: center;
        color: #b4bcd0;
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
        tap-highlight-color: transparent;
        outline: none;
        &:focus {
          outline: none;
          background: transparent;
          -webkit-tap-highlight-color: transparent;
        }
        &:active {
          background: transparent;
          outline: none;
          -webkit-tap-highlight-color: transparent;
        }
        img {
          width: 0.15rem;
          height: 0.13rem;
          margin-left: 0.05rem;
          margin-right: 0.1rem;
        }
      }
      &:hover {
        background: url('../../../../assets/bg4.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .more {
    cursor: pointer;
    width: 1.2rem;
    z-index: 9999;
    z-index: 1;
    font-family: Verdana;
    font-size: 0.18rem;
    float: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-self: center;
    color: #fff;
    text-decoration: none;
    box-sizing: border-box;
    margin-right: 0.24rem;
    -webkit-tap-highlight-color: transparent !important;
    tap-highlight-color: transparent !important;
    outline: none;
    &:focus {
      outline: none;
      background: transparent;
      -webkit-tap-highlight-color: transparent;
    }
    &:active {
      background: transparent;
      outline: none;
      -webkit-tap-highlight-color: transparent;
    }
    img {
      width: 0.24rem;
      height: 0.24rem;
      margin-left: 0.1rem;
      margin-right: 0.1rem;
      transform: rotate(0deg);
    }
    .upMore {
      transform: rotate(-90deg);
    }
  }
}
.news-zh {
  .title {
    font-size: 0.3rem;
    font-family: MyNotoRegular;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-size: 0.14rem;
    font-weight: 400;
    font-style: normal;
  }
  .more {
    font-family: MyNoto;
    font-weight: 400;
  }
  .news-list .list-slide {
    .slide-title {
      font-family: MyNoto;
      font-size: 0.23rem;
    }
    .slide-content {
      font-family: MyNoto;
      font-size: 0.13rem;
    }
    .slide-footer {
      span {
        font-family: MyNoto;
      }
    }
  }
}
</style>
