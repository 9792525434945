<template>
  <div class="home" :class="{ 'home-zh': locale === 'zh' }" ref="scrollSection">
    <Navbar v-if="showNavBar" />
    <Navbar
      :class="`bgcNav ${showBgcBar && !showNavBar ? 'bgc-active' : ''}`"
    />
    <div class="home-banner" id="banner" :style="dynamicStyle">
      <video
        autoplay
        loop
        muted
        poster="https://oss-cn-beijing.galbot.com/online/portal/img/poster1.png"
      >
        <source
          src="https://oss-cn-beijing.galbot.com/online/portal/video/home1-1.mp4"
          type="video/mp4"
        />
      </video>
      <div class="text">
        <div class="text-div" :class="fadeClass">
          <!-- <p>{{ t('hello') }}</p>
          <p>{{ t('welcome') }}</p> -->
          <div>
            <span>{{ t('home.banner[0]') }}</span
            ><span v-if="locale === 'en'">&nbsp;</span>{{ t('home.banner[1]') }}
          </div>
          <div>{{ t('home.banner[2]') }}</div>
          {{ t('home.banner[3]') }}
        </div>
      </div>
    </div>
    <div class="main">
      <Scene :activeSection="activeSection" :sections="sections" />
      <Product :activeSection="activeSection" :sections="sections" />
      <Footer />
    </div>
    <FooterCookie />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import FooterCookie from '@/components/FooterCookie.vue';
import Product from './product.vue';
import Scene from './myscene.vue';

import { isSafari, getOssUrl, setVhProperty, observeFun } from '@/utils';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const sections = ref(['title-1', 'title-2']);
const activeSection = ref([]);
let observers = [];
const scrollSection = ref(null);
const fixedDiv = ref(null);
const fadeClass = ref('');
const showNavBar = ref(true);
const showBgcBar = ref(false);
const dynamicStyle = ref();

const setStyle = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  if (isSafari()) {
    dynamicStyle.value = setVhProperty();
  } else {
    dynamicStyle.value = '';
  }
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const viewportHeight = document
    .getElementById('banner')
    .getBoundingClientRect().height;
  if (scrollTop > viewportHeight / 1.8) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

const handleMousemove = (event) => {
  const topThreshold = 64; // 定义顶部区域的阈值
  if (event.clientY <= topThreshold) {
    showBgcBar.value = true;
  } else {
    showBgcBar.value = false;
  }
};
const intGsap = () => {
  gsap.registerPlugin(ScrollTrigger);
  // 创建时间轴实例
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.scene',
      start: 'top 1%',
      end: '+=250%', // 增加滚动范围
      scrub: 1, // 调整为更大的值使滚动更慢
      pin: true,
      markers: false,
      toggleActions: 'play reverse play reverse',
    },
  });
  const numArr = [0, 0, 0, 0];
  // 添加动画到时间轴，并使用不同的缓动效果
  tl.fromTo(
    '.list-active-1',
    { y: 500, opacity: 0 },
    {
      y: 0,
      opacity: 1,
      duration: 2,
      ease: 'none',
    }
  )
    .fromTo(
      '.list-active-2',
      { y: 500, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 2,
        ease: 'none',
      }
    )
    .fromTo(
      '.list-active-3',
      { y: 500, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: 2,
        ease: 'none',
      }
    );
  gsapTranslateY(tl);
};

/**
 * @description 图片平移动画
 */
const gsapTranslateY = (tl) => {
  tl.add(
    gsap.fromTo(
      '.list-item-0',
      { filter: 'brightness(100%)', scale: 1 },
      {
        filter: 'brightness(30%)',
        scale: 0.9,
        duration: 2,
        ease: 'none',
      }
    ),
    0 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
  )
    .add(
      gsap.fromTo(
        '.list-item-1',
        { filter: 'brightness(100%)', top: 1240 },
        {
          top: 0,
          duration: 2,
          filter: 'brightness(100%)',
          ease: 'none',
        }
      ),
      0 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.list-item-1',
        { filter: 'brightness(100%)', scale: 1 },
        {
          filter: 'brightness(30%)',
          scale: 0.9,
          duration: 2,
          ease: 'none',
        }
      ),
      2 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.list-item-2',
        { top: 1240 },
        {
          top: 0,
          duration: 2,
          ease: 'none',
        }
      ),
      2 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.list-item-2',
        { filter: 'brightness(100%)', scale: 1 },
        {
          filter: 'brightness(30%)',
          scale: 0.9,
          duration: 2,
          ease: 'none',
        }
      ),
      4 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.list-item-3',
        { top: 1240 },
        {
          top: 0,
          duration: 2,
          ease: 'none',
        }
      ),
      4 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    );
  // .add(
  //   gsap.fromTo(
  //     '.list-item-2',
  //     { height: '100%' },
  //     { height: '0%', duration: 2, ease: 'none' }
  //   ),
  //   4 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
  // );
};

const preloadVideo = () => {
  const videos = document.querySelectorAll('video');
  videos.forEach((video) => {
    video.preload = 'auto';
    video.load();
  });
};

onMounted(() => {
  preloadVideo();
  setTimeout(() => {
    fadeClass.value = 'fade-up';
  }, 500);
  intGsap();
  setStyle();
  // getWinSize
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', setStyle);
  window.addEventListener('mousemove', handleMousemove);
  observeFun(sections, activeSection, observers);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', setStyle);
  window.removeEventListener('mousemove', handleMousemove);
  observers.forEach((observer) => observer.disconnect());
});
</script>

<style lang="scss" scoped>
:root {
  --vh: 100vh;
}
.home {
  width: 100%;
  &-banner {
    position: relative;
    z-index: 0;
    width: 100%;
    // max-height: calc(var(--vh, 1vh) * 100);
    max-height: 1440px;
    overflow: hidden;
    &::after {
      position: absolute;
      left: 0px;
      bottom: 0;
      content: '';
      height: 5px;
      width: 100%;
      background: #111112;
      // background: red;
      z-index: 1;
    }
    img {
      // aspect-ratio: 16 / 9;
      width: 100%;
      height: 100%;
    }
    video {
      aspect-ratio: 16 / 9;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      width: 100%;
      padding: 0 100px;
      box-sizing: border-box;
      position: absolute;
      max-height: calc(var(--vh, 1vh) * 100);
      top: 0px;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);

      > div {
        position: absolute;
        opacity: 0;
        width: 810px;
        color: #fff;
        font-family: MyRegular;
        font-size: 58px;
        font-style: normal;
        font-weight: 400;
        line-height: 65px; /* 112.069% */
        bottom: 10%;
        transform: translateY(80%);
        transition: opacity 1.5s, transform 1s;
        > div {
          margin-bottom: 10px;
        }
        span {
          background: linear-gradient(
            to right,
            #a264ff,
            #83a7ff,
            #cef7ff,
            #eefcff,
            #ffffff
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
        }
      }
    }
    .fade-up {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
  .main {
    max-width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
    .scene,
    .product {
      // background-size: contain; /* 确保背景图片不溢出容器 */
      // width: 100%;
    }
  }
}
.home-zh {
  .text {
    > div {
      font-size: 60px;
      font-family: MyNotoRegular;
      font-weight: 400;
    }
  }
}
@media (min-width: 769px) and (max-width: 2559px) {
  .home {
    &-banner {
      &::after {
        bottom: 2px;
      }
    }
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 1230px) {
  .home {
    &-banner {
      .text {
        max-width: 100%;
        div {
          width: 80%;
          font-size: 42px;
        }
      }
    }
    .main {
      max-width: 100%;
      margin: 0 auto;
      > .footerStyle {
        padding-top: 0px;
      }
    }
  }
}
</style>
