<script setup lang="ts">
import { reactive, ref, onMounted } from 'vue';
import Read from '@/assets/ic_read_normal.png';
// import Arrow from '@/assets/ic_arrow.png';

import { getRolesList } from '@/api';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});

const pageObj: any = reactive({ page: 1, page_size: 100 });

const tableList: any = ref([]);

/**
 * @description 获取新闻列表
 */
const getRoles = async () => {
  try {
    let res = await getRolesList(pageObj);
    if (res.code === 200) {
      tableList.value = res.data;
    }
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};

const tolink = (item: any) => {
  window.open(item.url);
};

onMounted(() => {
  getRoles();
});
</script>

<template>
  <div class="roles" :class="{ 'roles-zh': locale === 'zh' }">
    <div class="title" id="title-2">
      <div :class="{ 'fade-up': props.activeSection.includes('title-2') }">
        {{ t('careers.positions.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-2') }"
    >
      {{ t('careers.positions.decs') }}
    </div>
    <div class="roles-content">
      <template v-for="(table, index) in tableList">
        <div class="table" v-if="table.list.length" :key="index">
          <div class="table-head">
            <div class="head-title">
              {{ locale === 'zh' ? table.cn_category : table.category }}
            </div>
            <a
              class="head-more"
              target="_blank"
              :href="
                table.category === 'Social Recruitment' ||
                table.category === '社会招聘'
                  ? 'https://owm6ymi5v9b.jobs.feishu.cn/870797'
                  : 'https://owm6ymi5v9b.jobs.feishu.cn/584984/'
              "
            >
              <span>{{ locale === 'zh' ? '查看更多' : 'More' }}</span>
              <img :src="Read" alt="" />
            </a>
          </div>
          <div class="table-body">
            <div class="body-line"></div>
            <div class="table-body-content">
              <template v-for="item in table.list">
                <div class="table-body-item" @click="tolink(item)">
                  <div class="name">
                    {{ locale === 'zh' ? item.cn_post_name : item.post_name }}
                  </div>
                  <div class="base">
                    {{ locale === 'zh' ? item.cn_base_loc : item.base_loc }}
                  </div>
                  <div class="apply">
                    <span>{{ locale === 'zh' ? '立即申请' : 'Apply' }}</span>
                    <div class="arrow"></div>
                  </div>
                </div>
                <div class="item-line"></div>
              </template>
            </div>
            <div class="body-line"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.roles {
  padding-bottom: 68px;
  .title {
    color: #fff;
    font-family: Arial;
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .subtitle {
    margin-top: 12px;
    color: rgba(180, 188, 208, 0.37);
    font-family: MyRegular;
    font-size: 23px;
    font-style: italic;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    padding-top: 50px;
    padding-bottom: 10px;
    .table {
      margin-bottom: 100px;
      &-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .head-title {
          padding: 0 30px;
          box-sizing: border-box;
          text-align: center;
          font-family: Arial;
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
          background: linear-gradient(180deg, #ebf2f5 42.11%, #c5d0d8 97.75%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .head-more {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          justify-self: center;
          color: #fff;
          font-family: Helvetica;
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          text-decoration: none;
          img {
            width: 35px;
            height: 35px;
            margin-left: 14px;
            // transform: rotate(-90deg);
          }
          &:hover {
            color: #fff;
          }
        }
      }
      &-body {
        &-content {
          width: 100%;
          height: 243px;
          overflow-x: hidden;
          overflow-y: auto;
          .table-body-item {
            padding: 24px 0px;
            padding-left: 30px;
            padding-right: 10px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            // font-family: ArialMT, 'Arial';
            // font-size: 20px;
            // color: #979ea6;
            color: #b4bcd0;

            font-family: MyRegularLight;
            font-size: 25px;
            font-style: normal;
            font-weight: 300;
            line-height: 32px;
            opacity: 0.9;
            text-align: justify;
            .name {
              width: 33.3%;
              white-space: nowrap; /* 强制文本在一行内显示 */
              overflow: hidden; /* 隐藏超出的文本 */
              text-overflow: ellipsis; /* 使用省略号显示超出的文本 */
            }
            &:hover {
              cursor: pointer;
              color: #ffffff;
              .apply span {
                color: #ffffff;
              }
              .apply .arrow {
                width: 17px;
                height: 17px;
                background: url('../../../../assets/ic_arrow_hover.png')
                  no-repeat;
                background-size: 100% 100%;
              }
            }
            .apply {
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                position: relative;
                color: #979ea6;
                text-decoration: none;
                margin-right: 14px;
                z-index: 10;
              }
              .arrow {
                width: 17px;
                height: 17px;
                background: url('../../../../assets/ic_arrow.png') no-repeat;
                background-size: 100% 100%;
              }
            }
          }
          .item-line {
            position: relative;
            width: 100%;
            height: 1px;
            border: none;
            background: linear-gradient(
              to right,
              transparent,
              #bcbcbc,
              #bcbcbc,
              #bcbcbc,
              #bcbcbc,
              #bcbcbc,
              #bcbcbc,
              #bcbcbc,
              transparent
            );
            opacity: 0.2;
          }
        }
        .body-line {
          position: relative;
          width: 100%;
          left: -50px;
          margin: 0 auto;
          height: 1px;
          border: none;
          background: linear-gradient(
            to right,
            transparent,
            #777,
            #777,
            #777,
            #777,
            #777,
            transparent
          );
        }
      }
    }
    .table:last-child {
      margin-bottom: 0;
    }
  }
}
.roles-zh {
  .title {
    font-family: MyNotoRegular;
    font-size: 68px;
    font-weight: 400;
  }
  .subtitle {
    font-family: MyNoto;
    font-weight: 400;
    font-size: 23px;
    font-style: normal;
  }
  .roles-content {
    .table-head .head-title {
      font-family: MyNoto;
      font-weight: 400;
      font-size: 36px;
    }
    .table-head .head-more {
      font-family: MyNoto;
      font-weight: 400;
      font-size: 32px;
    }
    .table-body-content .table-body-item {
      font-family: MyNoto;
      font-weight: 300;
      font-size: 25px;
    }
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 1230px) {
  .roles {
    .title {
      font-size: 50px;
    }
    &-content {
      .table-body {
        .body-line {
          width: 110%;
        }
      }
    }
  }
}
</style>
