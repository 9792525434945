<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import Close from '@/assets/close.svg';

import { useLanguageStore } from '@/store/languageStore';

const { t } = useI18n();

const language = useLanguageStore();

const router = useRouter();
// 检查 Cookie 是否已经存在
const display = ref(true);
const checkCookie = () => {
  return document.cookie
    .split(';')
    .some((item) => item.trim().startsWith('cookieConsent='));
};

// 设置 Cookie
const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

const clickHandle = () => {
  setCookie('cookieConsent', 'true', 365); // 设置 365 天有效期的 Cookie
  display.value = true;
};

const toAgreement = (path) => {
  router.replace(path);
};

onMounted(() => {
  console.log(!checkCookie(), '----');
  if (language.ip === '国外') {
    // 检查是否已经同意
    if (!checkCookie()) {
      // 如果没有同意，显示弹框
      display.value = false;
    } else {
      display.value = true;
    }
  }
});
</script>

<template>
  <div class="cookie" :class="{ display }">
    <img :src="Close" alt="" class="cookie-close" @click="display = true" />
    <div class="cookie-content">
      {{ t(`cookie[0]`) }}
      <span @click="toAgreement('/privacy')">{{ t(`cookie[1]`) }}</span>
    </div>
    <div class="cookie-button" @click="clickHandle">
      {{ t(`cookie[2]`) }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cookie {
  position: fixed;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0.3rem;
  width: 4rem;
  padding: 0.3rem 0.15rem;
  background: #fff;
  border-radius: 0.1rem;
  box-sizing: border-box;
  transition: bottom 0.5s ease-in-out, opacity 0.5s ease-in-out;
  &-close {
    position: absolute;
    right: 0.15rem;
    top: 0.12rem;
    height: 0.12rem;
    width: 0.12rem;
  }
  &-content {
    color: rgba(18, 18, 18, 0.6);
    opacity: 0.6;
    font-family: MyRegularLight;
    font-size: 0.14rem;
    font-style: normal;
    font-weight: 300;
    line-height: 0.2rem;
    span {
      font-size: 600;
      font-family: MyRegularLight;
      color: #121212;
      font-style: italic;
      text-decoration-line: underline;
    }
  }
  &-button {
    margin-top: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.77rem;
    height: 0.52rem;
    flex-shrink: 0;
    border-radius: 0.06rem;
    background: linear-gradient(113deg, #a264ff 13.52%, #83a7ff 80.24%);
    color: #fff;
    font-family: MyRegularLight;
    font-size: 0.2rem;
    font-style: normal;
    font-weight: 600;
  }
}
.display {
  opacity: 0;
  bottom: -8rem;
}
</style>
