<template>
  <nav
    class="navbar"
    :class="{ 'navbar-zh': locale === 'zh', 'fade-down': fadeClass !== '' }"
  >
    <div class="navbar-content">
      <!-- <div class="logo" @click="navigateToHome">
        <img class="logoIMG"  alt="" />
      </div>  会有白边 -->
      <div
        class="logo"
        @click="navigateToHome"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
      >
        <img class="logoIMG" :src="hover ? actlogo : logo" alt="Logo" />
      </div>
      <ul class="navlist">
        <li
          v-for="(item, index) in navlist"
          :key="index"
          :class="['navbar-item', { active: index === activeIndex }]"
          @click="navigateTo(item, index)"
        >
          {{ t(`menu[${index}]`) }}
        </li>
        <div class="language">
          <div
            @click="changeZh"
            class="language-item noto"
            :class="{ 'language-active': locale === 'zh' }"
          >
            中文
          </div>
          <span>|</span>
          <div
            @click="changeEn"
            class="language-item"
            :class="{ 'language-active': locale === 'en' }"
          >
            English
          </div>
        </div>
      </ul>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useLanguageStore } from '@/store/languageStore';

import logo from '@/assets/logo_white.png';
import actlogo from '@/assets/logo_color.png';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const languageStore = useLanguageStore();
const router = useRouter();
const route = useRoute();
const clientWidth = ref(document.body.clientWidth);
const fadeClass = ref('');
const navlist = [
  {
    name: 'About',
    path: '/about',
  },
  {
    name: 'News',
    path: '/news',
  },
  {
    name: 'Careers',
    path: '/careers',
  },
];
const changeZh = () => {
  console.log(route, '----');
  locale.value = 'zh';
  languageStore.setLanguage('zh');
  // 设置页面标题
  if (route.meta.title) {
    document.title = route.meta.titleZh;
  }
  // 设置描述和关键词
  const metaDescription = document.querySelector('meta[name="description"]');
  const metaKeywords = document.querySelector('meta[name="keywords"]');

  if (metaDescription) {
    metaDescription.setAttribute('content', route.meta.descriptionZh || '');
  }

  if (metaKeywords) {
    metaKeywords.setAttribute('content', route.meta.keywordsZh);
  }
};
const changeEn = () => {
  locale.value = 'en';
  languageStore.setLanguage('en');
  // 设置页面标题
  if (route.meta.title) {
    document.title = route.meta.title;
  }
  // 设置描述和关键词
  const metaDescription = document.querySelector('meta[name="description"]');
  const metaKeywords = document.querySelector('meta[name="keywords"]');

  if (metaDescription) {
    metaDescription.setAttribute('content', route.meta.description || '');
  }

  if (metaKeywords) {
    metaKeywords.setAttribute('content', route.meta.keywords);
  }
};
const activeIndex = ref(-1);
const hover = ref(false); // 新增hover状态

const navigateTo = (item, index) => {
  activeIndex.value = index;
  router.push(item.path);
};

const navigateToHome = () => {
  activeIndex.value = -1;
  router.push('/');
};

const updateActiveIndex = () => {
  const currentPath = route.path;
  const index = navlist.findIndex((item) => item.path === currentPath);
  activeIndex.value = index;
};

watch(route, updateActiveIndex);

const handleResize = () => {
  clientWidth.value = document.body.clientWidth;
};

onMounted(() => {
  setTimeout(() => {
    fadeClass.value = 'fade-down';
  }, 500);
  updateActiveIndex();
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped lang="scss">
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 58px;
  // background: rgba(17, 17, 18, 0.4);
  // backdrop-filter: blur(10px);
  color: white;
  transform: translateY(-100%);
  opacity: 0;
  transition: opacity 1s, transform 0.6s;
}
.fade-down {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
.bgcNav {
  top: -84px;
  transition: top 0.3s ease;
  z-index: 999;
}
.bgc-active {
  top: 0;
  // background-image: url('../assets/nav_bgc.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  backdrop-filter: blur(10px);
  background: rgba(17, 17, 18, 0.4);
  transition: top 0.6s ease;
}

.navbar-content {
  max-width: 100%;
  padding: 0 100px;
  box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 100%;
}

.logo {
  cursor: pointer;
  width: 191px;
  height: 16px;
  border: none;
}

.logoIMG {
  width: 100%;
  height: 100%;
  border: none; /* 确保没有边框 */
  display: block; /* 确保图片占据整个元素 */
  margin: 0; /* 去掉默认外边距 */
  padding: 0; /* 去掉默认内边距 */
  background-color: transparent; /* 确保背景为透明 */
  transition: transform 0.3s ease; /* 过渡动画 */
  // background-image: url("@/assets/logo_white.png");
}
// .logo:hover {
//   .logoIMG {
//   background-image: url("@/assets/logo_color.png");
//   }
// }
.navlist {
  display: flex;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
  .language {
    font-family: Arial;
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    display: flex;
    span {
      opacity: 0.7;
    }
    &-item {
      cursor: pointer;
      opacity: 0.6;
      margin: 0 10px;
      &:hover {
        opacity: 1;
      }
    }
    &-item:last-child {
      position: relative;
      top: 1px;
    }
    .noto {
      font-family: MyNoto;
    }
    &-active {
      opacity: 1;
      font-family: Arial;
      font-size: 16px;
      color: #ffffff;
      font-weight: 400;
    }
  }
}

.navbar-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  transition: color 0.3s ease;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }

  &:last-child {
    padding-right: 0;
  }
}
.navbar-zh .navbar-item {
  font-family: MyNotoRegular;
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 960px) {
  .navbar-content {
    // max-width: 1200px;
  }
}
@media (min-width: 1440px) and (max-width: 1680px) {
  .navbar-item {
    font-size: 20px !important;
  }
  .language-item {
    font-size: 19.5px !important;
  }
}
/* 1280px - 1439px */
@media (min-width: 1280px) and (max-width: 1439px) {
  .navbar-item {
    font-size: 22px !important;
  }
  .language-item {
    font-size: 20px !important;
  }
}
@media (min-width: 769px) and (max-width: 1279px) {
  .navbar-item {
    font-size: 24px !important;
  }
  .language-item {
    font-size: 22px !important;
  }
}
</style>
