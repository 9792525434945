import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import zh from './locales/zh.json';
import { createPinia } from 'pinia';
import vConsole from 'vconsole'; // 导入 vConsole

import { getUserAgent } from '@/utils/index';
import { getUserLanguage } from './api';
import { useLanguageStore } from './store/languageStore';

import './style.css';
import App from './App.vue';
import router from './router'; // 导入路由配置

// 开发环境下启用 vConsole，仅限手机端
if (process.env.NODE_ENV !== 'production' && getUserAgent() === 'phone') {
  new vConsole();
}

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);

// 初始化 i18n 的函数
const setupLanguage = async () => {
  const languageStore = useLanguageStore();
  try {
    // 获取用户语言，若失败则使用默认语言
    const userLanguage = await getUserLanguage();
    languageStore.setLanguage(userLanguage || 'en'); // 设置用户语言，默认英文
    languageStore.setIp(userLanguage === 'en' ? "国外" : "国内"); // 设置用户语言，默认英文
    const messages = {
      en,
      zh,
    };

    const i18n = createI18n({
      locale: userLanguage || 'en', // 设置默认语言为用户语言或英文
      fallbackLocale: 'en', // 当用户语言不可用时使用的后备语言
      messages, // 语言包
      silentTranslationWarn: false, // 禁用翻译警告
      silentFallbackWarn: false, // 禁用回退警告
    });

    // 挂载 i18n 及 router
    app.use(i18n).use(router).mount('#app');
  } catch (error) {
    console.error('Error setting up language:', error);
  }
};

// 初始化语言设置后再挂载应用
setupLanguage();
