<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { onMounted, onUnmounted, ref } from 'vue';

import Navbar from '@/components/NavbarPhone.vue';
import RouterModal from '@/components/RouterModal.vue';
import FormModal from '@/components/FormModal.vue';
import Footer from '@/components/FooterPhone.vue';
import CookieModal from '@/components/CookieModal.vue';

import { useLanguageStore } from '@/store/languageStore';

const { t, locale } = useI18n();
const language = useLanguageStore();
const showNavBar = ref(true);
const showMenu = ref(false);
const showForm = ref(false);

const closeHandle = () => {
  showMenu.value = false;
};

// 定义一个函数获取当前设备的宽度
const getWinSize = () => {
  let width = document.documentElement.clientWidth;
  // 假设设计稿宽度为750px
  // 假设已知根元素我们设置为100px（这里设置100方便后续我们好计算）
  // 动态设置根元素html的fontSize
  document.documentElement.style.fontSize = 100 * (width / 430) + 'px';
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const viewportHeight = (document as any)
    .getElementById('main')
    .getBoundingClientRect().height;
  if (scrollTop > viewportHeight + 50) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

onMounted(() => {
  getWinSize();
  //当页面窗口视图改变的时候再次执行
  window.onresize = getWinSize;
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>
<template>
  <div class="privacy" :class="{ 'privacy-zh': locale === 'zh' }">
    <!-- <First />
    <Two />
    <Three /> -->
    <Navbar v-if="showNavBar" @change="showMenu = true" />
    <div class="privacy-main">
      <div class="main-content">
        <div class="title" id="main">{{ t('agreement.privacy.title1') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.decs1[0]') }}
        </div>
        <div class="decs">
          {{ t('agreement.privacy.decs1[1]') }}
        </div>
        <div class="sub-title">{{ t('agreement.privacy.sub1.title') }}</div>
        <div class="decs margin">
          {{ t('agreement.privacy.sub1.p[0]') }}
          <li>
            {{ t('agreement.privacy.sub1.p[1]') }}
          </li>
          <li>
            {{ t('agreement.privacy.sub1.p[2]') }}
          </li>
        </div>
        <div v-if="language.ip === '国外'" class="decs">
          {{ t('agreement.privacy.sub1.p[3]') }}
          <li>
            {{ t('agreement.privacy.sub1.p[4]') }}
          </li>
          <li>
            {{ t('agreement.privacy.sub1.p[5]') }}
          </li>
        </div>
        <div class="sub-title">{{ t('agreement.privacy.sub2.title') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.sub2.p[0]') }}
          <li>{{ t('agreement.privacy.sub2.p[1]') }}</li>
          <li>{{ t('agreement.privacy.sub2.p[2]') }}</li>
        </div>
        <div class="sub-title">{{ t('agreement.privacy.sub3.title') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.sub3.p[0]') }}
        </div>
        <div class="sub-title">{{ t('agreement.privacy.sub4.title') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.sub4.p[0]') }}
        </div>
        <div class="title mg">{{ t('agreement.privacy.title2') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.decs2[0]') }}
        </div>
        <div class="sub-title">{{ t('agreement.privacy.sub5.title') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.sub5.p[0]') }}
        </div>
      </div>
    </div>

    <Footer @change="showForm = true" />
    <RouterModal :class="showMenu ? 'active' : ''" @close="closeHandle" />
    <FormModal
      :class="showForm ? 'active-form' : ''"
      @close="showForm = false"
    />
    <CookieModal />
  </div>
</template>

<style scoped lang="scss">
.privacy {
  width: 100%;
  &-main {
    max-width: 100%;
    margin-top: 0.37rem;
    box-sizing: border-box;
    .main-content {
      width: 100%;
      padding: 0 0.24rem;
      box-sizing: border-box;
      margin-bottom: 0.5rem;
      .title {
        font-family: Arial;
        font-size: 0.33rem;
        font-style: normal;
        font-weight: 400;
        background: linear-gradient(
          105deg,
          #c59eff 12.27%,
          #a1bcff 32.02%,
          #eefcff 53.62%,
          #fff 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        margin-bottom: 0.18rem;
      }
      .sub-title {
        font-family: MyRegular;
        font-size: 0.24rem;
        font-weight: 400;
        background: linear-gradient(180deg, #fff 42.11%, #c5d0d8 97.75%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 0.25rem;
        margin-bottom: 0.14rem;
      }
      .decs {
        color: #a4abbd;
        font-family: MyRegularLight;
        font-size: 0.14rem;
        font-style: normal;
        font-weight: 300;
        line-height: 0.2rem; /* 141.667% */
        li {
          position: relative;
          padding-left: 0.3rem;
          list-style: none;
          &::after {
            position: absolute;
            top: 0.13rem;
            left: 0.15rem;
            content: '';
            width: 0.05rem;
            height: 0.05rem;
            background: #b4bcd0;
            border-radius: 0.1rem;
          }
        }
      }
      .margin {
        margin-bottom: 0.24rem;
      }
      .mg {
        margin-top: 0.3rem;
      }
    }
  }
}
.privacy-zh{
  .privacy-main{
    .main-content{
      .title{
        font-family: MyNotoRegular;
      }
      .decs{
        font-family: MyNoto;
      }
    }
  }
}
</style>
