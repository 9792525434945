<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});

const techs = ref([
  {
    img: 'https://oss-cn-beijing.galbot.com/online/portal/img/pic_a1.png',
    isPlaying: false,
  },
  {
    img: 'https://oss-cn-beijing.galbot.com/online/portal/img/pic_b1.png',
    isPlaying: false,
  },
]);

// const togglePlay = (id: string) => {
//   techs.value.forEach((item, index) => {
//     const video: any = document?.getElementById(`video-${index}`);
//     if (!video.paused) {
//       item.isPlaying = false;
//       video.pause();
//     }
//     if (id === `video-${index}`) {
//       if (video.paused) {
//         video.requestFullscreen();
//         video.play();
//         item.isPlaying = true;
//       } else {
//         video.pause();
//       }
//     }
//   });
// };

const handleFullscreenChange = () => {
  const doc: any = document;
  if (
    !doc.fullscreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.msFullscreenElement
  ) {
    techs.value.forEach((item, index) => {
      const video: any = document?.getElementById(`video-${index}`);
      if (!video.paused) {
        video.pause();
        item.isPlaying = false;
      }
    });
    // 这里添加退出全屏时的处理逻辑
  }
};

onMounted(() => {
  document.addEventListener('fullscreenchange', handleFullscreenChange);
  document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
  document.addEventListener('mozfullscreenchange', handleFullscreenChange);
  document.addEventListener('msfullscreenchange', handleFullscreenChange);
});

onBeforeUnmount(() => {
  document.removeEventListener('fullscreenchange', handleFullscreenChange);
  document.removeEventListener(
    'webkitfullscreenchange',
    handleFullscreenChange
  );
  document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
  document.removeEventListener('msfullscreenchange', handleFullscreenChange);
});
</script>

<template>
  <div class="applic" :class="{ 'applic-zh': locale === 'zh' }">
    <div class="title" id="title-1">
      <div :class="{ 'fade-up': props.activeSection.includes('title-1') }">
        {{ t('careers.working.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-1') }"
    >
      {{ t('careers.working.decs') }}
    </div>
    <div class="applic-content">
      <div
        class="applic-list"
        :class="{ 'fade-up': props.activeSection.includes('title-1') }"
      >
        <div class="list-slide" v-for="(item, index) in techs" :key="index">
          <div class="slide-source">
            <img :src="item.img" alt="" />
            <!-- <video :id="`video-${index}`" :poster="Pic3">
              <source :src="Map4" type="video/mp4" />
            </video>
            <div class="overlay" v-if="!item.isPlaying">
              <div class="play-button" @click="togglePlay(`video-${index}`)">
                ▶
              </div>
            </div> -->
          </div>
          <div class="slide-decs">
            {{ t(`careers.working.list[${index}]`) }}
          </div>
        </div>
      </div>
      <!-- <swiper :slidesPerView="2" :spaceBetween="40" class="applicSwiper">
        <swiper-slide
          class="techSwiper-slide"
          v-for="(item, index) in techs"
          :key="index"
        >
          <div class="slide-source">
            <img :src="item.img" alt="" /> -->
      <!-- <video :id="`video-${index}`" :poster="Pic3">
              <source :src="Map4" type="video/mp4" />
            </video>
            <div class="overlay" v-if="!item.isPlaying">
              <div class="play-button" @click="togglePlay(`video-${index}`)">
                ▶
              </div>
            </div> -->
      <!-- </div>
          <div class="slide-decs">
            {{ item.decs }}
          </div>
        </swiper-slide>
      </swiper> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.applic {
  max-width: 1640px !important;
  padding-top: 46px;
  margin: 0 auto;
  .title {
    color: #fff;
    font-family: Arial;
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    margin-top: 12px;
    color: rgba(180, 188, 208, 0.37);
    font-family: MyRegular;
    font-size: 23px;
    font-style: italic;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    padding-top: 45px;
    padding-bottom: 118px;
    .applic-list {
      width: 100% !important;
      display: grid;
      grid-template-columns: repeat(2, minmax(200px, 788px));
      grid-gap: 0 60px;
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 2s, transform 1.5s;
      .list-slide {
        max-width: 788px !important;
      }
      .slide-source {
        position: relative;
        max-width: 788px !important;
        border-radius: 10px;
        video {
          position: relative;
          cursor: pointer;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          z-index: -1;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          transition: opacity 0.3s;
          z-index: 100;
        }

        .play-button {
          font-size: 3em;
          color: #fff;
          cursor: pointer;
        }
        img {
          position: relative;
          top: 0;
          left: 0;
          width: 100% !important;
          cursor: pointer;
          border-radius: 10px;
          z-index: 10;
        }
      }
      .slide-source:hover .overlay {
        opacity: 1;
      }
      .slide-decs {
        display: block;
        width: 100%;
        margin: 24px 0;
        color: #b4bcd0;
        font-family: MyRegularLight;
        font-size: 23px;
        font-style: normal;
        font-weight: 300;
        line-height: 32px;
        opacity: 0.9;
      }
    }
    .applicSwiper {
      // &::after {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   height: 316px;
      //   left: 94%;
      //   background: linear-gradient(to left, rgba(0, 0, 0, 0.6), transparent);
      //   pointer-events: none; /* 使阴影不影响点击事件 */
      //   z-index: 99999;
      // }
      .techSwiper-slide {
        width: 788px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        .slide-source {
          position: relative;
          width: 100% !important;
          height: 504px;
          border-radius: 10px;
          img {
            width: 100%;
            height: 100%;
          }
          video {
            position: relative;
            cursor: pointer;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            z-index: -1;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity 0.3s;
            z-index: 100;
          }

          .play-button {
            font-size: 3em;
            color: #fff;
            cursor: pointer;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            max-height: 425px;
            cursor: pointer;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            z-index: 10;
          }
        }
        .slide-source:hover .overlay {
          opacity: 1;
        }
        .slide-decs {
          display: block;
          width: 100%;
          margin: 24px 0;
          color: #b4bcd0;
          font-family: MyRegular;
          font-size: 25px;
          font-style: normal;
          font-weight: 300;
          opacity: 0.9;
          line-height: 32px;
        }
      }
    }
  }
}
.applic-zh {
  .title {
    font-family: MyNotoRegular;
    font-size: 68px;
    font-weight: 400;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-weight: 400;
    font-size: 23px;
    font-style: normal;
  }
  .applic-content .applic-list .slide-decs {
    font-family: MyNoto;
    font-size: 20px;
    font-weight: 300;
    line-height: 36px;
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 906px) and (max-width: 1000px) {
  .applic-content .applic-list {
    // grid-template-columns: repeat(2, minmax(750px, 1fr));
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 905px) {
  .applic-content .applic-list {
    // grid-template-columns: repeat(2, minmax(630px, 1fr));
  }
}
</style>
