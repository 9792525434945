<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Controller } from 'swiper/modules';
import Play from '@/assets/play.png';
import { useI18n } from 'vue-i18n';
import { isIOS, isWeChatBrowser } from '@/utils';

import { getInfocardList } from '@/api';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});
const pageObj: any = reactive({ page: 1, page_size: 100 });
const videoList = ref<any[]>([]);
const mySwiper: any = ref(null);
const currentIndex = ref(0);
const isIosFlag = ref(false);
const Plyrs = ref<any>([]);

const togglePlay = (id: string) => {
  videoList.value.forEach((item, index) => {
    const video: any = document.getElementById(`video-${index}`);
    if (id === `video-${index}`) {
      console.log(video.paused, 'video.paused');
      if (video.paused) {
        video.play();
        item.isPlaying = true;
        video.controls = true;
      }
      if (!isWeChatBrowser()) {
        Plyrs.value[index].on('exitfullscreen', (event: any) => {
          console.log(event);
          video.pause();
          item.isPlaying = false;
          video.controls = false;
        });
      } else {
        // 监听全屏变化事件
        handleFullscreenChange(video, item);
      }
    }
    video.addEventListener('pause', () => {
      // 暂停按钮被点击时执行的代码
      item.isPlaying = false;
      video.controls = false;
    });
  });
};
// 定义处理全屏变化的函数
const handleFullscreenChange = (video: any, item: any) => {
  onScreenChange(video, item);
};

//判断进入退出全屏
const checkIsFullScreen = () => {
  const isFullScreen =
    document.fullscreen ||
    (document as any).mozFullScreen ||
    (document as any).webkitIsFullScreen;
  return isFullScreen == undefined ? false : isFullScreen;
};

const onFullscreenchange = (video: any, item: any) => {
  if (checkIsFullScreen()) {
    console.log('进入全屏');
  } else {
    console.log('退出全屏');
    video.pause();
    item.isPlaying = false;
    video.controls = false;
  }
};

// 监听全屏或退出全屏
const onScreenChange = (video: any, item: any) => {
  document.addEventListener('fullscreenchange', function () {
    onFullscreenchange(video, item);
  });

  /* Firefox */
  document.addEventListener('mozfullscreenchange', function () {
    onFullscreenchange(video, item);
  });

  /* Chrome, Safari 和 Opera */
  document.addEventListener('webkitfullscreenchange', function () {
    onFullscreenchange(video, item);
  });

  /* IE / Edge */
  document.addEventListener('msfullscreenchange', function () {
    onFullscreenchange(video, item);
  });
};

const setControlledSwiper = (swiper: any) => {
  mySwiper.value = swiper;
};

const onSlideChange = (swiper: any) => {
  currentIndex.value = swiper.activeIndex;
  console.log('Current index:', swiper.activeIndex);
};

const getVideoList = async () => {
  try {
    let res: any = await getInfocardList(pageObj);
    if (res.code === 200) {
      let tempArr: any = [];
      res.data.list.forEach((item: any) => {
        item.isPlaying = false;
        tempArr.unshift(item);
      });
      videoList.value = tempArr;
      console.log(videoList, 'videoList');
      if (!isWeChatBrowser()) {
        const plyr0 = new Plyr(`#video-0`, {
          controls: ['play', 'fullscreen'],
          fullscreen: {
            enabled: true,
          },
        });
        const plyr1 = new Plyr(`#video-1`, {
          controls: ['play', 'fullscreen'],
          fullscreen: {
            enabled: true,
          },
        });
        const plyr2 = new Plyr(`#video-2`, {
          controls: ['play', 'fullscreen'],
          fullscreen: {
            enabled: true,
          },
        });
        const plyr3 = new Plyr(`#video-3`, {
          controls: ['play', 'fullscreen'],
          fullscreen: {
            enabled: true,
          },
        });
        const plyr4 = new Plyr(`#video-4`, {
          controls: ['play', 'fullscreen'],
          fullscreen: {
            enabled: true,
          },
        });
        Plyrs.value = [plyr0, plyr1, plyr2, plyr3, plyr4];
      }
      if (isIOS()) {
        isIosFlag.value = true;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  getVideoList();
});
</script>

<template>
  <div class="technology" :class="{ 'technology-zh': locale === 'zh' }">
    <div class="title" id="title-4">
      <div :class="{ 'fade-up': props.activeSection.includes('title-4') }">
        {{ t('about.technology.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-4') }"
    >
      {{ t('about.technology.decs') }}
    </div>
    <div class="technology-content">
      <Swiper
        :slidesPerView="1.4"
        :spaceBetween="20"
        :controller="{ control: mySwiper }"
        :modules="[Controller]"
        :observer="true"
        @slideChange="onSlideChange"
        @swiper="setControlledSwiper"
        class="technologySwiper"
        :class="`${
          currentIndex === videoList.length - 1 ? 'left-shadow' : 'right-shadow'
        }`"
      >
        <SwiperSlide
          class="techSwiper-slide"
          v-for="(item, index) in videoList"
          :key="index"
        >
          <div class="slide-source">
            <img v-if="!item.isPlaying" :src="item.photo_h5_url" alt="" /> "
            <video
              :poster="item.photo_h5_url"
              :src="item.video_h5_url"
              :id="`video-${index}`"
              :class="`${isIosFlag ? 'ios-video' : ''}`"
              controlsList="nodownload"
              controls
              muted
            ></video>
            <div
              class="overlay"
              v-if="!item.isPlaying"
              @click="togglePlay(`video-${index}`)"
            >
              <img :src="Play" class="play-button" />
            </div>
          </div>
          <div class="slide-decs">
            {{ locale === 'zh' ? item.content : item.eng_content }}
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.technology {
  .title {
    color: #fff;
    font-family: Arial;
    font-size: 0.33rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    padding: 0.12rem 0.24rem 0 0.24rem;
    font-family: MyRegular;
    color: rgba(180, 188, 208, 0.55);
    font-size: 0.15rem;
    font-style: italic;
    font-weight: 600;
    line-height: 0.2rem;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    padding: 0.17rem 0.24rem 0.5rem 0.24rem;
    box-sizing: border-box;
    .technologySwiper {
      .techSwiper-slide {
        .slide-source {
          position: relative;
          width: 2.6rem !important;
          height: 1.78rem;
          border-radius: 0.1rem;
          -webkit-tap-highlight-color: transparent !important;
          tap-highlight-color: transparent !important;
          outline: none;
          &:focus {
            outline: none;
            background: transparent;
            -webkit-tap-highlight-color: transparent;
          }
          &:active {
            background: transparent;
            outline: none;
            -webkit-tap-highlight-color: transparent;
          }
          video {
            position: absolute;
            left: 0;
            top: 0;
            cursor: pointer;
            width: 100%;
            object-fit: contain;
            height: 100%;
            border-radius: 0.1rem;
            -webkit-tap-highlight-color: transparent !important;
            tap-highlight-color: transparent !important;
            outline: none;
            &:focus {
              outline: none;
              background: transparent;
              -webkit-tap-highlight-color: transparent;
            }
            &:active {
              background: transparent;
              outline: none;
              -webkit-tap-highlight-color: transparent;
            }
          }
          .ios-video {
            z-index: -1;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              to top,
              rgba(0, 0, 0, 0.1) 0%,
              #fff 100%
            );
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1;
            transition: opacity 0.3s;
            z-index: 10003;
            -webkit-tap-highlight-color: transparent !important;
            tap-highlight-color: transparent !important;
            outline: none;
            &:focus {
              outline: none;
              background: transparent;
              -webkit-tap-highlight-color: transparent;
            }
            &:active {
              background: transparent;
              outline: none;
              -webkit-tap-highlight-color: transparent;
            }
          }

          .play-button {
            position: relative;
            width: 0.4rem;
            height: 0.4rem;
            cursor: pointer;
          }
          > img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 3.16rem;
            cursor: pointer;
            border-radius: 0.1rem;
            z-index: 10002;
          }
        }
        .slide-source:hover .overlay {
          opacity: 1;
        }
        .slide-decs {
          margin: 0.2rem 0;
          font-family: MyRegular;
          font-size: 0.14rem;
          line-height: 0.2rem;
          color: #b4bcd0;
          font-style: normal;
          font-weight: 300;
          opacity: 0.9;
        }
      }
    }
    .left-shadow {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 80%;
        background: linear-gradient(to right, rgba(19, 19, 20), transparent);
        pointer-events: none; /* 使阴影不影响点击事件 */
        z-index: 100;
      }
    }
    .right-shadow {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 80%;
        background: linear-gradient(to left, rgba(19, 19, 20), transparent);
        pointer-events: none; /* 使阴影不影响点击事件 */
        z-index: 100;
      }
    }
  }
}
.technology-zh {
  .title {
    font-size: 0.3rem;
    font-family: MyNotoRegular;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-size: 0.14rem;
    font-weight: 400;
    font-style: normal;
  }
  .technology-content {
    .technologySwiper {
      .techSwiper-slide {
        .slide-decs {
          font-family: MyNoto;
          font-size: 0.13rem;
        }
      }
    }
  }
}
@media (max-width: 769px) {
  .technology-content {
    .technologySwiper {
      .techSwiper-slide {
        .overlay {
          background: none !important;
        }
      }
    }
  }
}
</style>
<style>
.plyr {
  position: absolute;
  width: 100%;
  border-radius: 0.1rem;
  left: 0;
  top: 0;
  z-index: 10001 !important;
}
.plyr__video-wrapper {
  min-height: 1.78rem !important;
  height: 100% !important;
}
.plyr--video .plyr__controls {
  z-index: 1003 !important;
}
.plyr__control--overlaid {
  z-index: 1002 !important;
}
.plyr__controls .plyr__controls__item:first-child {
  margin-right: 0 !important;
  margin-left: auto !important;
}
.plyr__control:focus-visible {
  outline: none !important;
}
.plyr--video .plyr__control:focus-visible,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded='true'] {
  background: none !important;
}
</style>
