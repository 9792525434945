<template>
  <div class="news" :class="{ 'news-zh': locale === 'zh' }" ref="scrollSection">
    <Navbar v-if="showNavBar" />
    <Navbar
      :class="`bgcNav ${showBgcBar && !showNavBar ? 'bgc-active' : ''}`"
    />
    <div
      class="news-banner"
      id="banner"
      :style="dynamicStyle"
      @click="bannerJump"
    >
      <img class="mb" :src="Mb" alt="" />
      <video
        v-if="bannerData.length && bannerData[0].video_pc_url !== ''"
        autoplay
        loop
        muted
        :poster="bannerData[0].photo_pc_url"
      >
        <source :src="bannerData[0].video_pc_url" type="video/mp4" />
      </video>
      <img
        v-if="bannerData.length && bannerData[0].video_pc_url === ''"
        src="https://oss-cn-beijing.galbot.com/online/portal/img/poster1.png"
        alt=""
      />
      <div
        v-if="bannerData.length === 0 || bannerData[0].video_pc_url === ''"
        class="empty"
      ></div>
      <div class="text">
        <div class="text-div" :class="fadeClass">
          <!-- <p>{{ t('hello') }}</p>
          <p>{{ t('welcome') }}</p> -->
          <div v-if="bannerData.length" class="text-main">
            {{
              locale === 'en'
                ? bannerData[0].eng_content
                : bannerData[0].content
            }}
            <!-- <img class="read" :src="Read" alt="" /> -->
          </div>
          <div v-if="bannerData.length">
            <span class="tags"
              >{{
                locale === 'en' ? bannerData[0].eng_title : bannerData[0].title
              }}
            </span>
            <span class="time">{{
              locale === 'en'
                ? dayjs(bannerData[0].publish_time).format('MMMM D, YYYY')
                : dayjs(bannerData[0].publish_time).format('YYYY年MM月DD日')
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="news-main">
      <div style="width: 100%; height: 1px" id="section-1"></div>
      <div
        class="news-list"
        :class="{ fade: activeSection.includes('section-1') }"
      >
        <div
          v-for="(item, index) in displayedItems"
          :key="index"
          class="list-item"
          @click="jumpHandle(item)"
        >
          <div class="list-item-top">
            <img :src="item.photo_pc_url" alt="" />
          </div>
          <div class="item-title">
            <img :src="Time" alt="" />
            {{ locale === 'en' ? item.eng_title : item.title }}
          </div>
          <div class="item-decs">
            {{ locale === 'en' ? item.eng_content : item.content }}
          </div>
          <div class="item-time">
            {{
              locale === 'en'
                ? item.publish_time
                  ? dayjs(item.publish_time).format('MMMM D, YYYY')
                  : ''
                : item.publish_time
                ? dayjs(item.publish_time).format('YYYY年MM月DD日')
                : ''
            }}
          </div>
        </div>
        <a v-if="canLoadMore" class="more">
          <span v-if="!upMore">{{
            locale === 'zh' ? '查看更多' : 'More'
          }}</span>
          <img
            :class="`${upMore ? 'upMore' : ''}`"
            :src="Read"
            alt=""
            @click="loadMore"
          />
        </a>
      </div>
      <Footer v-if="bannerData.length" />
    </div>
    <FooterCookie />
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, onUnmounted } from 'vue';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import FooterCookie from '@/components/FooterCookie.vue';

import Read from '@/assets/ic_read_normal.png';
import Time from '@/assets/time-hover.png';

import Mb from '@/assets/mb.png';

import { isSafari, setVhProperty, observeFun } from '@/utils';

import { useI18n } from 'vue-i18n';

import dayjs from 'dayjs';

import { getNewsList } from '@/api';
const { locale } = useI18n();
const fadeClass = ref('');
const sections = ref(['section-1']);
const activeSection = ref<string[]>([]);
let observers: any = [];
const news: any = ref([]);
const bannerData: any = ref([]);
const scrollSection = ref(null);
const showNavBar = ref(true);
const showBgcBar = ref(false);
const dynamicStyle = ref();
const pageObj = reactive({ page: 1, page_size: 100000 });

const canLoadMore = ref(false);
const upMore = ref(false);
const itemsToShowInitially = 6;
const itemsToLoadMore = 6;
const displayedItems: any = ref([]);

const setStyle = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  if (isSafari()) {
    dynamicStyle.value = setVhProperty();
  } else {
    dynamicStyle.value = '';
  }
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const viewportHeight = (document as any)
    .getElementById('banner')
    .getBoundingClientRect().height;
  if (scrollTop > viewportHeight / 1.8) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

const handleMousemove = (event: any) => {
  const topThreshold = 64; // 定义顶部区域的阈值
  if (event.clientY <= topThreshold) {
    showBgcBar.value = true;
  } else {
    showBgcBar.value = false;
  }
};

/**
 * @description 获取新闻列表
 */
const getNews = async () => {
  try {
    let res: any = await getNewsList(pageObj);
    if (res.code === 200) {
      setTimeout(() => {
        fadeClass.value = 'fade-up';
      });
      observeFun(sections, activeSection, observers, 0.1, 'section');
      bannerData.value = res.data.list.filter(
        (item: any) => item.source === '首屏'
      );
      const arr = res.data.list.filter((item: any) => item.source !== '首屏');
      news.value = arr;
      displayedItems.value = arr.slice(0, itemsToShowInitially);
      canLoadMore.value = displayedItems.value.length < arr.length;
    }
  } catch (error) {
    console.log(error);
  }
};

const loadMore = () => {
  // 收起
  if (upMore.value) {
    displayedItems.value = [];
    const nextItems = news.value.slice(0, itemsToShowInitially);
    displayedItems.value = displayedItems.value.concat(nextItems);
    upMore.value = false;
  } else {
    const currentLength = displayedItems.value.length;
    const nextItems = news.value.slice(
      currentLength,
      currentLength + itemsToLoadMore
    );
    displayedItems.value = displayedItems.value.concat(nextItems);
    console.log(
      displayedItems.value.length,
      news.value,
      'displayedItems.value.length'
    );
    if (displayedItems.value.length >= news.value.length) {
      upMore.value = true;
    }
  }
};
const bannerJump = () => {
  if (bannerData.value[0].url !== '') {
    // 创建a元素
    const link = document.createElement('a');
    // 设置链接的目标URL
    link.href = bannerData.value[0].url;

    // 设置target属性为_blank
    link.target = '_blank';

    // 将a元素添加到文档中（这一步可以不做）
    document.body.appendChild(link);

    // 模拟点击该链接
    link.click();

    // 如果不想在文档中保留该链接，可以移除
    document.body.removeChild(link);
  }
};
const jumpHandle = (item: any) => {
  // 创建a元素
  const link = document.createElement('a');
  console.log(item.url, 'item.url');
  // 设置链接的目标URL
  link.href = item.url;

  // 设置target属性为_blank
  link.target = '_blank';

  // 将a元素添加到文档中（这一步可以不做）
  document.body.appendChild(link);

  // 模拟点击该链接
  link.click();

  // 如果不想在文档中保留该链接，可以移除
  document.body.removeChild(link);
};

onMounted(() => {
  setStyle();
  getNews();
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', setStyle);
  window.addEventListener('mousemove', handleMousemove);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', setStyle);
  window.removeEventListener('mousemove', handleMousemove);
  observers.forEach((observer: any) => observer.disconnect());
});
</script>

<style lang="scss" scoped>
:root {
  --vh: 100vh;
}
.news {
  // max-width: 1640px;
  // margin: 0 auto;
  &-banner {
    cursor: pointer;
    position: relative;
    z-index: 0;
    width: 100%;
    max-height: calc(var(--vh, 1vh) * 100);
    aspect-ratio: 16 / 9;
    overflow: hidden;
    .mb {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
    &::after {
      position: absolute;
      left: 0px;
      bottom: 0;
      content: '';
      height: 5px;
      width: 100%;
      background: #111112;
      // background: red;
      z-index: 1;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .empty {
      height: 1440px;
    }
    video {
      aspect-ratio: 16 / 9;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      width: 100%;
      padding: 0 100px;
      box-sizing: border-box;
      position: absolute;
      max-height: calc(var(--vh, 1vh) * 100);
      top: 0px;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);

      > div {
        position: absolute;
        opacity: 0;
        max-width: 1210px;
        color: #fff;
        font-family: MyRegular;
        font-size: 58px;
        font-style: normal;
        font-weight: 400;
        line-height: 65px; /* 112.069% */
        bottom: 10%;
        transform: translateY(80%);
        transition: opacity 1.5s, transform 0.8s;
        .text-main {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          margin-bottom: 36px;
          padding-bottom: 10px;
          box-sizing: border-box;
          max-height: 264px;
          // &:hover {
          //   text-decoration: underline;
          //   text-decoration-thickness: 3px; /* 设置下划线的粗细 */
          // }
        }
        > div {
          position: relative;
          margin-bottom: 10px;
          span {
            color: #d6dbe8;
            font-family: MyRegularLight;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
          }
          .read {
            display: inline-block;
            vertical-align: middle; /* 保持图片与文字垂直对齐 */
            position: relative;
            top: 7px;
            bottom: 10px;
            width: 50px;
            height: 50px;
          }
        }
        > div:last-child {
          display: flex;
          align-items: center;
          .time {
            display: flex;
            line-height: normal;
            height: 43px;
            line-height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            border: 1px solid #b4bcd0;
            opacity: 0.5;
            background: rgba(23, 26, 32, 0.5);
            padding: 0 26px;
            box-sizing: border-box;
          }
          .tags {
            display: inline-block;
            padding: 0 26px;
            box-sizing: border-box;
            height: 43px;
            line-height: 40px;
            max-width: 458px;
            margin-right: 30px;
            border-radius: 25px;
            border: 1px #83a7ff;
            opacity: 0.5;
            background: rgba(23, 26, 32, 0.5);
            white-space: nowrap; /* 强制在一行显示 */
            overflow: hidden;
            text-overflow: ellipsis;
            border: 1px solid #83a7ff;
          }
        }
      }
    }
    .fade-up {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
  &-main {
    max-width: 100%;
    padding: 0 100px;
    box-sizing: border-box;

    .news-list {
      position: relative;
      max-width: 1640px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(3, minmax(200px, 514px));
      grid-gap: 60px 46px;
      padding-bottom: 80px;
      opacity: 0;
      transform: translateY(100%);
      transition: opacity 1s, transform 1s;
      margin-top: 60px;
      .list-item {
        position: relative;
        cursor: pointer;
        // font-size: 200%;
        aspect-ratio: 0.986/1;
        border-radius: 10px;
        border: 1px solid rgba(70, 87, 121, 0.8);
        background: rgba(23, 26, 32, 0.6);
        transition: background 0.2s ease-in-out;
        &-top {
          overflow: hidden;
          width: 100%;
          height: 310px;
          border-radius: 10px 10px 0px 0px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px 10px 0px 0px;
            scale: 1;
            transition: scale 0.2s ease-in-out;
          }
        }
        .item-title {
          width: 100%;
          align-items: center;
          color: rgb(161, 235, 255, 0.6);
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 65px;
          transition: opacity 0.2s ease-in-out;
          display: inline-block; /* 必须 */
          white-space: nowrap; /* 强制在一行显示 */
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 31px;
          box-sizing: border-box;
          img {
            position: relative;
            top: 3px;
            width: 24px;
            height: 24px;
            margin-left: 31px;
            margin-right: 8px;
            border-radius: 0;
            opacity: 0.6;
          }
        }
        .item-decs {
          width: 100%;
          color: #b4bcd0;
          font-family: MyRegular;
          font-size: 23px;
          font-style: normal;
          font-weight: 300;
          line-height: 34px;
          padding: 0 30px;
          box-sizing: border-box;
          display: flex;
          display: -webkit-box; /* 必须 */
          -webkit-box-orient: vertical; /* 必须 */
          -webkit-line-clamp: 2; /* 显示3行 */
          overflow: hidden; /* 隐藏溢出内容 */
          text-overflow: ellipsis; /* 使用省略号表示溢出 */
          transition: color 0.2s ease-in-out;
          opacity: 0.9;
        }
        .item-time {
          position: absolute;
          right: 32px;
          bottom: 20px;
          color: #d6dbe8;
          font-family: MyRegular;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          opacity: 0.3;
          transition: opacity 0.2s ease-in-out;
        }
      }
      .list-item:hover {
        background: rgba(23, 26, 32, 1);

        .list-item-top {
          > img {
            scale: 1.1;
            border-start-end-radius: inherit;
            border-start-start-radius: inherit;
          }
        }
        .item-decs {
          color: #feffff;
        }
        .item-time {
          opacity: 1;
        }
        .item-title {
          img {
            opacity: 1;
          }
          color: rgb(161, 235, 255, 1);
        }
      }
      .list-item:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
    .fade {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
    .more {
      position: absolute;
      cursor: pointer;
      right: 0px;
      bottom: 0px;
      z-index: 1;
      color: #fff;
      font-family: Helvetica;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      display: flex;
      width: 20%;
      justify-content: flex-end;
      align-items: center;
      justify-self: center;
      text-decoration: none;
      img {
        width: 35px;
        height: 35px;
        margin-left: 10px;
        margin-right: 10px;
        // transform: rotate(90deg);
      }
    }
    .upMore {
      opacity: 0;
      transform: rotate(-90deg) !important;
    }
  }
}
.news-zh {
  .news-banner .text {
    > div {
      width: 1220px;
      font-size: 60px;
      font-family: MyNotoRegular;
      font-weight: 400;
      .text-main {
        .read {
          // right: 0px;
        }
      }
      > div {
        .tags {
          font-size: 20px;
          font-family: MyNoto;
          font-weight: 400;
        }
        .time {
          font-size: 20px;
          font-family: MyNoto;
          font-weight: 400;
        }
      }
    }
  }
  .news-main {
    .news-list {
      .list-item {
        .item-title {
          font-size: 20px;
          font-family: MyNoto;
          font-weight: 400;
        }
        .item-decs {
          font-family: MyNoto;
        }
        .item-time {
          font-size: 18px;
          font-family: MyNoto;
        }
      }
      .more {
        font-size: 26px;
        font-family: MyNoto;
        font-weight: 300;
      }
    }
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 1230px) {
  .news {
    &-main {
      .news-list {
        grid-template-columns: repeat(2, minmax(500px, 1fr));
        .list-item{
          .list-item-top{
            height: auto !important;
            max-height: 480px;
          }
        }
      }
    }
  }
}
</style>
