<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

import 'swiper/css';
import { useI18n } from 'vue-i18n';
import Home from '@/assets/home.png';
import Vector from '@/assets/vector.png';
import Factory from '@/assets/factory.png';
import Retail from '@/assets/retail.png';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});
// const imgList = [img1, img2, img1, img2];
const imgList = [
  'https://oss-cn-beijing.galbot.com/online/portal/img/pic_jiating1.png',
  'https://oss-cn-beijing.galbot.com/online/portal/img/pic_chaoshi1.png',
  'https://oss-cn-beijing.galbot.com/online/portal/img/pic_gongchang1.png',
  'https://oss-cn-beijing.galbot.com/online/portal/img/pic_yaodian1.png',
];
const iconList = [Home, Vector, Factory, Retail];
const isWidthInRange = ref();
const isWidthInRangeFun = () => {
  isWidthInRange.value = window.matchMedia(
    '(min-width: 769px) and (max-width: 1230px)'
  ).matches;
};

const setStyle = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
onMounted(() => {
  setStyle();
  isWidthInRangeFun();
  window.addEventListener('resize', isWidthInRangeFun);
  window.addEventListener('scroll', () => {
    setStyle();
  });
});

onUnmounted(() => {
  window.removeEventListener('resize', isWidthInRangeFun);
  window.removeEventListener('scroll', setStyle);
});
</script>
<template>
  <div class="scene" :class="{ 'scene-zh': locale === 'zh' }">
    <div class="title" id="title-1">
      <div :class="{ 'fade-up': props.activeSection.includes('title-1') }">
        {{ t(`home.application.title`) }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-1') }"
    >
      {{ t(`home.application.decs`) }}
    </div>
    <div
      class="scene-content"
      :class="{ 'fade-up': props.activeSection.includes('title-1') }"
    >
      <div class="content-list">
        <div
          v-for="(item, index) in imgList"
          :class="`content-list-item list-item-${index}`"
        >
          <img :src="item" alt="" />
          <div class="shadow"></div>
        </div>
      </div>
      <div class="list">
        <div
          v-for="(item, index) in [1, 2, 3, 4]"
          :key="item"
          :class="`content-item list-active-${index}`"
        >
          <div class="scenetitle">
            <span><img :src="iconList[index]" alt="" /></span>
            {{ t(`home.application.list[${index}].title`) }}
          </div>
          <div class="scenedes">
            {{ t(`home.application.list[${index}].decs`) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.scene {
  position: relative;
  max-width: 1640px;
  margin: 0 auto;
  padding-bottom: 170px;
  &-content {
    display: flex;
    align-items: center;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 2s, transform 1.5s;
    margin-top: 70px;
    .content-list {
      width: 920px;
      height: 620px;
      position: relative;
      left: 0px;
      top: 0px;
      float: left;
      margin-right: 63px;
      &-item {
        position: absolute;
        left: 0;
        top: 1240px;
        width: 100% !important;
        height: 100%;
        background: #111112;
        // box-shadow: 0 5px 5px 1px rgba(19, 19, 20, 1);

        // transition: height 0.2s ease;
        .shadow {
          bottom: 5px;
          width: 100% !important;
          height: 25px;
          pointer-events: none; /* 使阴影不影响点击事件 */
          position: absolute;
          left: 0;
          // background: linear-gradient(to top, rgba(19, 19, 20, 1), transparent);
          &::after {
            width: 100% !important;
            height: 25px;
            position: absolute;
            content: '';
            top: 25px;
            left: 0;
            bottom: 0;
            // background: linear-gradient(
            //   to bottom,
            //   rgba(19, 19, 20, 1),
            //   transparent
            // );
          }
        }
        img {
          position: relative;
          top: 0;
          width: 920px;
          height: 620px;
          flex-shrink: 0;
          opacity: 1;
        }
      }
      .list-item-2 {
        // img{
        //   left: -80px;
        //   height: 850px;
        // }
      }
      .list-item-0 {
        top: 0;
      }
    }

    .list-item {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      font-weight: bold;
      transform: translateY(100%);
      color: #fff;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    .list-item-active {
      height: 0%;
    }
  }
  .selected {
    .scenetitle {
      background: linear-gradient(
        45deg,
        #6220ff,
        #3da0ff,
        #3da0ff,
        #3da0ff,
        #3da0ff
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .scenedes {
      opacity: 0.8;
      color: #fff !important;
    }
  }

  .title {
    padding: 66px 0 18px;
    color: #fff;
    font-family: Arial;
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    position: relative;
    // height: 36px;
    color: rgba(180, 188, 208, 0.37);
    font-family: MyRegular;
    font-size: 23px;
    font-style: italic;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
    margin: 0 auto;
    z-index: 10;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .list {
    flex: 1;
    position: relative;
    top: 18px;
    z-index: 1;
    .content-item:nth-child(3) {
      // opacity: 0;
      .scenetitle {
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
    .isShow {
      transform: translateY(0);
      opacity: 1;
    }
    .scenetitle {
      img {
        width: 32px;
        height: 30px;
        margin-right: 23px;
      }
      color: #fff;
      display: flex;
      align-items: center;
      height: 38px;
      font-family: MyRegular;
      font-size: 38px;
      font-style: normal;
      font-weight: 400;
    }
    .scenedes {
      color: #b4bcd0;
      font-family: MyRegularLight;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 25px;
      opacity: 0.9;
      margin: 19px 0 55px 55px;
    }
  }
}
.scene-zh .list {
  .scenetitle {
    font-family: MyNotoRegular;
    font-size: 34px;
    font-weight: 400;
  }
  .scenedes {
    font-family: MyNoto;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 40px;
  }
}
.scene-zh .title {
  font-family: MyNotoRegular;
  font-size: 68px;
  font-weight: 400;
}
.scene-zh .subtitle {
  font-family: MyNotoRegular;
  font-weight: 400;
  font-size: 23px;
  font-style: normal;
}
.slide-up {
  animation: slideUp 1s forwards;
}

.slide-down {
  animation: sildeDown 1s forwards;
}
@media (min-width: 1231px) and (max-width: 1360px) {
  .content-list {
    // left: -150px !important;
  }
}
@media (min-width: 769px) and (max-width: 1000px) {
  .scene {
    .content-list {
      width: 600px;
      img {
        margin-top: 100px;
        width: 100%;
        height: auto;
        // height: 320px !important;
      }
    }
  }
}
@media (min-width: 769px) and (max-width: 1230px) {
  .scene {
    width: 100% !important;
    background: none;
    height: auto;
    &-img {
      width: 100%;
    }
    .title {
      font-size: 50px;
    }
    .subtitle {
      text-align: center;
      font-family: ArialMT, 'Arial';
      font-size: 20px;
      color: #a7b8cb;
      line-height: 36px;
      font-weight: 400;
      margin: 0 auto;
    }
    .list {
      width: 100%;
      right: 0;
      margin: 0 auto;
    }
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes sildeDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}
</style>
