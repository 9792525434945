<script setup lang="ts">
import { ref, onMounted } from 'vue';
import Photo1 from '@/assets/photo1.png';
import Photo2 from '@/assets/photo2.png';

import { observeFun } from '@/utils';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});

const sections = ref(['team-1']);
let observers: any = [];
const activeItems = ref<any[]>([]);

onMounted(() => {
  observeFun(sections, activeItems, observers, 0.1, 'team');
});
</script>

<template>
  <div class="team" :class="{ 'team-zh': locale === 'zh' }">
    <div class="title" id="title-3">
      <div :class="{ 'fade-up': props.activeSection.includes('title-3') }">
        {{ t(`about.team.title`) }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-3') }"
    >
      {{ t(`about.team.decs`) }}
    </div>
    <div class="team-content">
      <div
        class="team-content-item flex-between"
        id="team-1"
        :class="{ 'fade-up': props.activeSection.includes('title-3') }"
      >
        <img :src="Photo1" alt="" />
        <div class="item-introduce">
          <div class="name">
            <span>{{ t(`about.team.list[0].name`) }}</span>
            <a class="git" href="https://pku-epic.github.io/">
              {{ t(`about.team.list[0].a`) }}</a
            >
            <!-- <div class="founder">Founder</div> -->
          </div>
          <div class="p">
            <div>
              {{ t(`about.team.list[0].decs[0]`) }}
            </div>
            <div>
              {{ t(`about.team.list[0].decs[1]`) }}
            </div>
            <div>
              {{ t(`about.team.list[0].decs[2]`) }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="team-content-item flex-between"
        id="team-1"
        :class="{ 'fade-up': activeItems.includes('team-1') }"
      >
        <img :src="Photo2" alt="" />
        <div class="item-introduce">
          <div class="name">
            <span>{{ t(`about.team.list[1].name`) }}</span>
            <!-- <div class="founder">Co-Founder</div> -->
          </div>
          <div class="p">
            <div>
              <span>{{ t(`about.team.list[1].decs[0]`) }}</span>
            </div>
            <div>
              {{ t(`about.team.list[1].decs[1]`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.team {
  max-width: 1300px;
  margin: 0 auto;
  .title {
    font-family: Arial;
    color: #fff;
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin-bottom: 12px;
    overflow: hidden;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 1s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    // height: 36px;
    color: rgba(180, 188, 208, 0.37);
    font-family: MyRegular;
    font-size: 23px;
    font-style: italic;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 1s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    padding-top: 70px;
    padding-bottom: 120px;
    &-item {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 1s;
      img {
        width: 238px;
        height: 265px;
        border-radius: 6px;
        margin-right: 46px;
        background-color: #e5eefb;
      }
      .item-introduce {
        margin-bottom: 91px;
        .name {
          display: flex;
          align-items: flex-end;
          margin-bottom: 22px;
          position: relative;
          
          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            height: 37px;
            width: 111px;
            line-height: 35px;
            box-sizing: border-box;
            font-family: PingFang-SC-Heavym, 'Arial';
            font-size: 13px;
            color: #8b98bd;
            font-weight: 400;
            text-decoration: none;
            background: rgba(54, 54, 58, 0.48);
            border: 1px solid rgba(147, 153, 170, 1);
            border-radius: 37px;
            margin-left: 13px;
            &:hover {
              color: #fff;
              border: 1px solid #fff;
            }
          }
          span {
            font-family: Arial;
            font-size: 52px;
            color: #fff;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
          }
          .founder {
            font-family: ArialMT, 'Arial';
            font-size: 13px;
            color: #ffffff;

            font-weight: 400;
            display: inline-block;
            padding: 8px 21px;
            box-sizing: border-box;
            background-image: linear-gradient(
              to right,
              rgba(162, 100, 255, 0.7),
              rgba(131, 167, 255, 0.7)
            );
            border-radius: 15px;
            margin-left: 20px;
            margin-top: 5px;
          }
        }
        .p {
          padding-right: 0px;
          color: #b4bcd0;
          font-family: MyRegularLight;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: 28px;
          opacity: 0.9;
          div {
            margin-bottom: 14px;
          }
        }
      }
    }
    &-item:last-child {
      .item-introduce {
        margin-bottom: 0px;
      }
    }
  }
}
.team-zh {
  .team-content {
    &-item {
      .item-introduce {
        .name {
          > a {
            top: -9px;
          }
          span {
            font-family: MyNoto;
            font-size: 48px;
          }
        }
        .p {
          line-height: 30px;
          font-family: MyNoto;
          font-size: 20px;
          > div {
            padding-bottom: 12px;
          }
        }
      }
    }
  }
  .title {
    font-family: MyNotoRegular;
    font-size: 68px;
    font-weight: 400;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-weight: 400;
    font-size: 23px;
    font-style: normal;
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 1230px) {
  .team {
    .title {
      font-size: 50px;
    }
    .team-content {
      &-item {
        .item-introduce {
          .name {
            span {
              font-size: 35px;
            }
          }
        }
      }
    }
  }
}
</style>
