import { defineStore } from 'pinia';

export const useLanguageStore = defineStore('language', {
  state: () => ({
    language: 'en',
    ip: '国内'
  }),
  actions: {
    setLanguage(lang: string) {
      this.language = lang;
    },
    setIp(ip: string) {
      this.ip = ip;
    },
  },
});
