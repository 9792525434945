<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref } from 'vue';

import Applic from './applic.vue';
import Roles from './roles.vue';
import Footer from '@/components/FooterPhone.vue';
import Navbar from '@/components/NavbarPhone.vue';
import RouterModal from '@/components/RouterModal.vue';
import FormModal from '@/components/FormModal.vue';
import CookieModal from '@/components/CookieModal.vue';

import { observeFun } from '@/utils';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
let observers: any = [];
const sections = ref(['title-1', 'title-2']);
const activeSection = ref<any[]>([]);
const fadeClass = ref('');
const showNavBar = ref(true);
const showMenu = ref(false);
const showForm = ref(false);

// 定义一个函数获取当前设备的宽度
const getWinSize = () => {
  let width = document.documentElement.clientWidth;
  // 假设设计稿宽度为750px
  // 假设已知根元素我们设置为100px（这里设置100方便后续我们好计算）
  // 动态设置根元素html的fontSize
  document.documentElement.style.fontSize = 100 * (width / 430) + 'px';
  console.log(100 * (width / 430) + 'px');
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const viewportHeight = (document as any)
    .getElementById('banner')
    .getBoundingClientRect().height;
  if (scrollTop > viewportHeight / 1.8) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

const closeHandle = () => {
  showMenu.value = false;
};

onMounted(() => {
  //进入页面默认获取一次
  getWinSize();
  //当页面窗口视图改变的时候再次执行
  window.onresize = getWinSize;
  window.addEventListener('scroll', handleScroll);
  observeFun(sections, activeSection, observers);
  setTimeout(() => {
    fadeClass.value = 'fade-up';
  }, 500);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <div class="careers" :class="{ 'careers-zh': locale === 'zh' }">
    <Navbar v-if="showNavBar" @change="showMenu = true" @close="closeHandle" />
    <div class="careers-banner" id="banner">
      <img
        src="https://oss-cn-beijing.galbot.com/online/portal/img/careers-phone-banner3.png"
        alt=""
      />
      <div class="text" :class="fadeClass">
        <div>
          <div>
            <span>{{ t('careers.phoneBanner[0]') }}</span
            >{{ t('careers.phoneBanner[1]') }}
          </div>
          <div class="text2">{{ t('careers.phoneBanner[2]') }}</div>
          <div>{{ t('careers.phoneBanner[3]') }}</div>
        </div>
      </div>
    </div>
    <div class="careers-main">
      <Applic :activeSection="activeSection" />
      <Roles :activeSection="activeSection" />
    </div>
    <Footer @change="showForm = true" />
    <RouterModal :class="showMenu ? 'active' : ''" @close="closeHandle" />
    <FormModal
      :class="showForm ? 'active-form' : ''"
      @close="showForm = false"
    />
    <CookieModal />
  </div>
</template>

<style lang="scss" scoped>
.careers {
  width: 100%;
  &-banner {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      width: 3.73rem;
      position: absolute;
      left: 0.24rem;
      bottom: 0.65rem;
      opacity: 0;
      transform: translateY(80%);
      transition: opacity 1.5s, transform 1s;
      div {
        color: #fff;
        font-family: MyRegular;
        font-size: 0.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.36rem;
        span {
          background: linear-gradient(
            to right,
            #a264ff,
            #83a7ff,
            #cef7ff,
            #eefcff,
            #ffffff
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
        }
      }
    }
    .fade-up {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
  &-main {
    max-width: 100%;
    margin: 0 auto;
  }
}
.careers-zh {
  .careers-banner .text {
    div {
      font-family: MyNotoRegular;
      font-size: 0.26rem;
      font-weight: 400;
      span {
        font-family: MyNotoRegular;
        font-size: 0.26rem;
        font-weight: 400;
      }
      .text2 {
        padding-left: 0.12rem;
      }
    }
  }
}
</style>
