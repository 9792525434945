<script setup lang="ts">
import { onMounted, ref, reactive, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

// import { Swiper, SwiperSlide } from 'swiper/vue';
import Read from '@/assets/read.png';
import More from '@/assets/ic_read_normal.png';

import { getNewsList } from '@/api';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});
const router = useRouter();

const news: any = ref([]);

const pageObj = reactive({ page: 1, page_size: 100000 });

const isWidthInRange = ref();

const itemsToShowInitially = 4;
// const itemsToLoadMore = 4;s

const displayedItems: any = ref([]);

const canLoadMore = ref(false);

const isWidthInRangeFun = () => {
  isWidthInRange.value = window.matchMedia(
    '(min-width: 769px) and (max-width: 1230px)'
  ).matches;
};

// const loadMore = () => {
//   const currentLength = displayedItems.value.length;
//   const nextItems = news.value.slice(
//     currentLength,
//     currentLength + itemsToLoadMore
//   );
//   displayedItems.value = displayedItems.value.concat(nextItems);
//   if (displayedItems.value.length >= news.value.length) {
//     canLoadMore.value = false;
//   }
// };

/**
 * @description 获取新闻列表
 */
const getNews = async () => {
  try {
    let res: any = await getNewsList(pageObj);
    if (res.code === 200) {
      news.value = res.data.list;
      displayedItems.value = res.data.list.slice(0, itemsToShowInitially);
      canLoadMore.value = displayedItems.value.length < news.value.length;
    }
  } catch (error) {
    console.log(error);
  }
};

const toUrl = (item: any) => {
  window.open(item.url);
};

const jumpHandle = () => {
  router.replace('/news');
};

const mouseenter = () => {
  const scrollingContent: any = document.querySelector('.mySwiper-content');
  scrollingContent.style.animationPlayState = 'paused';
};

const mouseleave = () => {
  const scrollingContent: any = document.querySelector('.mySwiper-content');
  scrollingContent.style.animationPlayState = 'running';
};

onMounted(() => {
  getNews();
  isWidthInRangeFun();
  window.addEventListener('resize', isWidthInRangeFun);
});

onUnmounted(() => {
  window.removeEventListener('resize', isWidthInRangeFun);
});
</script>

<template>
  <div class="news" :class="{ 'news-zh': locale === 'zh' }">
    <div class="title" id="title-6">
      <div :class="{ 'fade-up': props.activeSection.includes('title-6') }">
        {{ t('about.news.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-6') }"
    >
      {{ t('about.news.decs') }}
    </div>
    <!-- <div v-if="isWidthInRange" class="news-list">
      <div
        class="list-slide"
        v-for="(item, index) in displayedItems"
        :key="index"
        @click="toUrl(item)"
      >
        <div class="slide-title">
          {{ locale === 'en' ? item.eng_title : item.title }}
        </div>
        <div class="slide-content">
          {{ locale === 'en' ? item.eng_content : item.content }}
        </div>
        <div class="slide-footer">
          <span>{{ locale === 'en' ? 'Read' : '阅读' }}</span>
          <img :src="Arrow" alt="" />
        </div>
      </div>
    </div> -->
    <div class="mySwiper">
      <div
        class="mySwiper-content"
        @mouseenter="mouseenter"
        @mouseleave="mouseleave"
      >
        <div
          class="swiper-slide"
          v-for="(item, index) in news"
          :key="index"
          @click="toUrl(item)"
        >
          <div class="slide-title">
            {{ locale === 'en' ? item.eng_title : item.title }}
          </div>
          <div class="slide-content">
            {{ locale === 'en' ? item.eng_content : item.content }}
          </div>
          <div class="slide-footer">
            <span>{{ locale === 'en' ? 'Read' : '阅读' }}</span>
            <img :src="Read" alt="" />
          </div>
        </div>
        <div
          class="swiper-slide"
          v-for="(item, index) in news"
          :key="index"
          @click="toUrl(item)"
        >
          <div class="slide-title">
            {{ locale === 'en' ? item.eng_title : item.title }}
          </div>
          <div class="slide-content">
            {{ locale === 'en' ? item.eng_content : item.content }}
          </div>
          <div class="slide-footer">
            <span>{{ locale === 'en' ? 'Read' : '阅读' }}</span>
            <img :src="Read" alt="" />
          </div>
        </div>
      </div>
      <div
        class="mySwiper-content"
        @mouseenter="mouseenter"
        @mouseleave="mouseleave"
      >
        <div
          class="swiper-slide"
          v-for="(item, index) in news"
          :key="index"
          @click="toUrl(item)"
        >
          <div class="slide-title">
            {{ locale === 'en' ? item.eng_title : item.title }}
          </div>
          <div class="slide-content">
            {{ locale === 'en' ? item.eng_content : item.content }}
          </div>
          <div class="slide-footer">
            <span>{{ locale === 'en' ? 'Read' : '阅读' }}</span>
            <img :src="Read" alt="" />
          </div>
        </div>
        <div
          class="swiper-slide"
          v-for="(item, index) in news"
          :key="index"
          @click="toUrl(item)"
        >
          <div class="slide-title">
            {{ locale === 'en' ? item.eng_title : item.title }}
          </div>
          <div class="slide-content">
            {{ locale === 'en' ? item.eng_content : item.content }}
          </div>
          <div class="slide-footer">
            <span>{{ locale === 'en' ? 'Read' : '阅读' }}</span>
            <img :src="Read" alt="" />
          </div>
        </div>
      </div>
    </div>
    <a class="more" @click="jumpHandle">
      <span>{{ locale === 'zh' ? '查看更多' : 'More' }}</span>
      <img class="upMore" :src="More" alt="" />
    </a>
    <!-- <div v-if="isWidthInRange && canLoadMore" class="more">
      <span>More</span>
      <img class="more" :src="More" alt="" @click="loadMore" />
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.news {
  position: relative;
  max-width: 1640px !important;
  margin: 0 auto;
  padding-bottom: 67px;
  margin-bottom: 87px;
  .title {
    color: #fff;
    font-family: Arial;
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin-bottom: 12px;
    overflow: hidden;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    // height: 36px;
    color: rgba(180, 188, 208, 0.37);
    font-family: MyRegular;
    font-size: 23px;
    font-style: italic;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .news-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 80px;
    padding-bottom: 10px;
    box-sizing: border-box;
    .list-slide {
      cursor: pointer;
      width: 48%;
      position: relative;
      height: 300px;
      padding: 30px 40px;
      box-sizing: border-box;
      background: url('../../../../assets/bg4.png') no-repeat;
      background-size: 100% 100%;
      margin-bottom: 4%;
      transition: background 0.3s ease;
      &:hover {
        box-sizing: border-box;
        background: url('../../../../assets/bg3.png') no-repeat;
        background-size: 100% 100%;
      }
      .slide-title {
        width: 100%;
        margin-bottom: 24px;
        opacity: 0.8;
        font-family: Arial-BoldMT, 'Arial';
        font-size: 24px;
        color: #fff;
        line-height: 23px;
        font-weight: 700;
        white-space: nowrap; /* 强制在一行显示 */
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .slide-content {
        font-family: ArialMT, 'Arial';
        font-size: 16px;
        color: #abb2bc;
        line-height: 26px;
        font-weight: 400;
      }
      .slide-footer {
        cursor: pointer;
        position: absolute;
        z-index: 9999;
        z-index: 1;
        font-family: ArialMT, 'Arial';
        font-size: 20px;
        bottom: 26px;
        right: 36px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        justify-self: center;
        color: #808b98;
        text-decoration: none;
        img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }
  }
  .more {
    position: absolute;
    cursor: pointer;
    right: 0px;
    bottom: 10px;
    z-index: 1;
    color: #fff;
    font-family: Helvetica;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: center;
    justify-self: center;
    text-decoration: none;
    z-index: 100;
    img {
      width: 35px;
      height: 35px;
      margin-left: 10px;
      margin-right: 10px;
      // transform: rotate(90deg);
    }
  }
  .mySwiper {
    display: flex;
    padding-top: 50px;
    padding-bottom: 10px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    position: relative;
    mask-image: linear-gradient(
      90deg,
      rgba(7, 7, 9, 0),
      #0b0f19 10%,
      #0b0f19 90%,
      rgba(7, 7, 9, 0)
    );
    &-content {
      display: inline-flex;
      animation: scroll 40s linear infinite;
      .swiper-slide {
        cursor: pointer;
        position: relative;
        width: 387px !important;
        height: 400px;
        padding: 32px;
        margin-right: 0px !important;
        box-sizing: border-box;
        background: url('../../../../assets/bg4.png') no-repeat;
        background-size: 99.7% 100%;
        scale: 1;
        transition: scale 0.3s, background 0.3s ease;
        &:hover {
          box-sizing: border-box;
          background: url('../../../../assets/bg3.png') no-repeat;
          background-size: 99.7% 100%;
          scale: 1.05;
        }
        .slide-title {
          margin-bottom: 24px;
          color: #d6dbe8;
          font-family: MyRegular;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          white-space: nowrap; /* 强制在一行显示 */
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .slide-content {
          color: #b4bcd0;
          opacity: 0.9;
          font-family: MyRegularLight;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: 32px;
        }
        .slide-footer {
          cursor: pointer;
          position: absolute;
          z-index: 9999;
          z-index: 1;
          color: #b4bcd0;
          font-family: MyRegular;
          font-size: 30px;
          font-style: normal;
          font-weight: 300;
          bottom: 35px;
          right: 25px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          justify-self: center;
          color: #808b98;
          text-decoration: none;
          transition: color 0.3s ease;
          img {
            width: 17px;
            height: 17px;
            margin-left: 10px;
            margin-right: 10px;
          }
          &:hover {
            color: #fff;
          }
        }
      }
      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.news-zh {
  .title {
    font-family: MyNotoRegular;
    font-size: 68px;
    font-weight: 400;
  }
  .more {
    font-family: MyNoto;
    font-weight: 300;
    font-size: 26px;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-weight: 400;
    font-size: 23px;
    font-style: normal;
  }
  .mySwiper .mySwiper-content .swiper-slide {
    .slide-title {
      font-family: MyNoto;
      font-weight: 400;
      font-size: 30px;
    }
    .slide-content {
      font-family: MyNoto;
      font-weight: 300;
      font-size: 20px;
    }
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 1230px) {
  .news {
    .title {
      font-size: 50px;
    }
    .mySwiper {
      .swiper-slide {
        background-size: 100% 100%;
        .slide-content {
          font-size: 15px;
        }
        .slide-footer {
          font-size: 18px;
        }
      }
    }
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@media (min-width: 769px) and (max-width: 1000px) {
  .news {
    width: 100% !important;
  }
}
</style>
