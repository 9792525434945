<template>
  <div class="navbar">
    <div class="navbar-content">
      <img
        v-if="!active"
        @click="router.push('/')"
        class="logoIMG"
        :src="logo"
      />
      <div class="navlist" @click="navClick">
        <!-- <img :src="list" alt="" />
          -->
        <div :class="{ active1: active }"></div>
        <div :class="{ active2: active }"></div>
        <div :class="{ active3: active }"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import logo from '@/assets/logo_white.png';
import list from '@/assets/ic_list.png';

import { defineEmits } from 'vue';
import { useRouter } from 'vue-router';

const emits = defineEmits(['change', 'close']);
const active = ref(false);
const router = useRouter();
const clientWidth = ref(document.body.clientWidth);
const navlist = [
  {
    name: 'About',
    path: '/about',
  },
  {
    name: 'Careers',
    path: '/careers',
  },
];
const activeIndex = ref(-1);

const navigateTo = (item) => {};

const handleResize = () => {
  clientWidth.value = document.body.clientWidth;
};

const navClick = () => {
  console.log(active.value, 'active.value')
  if (active.value) {
    emits('close');
  } else {
    emits('change');
  }
  active.value = !active.value;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style scoped lang="scss">
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: transparent;
  color: white;
  transition: top 0.3s ease-in-out; /* 添加过渡效果 */
  z-index: 9999;
}

.navbar-content {
  position: relative;
  width: 100%;
  padding: 0.26rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoIMG {
  width: 1.78rem;
  display: block;
}

.navlist {
  position: absolute;
  display: flex;
  width: 0.21rem;
  height: 0.19rem;
  right: 0.26rem;
  top: 0.26rem;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0.85;
  font-family: ArialMT, 'Arial';
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
  z-index: 9999 !important;
  div {
    width: 0.21rem;
    height: 0.02rem;
    background: #fff;
    border-radius: 0.2rem;
    transition: all 0.3s ease-in-out;
  }
  .active1 {
    transform: rotate(45deg) translate(0.04rem, 0.08rem);
  }
  .active2 {
    opacity: 0;
  }
  .active3 {
    transform: rotate(-45deg) translate(0.04rem, -0.08rem);
  }
}

.navbar-item {
  width: 130px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding: 0 25px;
  box-sizing: border-box;
  cursor: pointer;
  a {
    color: #ffffff;
    opacity: 0.85;
    font-family: ArialMT, 'Arial';
    font-size: 20px;
    font-weight: 400;
    transition: font-weight 0.3s ease-in-out; /* 添加过渡效果 */
    text-decoration: none;
  }
}

.navbar-item a:hover {
  /* font-weight: 700; */
  color: #fff;
  opacity: 1;
  font-family: Arial-BoldMT, 'Arial';
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 1230px) {
  .navbar {
    &-content {
      max-width: 85%;
      margin: 0 auto;
    }
  }
}
</style>
