import { http } from "@/utils/http";

type Result = {
  code: Number,
  msg: string,
  data: Array<any>;
};

interface INews {
  page: Number
  page_size: Number
}

interface IRoles {
  categorys: String
  page: Number
  page_size: Number
}

interface IUpload {
  firstName: string
  lastName: string
  email: string
  msg: string
}

export const getNewsList = (data: INews) => {
  return http.request<Result>("post", "/news/list", { data });
};

export const getRolesList = (data: IRoles) => {
  return http.request<Result>("post", "/job/list", { data });
};

export const getLogoList = (data: any) => {
  return http.request<Result>("post", "/res/get_detail_list", { data });
};

export const getStoryList = (data: IRoles) => {
  return http.request<Result>("post", "/story/list", { data });
};

export const getInfocardList = (data: IRoles) => {
  return http.request<Result>("post", "/infocard/list", { data });
};

export const uploadMsg = (data: IUpload) => {
  return http.request<Result>("post", "/contact/upload_msg", { data });
};

export const getUserLanguage = async () => {
  try {
    const response = await fetch('https://api.ip.sb/geoip'); // Replace with your API endpoint
    const data = await response.json();
    const codeList = ['CN', 'HK', 'MO', 'TW'];
    return codeList.includes(data.country_code) ? 'zh' : 'en';
  } catch (error) {
    console.error('Error fetching user IP:', error);
    return 'en'; // 默认语言
  }
};