<template>
  <div
    class="privacy"
    :class="{ 'privacy-zh': locale === 'zh' }"
    ref="scrollSection"
  >
    <Navbar v-if="showNavBar" />
    <Navbar
      :class="`bgcNav ${showBgcBar && !showNavBar ? 'bgc-active' : ''}`"
    />
    <div class="privacy-main">
      <div class="main-content">
        <div class="title" id="main">{{ t('agreement.privacy.title1') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.decs1[0]') }}
        </div>
        <div class="decs">
          {{ t('agreement.privacy.decs1[1]') }}
        </div>
        <div class="sub-title">{{ t('agreement.privacy.sub1.title') }}</div>
        <div class="decs margin">
          {{ t('agreement.privacy.sub1.p[0]') }}
          <li>
            {{ t('agreement.privacy.sub1.p[1]') }}
          </li>
          <li>
            {{ t('agreement.privacy.sub1.p[2]') }}
          </li>
        </div>
        <div v-if="language.ip === '国外'" class="decs">
          {{ t('agreement.privacy.sub1.p[3]') }}
          <li>
            {{ t('agreement.privacy.sub1.p[4]') }}
          </li>
          <li>
            {{ t('agreement.privacy.sub1.p[5]') }}
          </li>
        </div>
        <div class="sub-title">{{ t('agreement.privacy.sub2.title') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.sub2.p[0]') }}
          <li>{{ t('agreement.privacy.sub2.p[1]') }}</li>
          <li>{{ t('agreement.privacy.sub2.p[2]') }}</li>
        </div>
        <div class="sub-title">{{ t('agreement.privacy.sub3.title') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.sub3.p[0]') }}
        </div>
        <div class="sub-title">{{ t('agreement.privacy.sub4.title') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.sub4.p[0]') }}
        </div>
        <div class="title mg">{{ t('agreement.privacy.title2') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.decs2[0]') }}
        </div>
        <div class="sub-title">{{ t('agreement.privacy.sub5.title') }}</div>
        <div class="decs">
          {{ t('agreement.privacy.sub5.p[0]') }}
        </div>
      </div>
      <Footer />
    </div>
    <FooterCookie />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import FooterCookie from '@/components/FooterCookie.vue';

import { useLanguageStore } from '@/store/languageStore';

import { isSafari, setVhProperty } from '@/utils';

import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const language = useLanguageStore();
const scrollSection = ref(null);
const showNavBar = ref(true);
const showBgcBar = ref(false);
const dynamicStyle = ref();

const setStyle = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  if (isSafari()) {
    dynamicStyle.value = setVhProperty();
  } else {
    dynamicStyle.value = '';
  }
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const viewportHeight = (document as any)
    .getElementById('main')
    .getBoundingClientRect().height;
  if (scrollTop > viewportHeight + 50) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

const handleMousemove = (event: any) => {
  const topThreshold = 64; // 定义顶部区域的阈值
  if (event.clientY <= topThreshold) {
    showBgcBar.value = true;
  } else {
    showBgcBar.value = false;
  }
};

onMounted(() => {
  setStyle();
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', setStyle);
  window.addEventListener('mousemove', handleMousemove);
  // observeFun(sections, activeSection, observers);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', setStyle);
  window.removeEventListener('mousemove', handleMousemove);
  // observers.forEach((observer: any) => observer.disconnect());
});
</script>

<style lang="scss" scoped>
:root {
  --vh: 100vh;
}
.privacy {
  width: 100%;
  &-main {
    max-width: 100%;
    padding: 0 100px;
    margin-top: 172px;
    box-sizing: border-box;
    .main-content {
      max-width: 1200px;
      .title {
        font-family: Arial;
        font-size: 80px;
        font-style: normal;
        font-weight: 400;
        background: linear-gradient(
          105deg,
          #c59eff 12.27%,
          #a1bcff 32.02%,
          #eefcff 53.62%,
          #fff 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: inline-block;
        margin-bottom: 29px;
      }
      .sub-title {
        font-family: MyRegular;
        font-size: 54px;
        font-weight: 400;
        background: linear-gradient(180deg, #fff 42.11%, #c5d0d8 97.75%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 74px;
        margin-bottom: 29px;
      }
      .decs {
        color: #b4bcd0;
        font-family: MyRegularLight;
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 34px; /* 141.667% */
        li {
          position: relative;
          padding-left: 30px;
          list-style: none;
          &::after {
            position: absolute;
            top: 13px;
            left: 15px;
            content: '';
            width: 5px;
            height: 5px;
            background: #b4bcd0;
            border-radius: 10px;
          }
        }
      }
      .margin {
        margin-bottom: 24px;
      }
      .mg {
        margin-top: 145px;
      }
    }
  }
}
.privacy-zh {
  .privacy-main {
    .main-content {
      .title {
        font-family: MyNoto;
        font-size: 76px;
      }
      .sub-title {
        font-family: MyNoto;
        font-size: 50px;
      }
      .decs {
        font-family: MyNoto;
        font-size: 22px;
      }
    }
  }
}
</style>
