<script setup>
import Story from './story.vue';
import Team from './team.vue';
import News from './news.vue';
import Investor from './investor.vue';
import Technology from './technology.vue';

import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import FooterCookie from '@/components/FooterCookie.vue';

import 'swiper/css';
import Pic3 from '@/assets/pic_3.png';
import Background from '@/assets/background.png';
import { onBeforeUnmount, onMounted, ref, computed } from 'vue';

import { useI18n } from 'vue-i18n';
import { isSafari, setVhProperty, observeFun } from '@/utils';

const { t, locale } = useI18n();
const sections = ref([
  'title-1',
  'title-2',
  'title-3',
  'title-4',
  'title-5',
  'title-6',
]);

let observers = [];
const fadeClass = ref('');
const activeSection = ref([]);
const video = ref();
const showBgcBar = ref(false);
const dynamicStyle = ref();
const scrollY = ref(0);
const mouseY = ref(0);
const viewportHeight = window.innerHeight;
const showNavBar = ref(true);

const setStyle = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  if (isSafari()) {
    dynamicStyle.value = setVhProperty();
  } else {
    dynamicStyle.value = '';
  }
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const viewportHeight = document
    .getElementById('banner')
    .getBoundingClientRect().height;
  if (scrollTop > viewportHeight / 1.8) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

const handleMousemove = () => {
  const topThreshold = 64; // 定义顶部区域的阈值
  if (event.clientY <= topThreshold) {
    showBgcBar.value = true;
  } else {
    showBgcBar.value = false;
  }
};

onMounted(() => {
  setStyle();
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', setStyle);
  window.addEventListener('mousemove', handleMousemove);
  // 滚动到标题添加动画
  observeFun(sections, activeSection, observers);

  setTimeout(() => {
    fadeClass.value = 'fade-up';
  }, 500);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', setStyle);
  window.addEventListener('mousemove', handleMousemove);
});
</script>

<template>
  <div class="about" :class="{ 'about-zh': locale === 'zh' }">
    <Navbar v-if="showNavBar" />
    <Navbar
      :class="`bgcNav ${showBgcBar && !showNavBar ? 'bgc-active' : ''}`"
    />
    <div class="about-banner" id="banner" :style="dynamicStyle">
      <img :src="Background" alt="galaxy" class="bg-container" />
      <div class="text">
        <div class="text-div" :class="fadeClass">
          <div>
            <span>{{ t('about.banner[0]') }}</span
            >{{ t('about.banner[1]') }}
          </div>
          <div>{{ t('about.banner[2]') }}</div>
          {{ t('about.banner[3]') }}
        </div>
      </div>
    </div>
    <div class="about-main">
      <div class="us">
        <div class="title" id="title-1" style="height: 1px; width: 100%"></div>
        <div
          class="us-content"
          :class="{ 'fade-up': activeSection.includes('title-1') }"
        >
          <img
            src="https://oss-cn-beijing.galbot.com/online/portal/img/about2.png"
            alt=""
          />
          <div class="list">
            <div class="title">
              <div :class="{ 'fade-up': activeSection.includes('title-1') }">
                {{ t('about.us.title') }}
              </div>
            </div>
            <div
              class="subtitle"
              :class="{ 'fade-up': activeSection.includes('title-1') }"
            >
              {{ t('about.us.decs') }}
            </div>
            <div>
              {{ t('about.us.infos[0]') }}
            </div>
            <div>
              {{ t('about.us.infos[1]') }}
            </div>
            <div>
              {{ t('about.us.infos[2]') }}
            </div>
            <div>
              {{ t('about.us.infos[3]') }}
            </div>
          </div>
        </div>
      </div>
      <Story :activeSection="activeSection" />
      <Team :activeSection="activeSection" />
      <Technology :activeSection="activeSection" />
      <investor :activeSection="activeSection" />
      <News :activeSection="activeSection" />
      <Footer />
    </div>
    <FooterCookie />
  </div>
</template>

<style lang="scss" scoped>
.about {
  width: 100%;
  &-banner {
    position: relative;
    width: 100%;
    max-height: calc(var(--vh, 1vh) * 100);
    aspect-ratio: 16 / 9;
    overflow: hidden;
    .bg-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: rotateAndScale 30s ease-in-out infinite;
      -webkit-animation: rotateAndScale 30s ease-in-out infinite;
      /* Chrome, Safari, Opera */
      -moz-animation: rotateAndScale 30s ease-in-out infinite;
      /* Firefox */
      -o-animation: rotateAndScale 30s ease-in-out infinite;
      /* Opera */
      background-clip: padding-box;
      -moz-background-clip: padding-box;
    }
    video {
      width: 100%;
      height: 100%;
    }
    .text {
      width: 100%;
      padding: 0 100px;
      box-sizing: border-box;
      position: absolute;
      top: 0px;
      left: 50%;
      bottom: 0px;
      transform: translateX(-50%);
      > div {
        position: absolute;
        max-width: 1100px;
        color: #fff;
        font-family: MyRegular;
        font-size: 58px;
        font-style: normal;
        font-weight: 400;
        line-height: 65px; /* 112.069% */
        bottom: 10%;
        opacity: 0;
        transform: translateY(80%);
        transition: opacity 1.5s, transform 1s;
        > div {
          margin-bottom: 10px;
        }
        span {
          background: linear-gradient(
            to right,
            #a264ff,
            #83a7ff,
            #cef7ff,
            #eefcff,
            #ffffff
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
        }
      }
    }
    .fade-up {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
  &-main {
    max-width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    .us {
      &-content {
        max-width: 1640px;
        margin: 0 auto;
        display: flex;
        padding-top: 46px;
        padding-bottom: 137px;
        box-sizing: border-box;
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 2s, transform 1.5s;
        overflow: hidden;
        img {
          // width: 845px;
          height: 540px;
          border-radius: 10px;
        }
        .list {
          flex: 1;
          // width: 586px;
          div {
            padding-left: 34px;
            width: 100%;
            color: #b4bcd0;
            opacity: 0.9;
            font-family: MyNoto;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 26px;
            margin-bottom: 20px;
            box-sizing: border-box;
          }
          .title {
            margin-bottom: 0px !important;
            > div {
              font-family: Arial !important;
              font-size: 68px !important;
              color: #fff !important;
              font-style: normal;
              font-weight: 400;
              padding-left: 0;
              text-align: left;
              line-height: normal;
              opacity: 0;
              transform: translateY(110%);
              transition: opacity 1s, transform 0.5s;
              margin-bottom: 10px !important;
            }
          }
          .subtitle {
            // height: 36px;
            font-family: MyRegular;
            font-size: 23px;
            font-style: italic;
            color: rgba(180, 188, 208, 0.37);
            text-align: left;
            line-height: 30px;
            font-weight: 600;
            margin: 0 auto;
            opacity: 0;
            transform: translateY(110%);
            transition: opacity 1s, transform 0.5s;
            margin-bottom: 26px;
          }
        }
      }
    }
  }
}

.fade-up {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.about-zh .about-main .us-content .list .title {
  div {
    font-family: MyNotoRegular !important;
    font-size: 68px !important;
    font-weight: 400 !important;
  }
}
.about-zh .about-main .us-content .list .subtitle {
  font-family: MyNotoRegular;
  font-weight: 400;
  font-size: 23px;
  font-style: normal;
  margin-bottom: 50px;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.swiper,
.technologySwiper {
  width: 100%;
  height: 100%;
}
.about-zh {
  // .about-main .us-content {
  //   width: 1055px;
  //   margin: 0 auto;
  // }
  // .about-main .us-content .list {
  //   width: 584px;
  //   flex: none;
  // }
  .about-main .us-content .list{
    position: relative;
    top: -20px;
  }
  .about-main .us-content .list >div {
    font-family: MyNoto;
    font-size: 20px;
    line-height: 30px;
  }

  .about-banner .text {
    > div {
      font-size: 60px;
      font-family: MyNotoRegular;
      font-weight: 400;
    }
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 1000px) {
  .about {
    &-main {
      max-width: 100% !important;
    }
    &-banner {
      .text {
        // max-width: 85%;
        div {
          width: 88%;
          font-size: 42px;
        }
      }
    }
    .us {
      &-content {
        justify-content: center;
        flex-wrap: wrap;
        img {
          // width: 700px !important;
          height: 395px !important;
          margin-bottom: 20px;
        }
        .list {
          > div {
            width: 100%;
          }
        }
      }
    }
  }
}

/* 定义图片收放效果-关键帧动画 */
@keyframes rotateAndScale {
  0% {
    transform: scale(0.5);
    background-size: cover;
  }

  50% {
    transform: scale(1);
    background-size: cover;
  }

  100% {
    transform: scale(0.5);
    background-size: cover;
  }
}

/* Chrome, Safari, Opera 以及较新版本的 Edge */
@-webkit-keyframes rotateAndScale {
  0% {
    -webkit-transform: scale(0.5);
    background-size: cover;
  }

  50% {
    -webkit-transform: scale(1);
    background-size: cover;
  }

  100% {
    -webkit-transform: scale(0.5);
    background-size: cover;
  }
}

/* Firefox */
@-moz-keyframes rotateAndScale {
  0% {
    -moz-transform: scale(0.5);
    background-size: cover;
  }

  50% {
    -moz-transform: scale(1);
    background-size: cover;
  }

  100% {
    -moz-transform: scale(0.5);
    background-size: cover;
  }
}
</style>
