<script setup>
import Applic from './applic.vue';
import Roles from './roles.vue';

import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import FooterCookie from '@/components/FooterCookie.vue';

import { isSafari, setVhProperty, observeFun } from '@/utils/index';

import { onBeforeUnmount, onMounted, ref, computed } from 'vue';

import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
let observers = [];
const sections = ref(['title-1', 'title-2']);
const activeSection = ref([]);
const fadeClass = ref('');
const dynamicStyle = ref();
const showNavBar = ref(true);
const showBgcBar = ref(false);
const viewportHeight = window.innerHeight;

const setStyle = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  if (isSafari()) {
    dynamicStyle.value = setVhProperty();
  } else {
    dynamicStyle.value = '';
  }
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const viewportHeight = document
    .getElementById('banner')
    .getBoundingClientRect().height;
  if (scrollTop > viewportHeight / 1.8) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

const handleMousemove = () => {
  const topThreshold = 64; // 定义顶部区域的阈值
  if (event.clientY <= topThreshold) {
    showBgcBar.value = true;
  } else {
    showBgcBar.value = false;
  }
};

onMounted(() => {
  setStyle();
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', setStyle);
  window.addEventListener('mousemove', handleMousemove);
  observeFun(sections, activeSection, observers);
  setTimeout(() => {
    fadeClass.value = 'fade-up';
  }, 500);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', setStyle);
  window.removeEventListener('mousemove', handleMousemove);
});
</script>

<template>
  <div class="careers" :class="{ 'careers-zh': locale === 'zh' }">
    <Navbar v-if="showNavBar" />
    <Navbar
      :class="`bgcNav ${showBgcBar && !showNavBar ? 'bgc-active' : ''}`"
    />
    <div class="careers-banner" id="banner" :style="dynamicStyle">
      <img
        src="https://oss-cn-beijing.galbot.com/online/portal/img/careers-banner3.png"
        alt=""
      />
      <div class="text">
        <div class="text-div" :class="fadeClass">
          <div class="text1">
            <span>{{ t('careers.banner[0]') }}</span
            >{{ t('careers.banner[1]') }}
          </div>
          <div>{{ t('careers.banner[2]') }}</div>
          {{ t('careers.banner[3]') }}
        </div>
      </div>
    </div>
    <div class="careers-main">
      <Applic :activeSection="activeSection" />
      <Roles :activeSection="activeSection" />
      <Footer />
    </div>
    <FooterCookie />
  </div>
</template>

<style lang="scss" scoped>
.careers {
  width: 100%;
  &-banner {
    position: relative;
    width: 100% !important;
    height: 100%;
    max-height: calc(var(--vh, 1vh) * 100);
    aspect-ratio: 16 / 9;
    overflow: hidden;
    img {
      width: 100%;
      // opacity: 0.8;
      // height: 100%;
    }
    .text {
      width: 100%;
      padding: 0 100px;
      box-sizing: border-box;
      position: absolute;
      top: 0px;
      left: 50%;
      bottom: 0px;
      transform: translateX(-50%);
      > div {
        color: #fff;
        position: absolute;
        width: 850px;
        font-family: MyRegular;
        font-size: 58px;
        font-style: normal;
        font-weight: 400;
        line-height: 65px; /* 112.069% */
        opacity: 0;
        bottom: 10%;
        transform: translateY(80%);
        transition: opacity 1.5s, transform 1s;
        > div {
          margin-bottom: 10px;
        }
        span {
          background: linear-gradient(
            to right,
            #a264ff,
            #83a7ff,
            #cef7ff,
            #eefcff,
            #ffffff
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
        }
      }
    }
    .fade-up {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
  &-main {
    max-width: 100%;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
  }
}
.careers-zh {
  .careers-banner .text {
    > div {
      font-size: 60px;
      font-family: MyNotoRegular;
      font-weight: 400;
      .text1 {
        position: relative;
        left: -20px;
      }
    }
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 960px) {
  .careers {
    &-main {
      // max-width: 1200px;
    }
    &-banner {
      .text {
        // max-width: 85%;
        div {
          // width: 88%;
          font-size: 42px;
        }
      }
    }
  }
}
</style>
