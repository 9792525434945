<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { observeFun } from '@/utils';
import Photo1 from '@/assets/photo1.png';
import Photo2 from '@/assets/photo2.png';

import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});

let observers: any = [];
const sections = ref(['section-1', 'section-2', 'section-3', 'section-4']);
const activeItems = ref<any[]>([]);

onMounted(() => {
  observeFun(sections, activeItems, observers, 0.1, 'section');
});
</script>

<template>
  <div class="team" :class="{ 'team-zh': locale === 'zh' }">
    <div class="title" id="title-3">
      <div :class="{ 'fade-up': props.activeSection.includes('title-3') }">
        {{ t(`about.team.title`) }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-3') }"
    >
      {{ t(`about.team.decs`) }}
    </div>
    <div class="team-content">
      <div class="team-content-item flex-between" id="team-1">
        <img
          :src="Photo1"
          alt=""
          id="section-1"
          :class="{ 'fade-up': activeItems.includes('section-1') }"
        />
        <div class="item-introduce">
          <div
            class="name"
            :class="{ 'fade-up': activeItems.includes('section-1') }"
          >
            <span>{{ t(`about.team.list[0].name`) }}</span>
            <a class="git" href="https://pku-epic.github.io/">
              {{ t(`about.team.list[0].a`) }}</a
            >
          </div>
          <!-- <div class="founder">Founder</div> -->
          <div
            class="p"
            id="section-2"
            :class="{ 'fade-up': activeItems.includes('section-2') }"
          >
            <div>
              {{ t(`about.team.list[0].decs[0]`) }}
            </div>
            <div>
              {{ t(`about.team.list[0].decs[1]`) }}
            </div>
            <div>
              {{ t(`about.team.list[0].decs[2]`) }}
            </div>
          </div>
        </div>
      </div>
      <div class="team-content-item flex-between" id="team-2">
        <img
          :src="Photo2"
          alt=""
          id="section-3"
          :class="{ 'fade-up': activeItems.includes('section-3') }"
        />
        <div class="item-introduce">
          <div
            class="name"
            :class="{ 'fade-up': activeItems.includes('section-3') }"
          >
            <span class="mg">{{ t(`about.team.list[1].name`) }}</span>
          </div>
          <!-- <div class="founder">Co-Founder</div> -->
          <div
            class="p"
            id="section-4"
            :class="{ 'fade-up': activeItems.includes('section-4') }"
          >
            <div>
              <span>{{ t(`about.team.list[1].decs[0]`) }}</span>
            </div>
            <div>
              {{ t(`about.team.list[1].decs[1]`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.team {
  position: relative;
  .title {
    color: #fff;
    font-family: Arial;
    font-size: 0.33rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    padding: 0.12rem 0.24rem 0.17rem 0.24rem;
    font-family: MyRegular;
    color: rgba(180, 188, 208, 0.55);
    font-size: 0.15rem;
    font-style: italic;
    font-weight: 600;
    line-height: 0.2rem;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    padding: 0 0.24rem 0.21rem 0.24rem;
    box-sizing: border-box;
    &-item {
      display: flex;
      align-items: center;
      flex-direction: column;

      > img {
        width: 2.1rem;
        opacity: 0;
        transform: translateY(110%);
        transition: opacity 1s, transform 0.5s;
        background-color: #e5eefb;
        border-radius: 0.06rem;
      }
      .item-introduce {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 0.22rem;
        .name {
          position: relative;
          display: flex;
          flex-direction: column;
          margin-top: 0.12rem;
          justify-content: center;
          align-items: center;
          opacity: 0;
          transform: translateY(110%);
          transition: opacity 1s, transform 0.5s;
          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.1rem auto;
            cursor: pointer;
            position: relative;
            padding: 0.05rem 0.13rem;
            box-sizing: border-box;
            font-family: Verdana;
            font-size: 0.13rem;
            color: #8b98bd;
            font-weight: 400;
            text-decoration: none;
            background: rgba(54, 54, 58, 0.48);
            border: 0.01rem solid rgba(147, 153, 170, 1);
            border-radius: 0.5rem;
            -webkit-tap-highlight-color: transparent !important;
            tap-highlight-color: transparent !important;
            outline: none;
            &:focus {
              outline: none;
              background: transparent;
              -webkit-tap-highlight-color: transparent;
            }
            &:active {
              background: transparent;
              outline: none;
              -webkit-tap-highlight-color: transparent;
            }
          }
          span {
            font-family: Arial;
            font-size: 0.3rem;
            color: #ffffff;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .mg {
            margin-bottom: 0.12rem;
          }
        }
        .founder {
          font-family: Arial-BoldMT, 'Arial';
          font-size: 0.13rem;
          color: #ffffff;
          font-weight: 700;
          background: linear-gradient(to right, #a1ebff, #a1ebff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 0.14rem;
        }
        .p {
          font-family: MyRegular;
          font-size: 0.13rem;
          color: #a4abbd;
          line-height: 0.23rem;
          font-weight: 300;
          opacity: 0;
          font-style: normal;
          transform: translateY(110%);
          transition: opacity 1s, transform 0.5s;
        }
      }
    }
  }
}
.team-zh {
  .team-content {
    &-item {
      .item-introduce {
        .name {
          span {
            font-family: MyNoto;
            font-size: 0.28rem;
          }
        }
        .p {
          line-height: 0.21rem;
          font-family: MyNoto;
          font-size: 0.14rem;
        }
      }
    }
  }
  .title {
    font-size: 0.3rem;
    font-family: MyNotoRegular;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-size: 0.14rem;
    font-weight: 400;
    font-style: normal;
  }
}
</style>
