<template>
  <div class="product" :class="{ 'product-zh': locale === 'zh' }">
    <div class="title" id="title-2">
      <div :class="{ 'fade-up': activeSection.includes('title-2') }">
        {{ t(`home.galbot.title`) }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': activeSection.includes('title-2') }"
    >
      {{ t(`home.galbot.decs`) }}
    </div>
    <div class="parameter">
      <img
        src="https://oss-cn-beijing.galbot.com/online/portal/img/home-galbot5.png"
        alt=""
      />
      <div class="left height" ref="heightSection">
        <div>
          <div class="item">{{ t(`home.galbot.height.span`) }}</div>
          <div class="itemNum">
            <ul class="list">
              <li class="listItem">145</li>
              <li class="listItem">150</li>
              <li class="listItem">155</li>
              <li class="listItem">173</li>
              <li class="listItem">173</li>
              <li class="listItem">173</li>
            </ul>
            <p class="test">{{ t(`home.galbot.height.unit`) }}</p>
          </div>
        </div>
      </div>
      <div class="right speed" ref="runtimeSection">
        <div>
          <div class="item">{{ t(`home.galbot.arm.span`) }}</div>
          <div class="itemNum">
            <ul class="list">
              <li class="listItem">160</li>
              <li class="listItem">170</li>
              <li class="listItem">180</li>
              <li class="listItem">190</li>
              <li class="listItem">190</li>
              <li class="listItem">190</li>
            </ul>
            <p class="test">{{ t(`home.galbot.arm.unit`) }}</p>
          </div>
        </div>
      </div>

      <div class="left footprint" ref="weightSection">
        <div>
          <div class="item">{{ t(`home.galbot.lift.span`) }}</div>
          <div class="itemNum">
            <ul class="list">
              <li class="listItem">50</li>
              <li class="listItem">55</li>
              <li class="listItem">60</li>
              <li class="listItem">65</li>
              <li class="listItem">65</li>
              <li class="listItem">65</li>
            </ul>
            <p class="test">{{ t(`home.galbot.lift.unit`) }}</p>
          </div>
        </div>
      </div>
      <div class="right runtime" ref="speedSection">
        <div>
          <div class="item">{{ t(`home.galbot.range.span`) }}</div>
          <div class="itemNum">
            <ul class="list">
              <li class="listItem">0-180</li>
              <li class="listItem">0-190</li>
              <li class="listItem">0-200</li>
              <li class="listItem">0-240</li>
              <li class="listItem">0-240</li>
              <li class="listItem">0-240</li>
            </ul>
            <p class="test">{{ t(`home.galbot.range.unit`) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
// Props
const props = defineProps({
  activeSection: Array,
});

// Reactive state
const state = reactive({
  digits: 123456,
});

// Refs for observing DOM elements
const heightSection = ref(null);
const runtimeSection = ref(null);
const speedSection = ref(null);
const weightSection = ref(null);

// Method to handle intersection
const handleIntersect = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const listElements = entry.target.querySelectorAll('.list');
      listElements.forEach((el) => {
        el.classList.remove('listactive');
        void el.offsetWidth; // Trigger reflow to restart animation
        el.classList.add('listactive');
      });
      observer.unobserve(entry.target);
    }
  });
};

// Method to create an observer
const createObserver = () => {
  const options = {
    root: null,
    threshold: 0.1,
  };
  const observer = new IntersectionObserver(handleIntersect, options);

  // Observe elements
  if (heightSection.value) observer.observe(heightSection.value);
  if (runtimeSection.value) observer.observe(runtimeSection.value);
  if (speedSection.value) observer.observe(speedSection.value);
  if (weightSection.value) observer.observe(weightSection.value);
};

// Mounted lifecycle hook
onMounted(() => {
  createObserver();
});
</script>

<style scoped lang="scss">
.product {
  // max-width: 1640px;
  // margin: 0 auto;
  height: 1006px;
  background-position-x: 30px;
  background-position-y: 105px;
  background: url('../../../../assets/parameter-bg.png') no-repeat;
  background-size: 100% 100%;
  .title {
    padding: 30px 0 18px;
    color: #fff;
    font-family: Arial;
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    > div {
      opacity: 0;
      transform: translateY(100%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    max-width: 700px;
    // height: 36px;
    color: rgba(180, 188, 208, 0.37);
    font-family: MyRegular;
    font-size: 23px;
    font-style: italic;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 1s, transform 0.5s;
  }

  .parameter {
    width: 100%;
    position: relative;
    height: 70%;

    img {
      top: 41px;
      // width: 100%;
      height: 720px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .left,
    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; /* 设置为左对齐 */
      border-bottom: 1px solid transparent;
      padding-bottom: 8px;
      /* 应用渐变效果 */
      border-image: linear-gradient(
          to right,
          #d8d8d8,
          rgba(238, 238, 238, 0) 80%
        )
        1;
    }
    .right {
      position: absolute;
      align-items: flex-end; /* 设置为右对齐 */
      border-bottom: 1px solid transparent;
      /* 应用渐变效果 */
      border-image: linear-gradient(
          to left,
          #d8d8d8,
          rgba(238, 238, 238, 0) 100%
        )
        1;
    }
    .height {
      position: absolute;
      left: 0;
      top: 224px;
      width: 420px;
    }
    .runtime {
      position: absolute;
      right: 0;
      width: 600px;
      top: 99px;
      text-align: right;
    }
    .speed {
      text-align: right;
      position: absolute;
      right: 0;
      top: 484px;
      width: 400px;
    }
    .footprint {
      text-align: left;
      width: 400px;
      position: absolute;
      left: 0;
      top: 565px;
    }
    .item,
    .itemNum {
      margin: 0;
    }
    .item {
      color: #b4bcd0;
      font-family: MyRegular;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .itemNum {
      display: flex; // 启用 flex 布局
      align-items: center; // 垂直居中对齐
      justify-content: flex-start; // 水平开始位置对齐
      font-family: MyRoboto;
      font-size: 100px;
      font-style: normal;
      font-weight: 300;
      line-height: 80px;
      letter-spacing: -3px;

      overflow: hidden;
      span {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        background: linear-gradient(
          129deg,
          #a264ff 2.43%,
          #83a7ff 27.18%,
          #d8f8ff 79.38%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.product-zh .title {
  font-family: MyNotoRegular;
  font-size: 68px;
  font-weight: 400;
}
.product-zh .subtitle {
  font-family: MyNotoRegular;
  font-weight: 400;
  font-size: 23px;
  font-style: normal;
}
.product-zh .parameter {
  .right,
  .left {
    .item {
      font-family: MyNoto;
      font-weight: 300;
      font-size: 20px;
    }
  }
}
.list {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
  font-size: 80px;
  height: 80px;
  line-height: 80px;
  .listItem {
    margin: 0;
    background: linear-gradient(129deg, #a264ff 2.43%, #83a7ff 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
ul {
  padding-inline-start: 0px;
}
.test {
  display: inline-block;
  margin: 0;
  font-size: 80px;
  height: 80px;
  background: linear-gradient(129deg, #83a7ff 0%, #d8f8ff 79.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.listactive {
  -webkit-animation-name: change;
  -webkit-animation-duration: 0.7s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: cubic-bezier(1, 0.75, 0.75, 0.5);
  -webkit-animation-fill-mode: forwards;
  animation-name: change;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(1, 0.75, 0.75, 0.5);
  animation-fill-mode: forwards;
}
@keyframes change {
  0% {
    transform: translate3d(0, -40px, 0);
  }
  10% {
    transform: translate3d(0, -80px, 0);
  }
  20% {
    transform: translate3d(0, -120px, 0);
  }
  30% {
    transform: translate3d(0, -160px, 0);
  }
  40% {
    transform: translate3d(0, -200px, 0);
  }
  50% {
    transform: translate3d(0, -240px, 0);
  }
  60% {
    transform: translate3d(0, -280px, 0);
  }
  70% {
    transform: translate3d(0, -320px, 0);
  }
  100% {
    transform: translate3d(0, -400px, 0);
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 2048px) {
  .product {
    max-width: 1640px;
    margin: 0 auto;
  }
}

/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 1230px) {
  .product {
    .title {
      font-size: 50px;
    }
    .subtitle {
      text-align: center;
      font-size: 20px;
      color: #a7b8cb;
      line-height: 36px;
      font-weight: 400;
      margin: 0 auto;
    }

    .parameter {
      .runtime {
        width: 430px;
      }
      .speed {
        width: 300px;
      }
      img {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 650px;
        height: auto;
      }
    }
  }
}
</style>
