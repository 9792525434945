<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
import { onMounted, onBeforeUnmount, ref } from 'vue';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { observeFun, getBrowserEngine, isWeChatBrowser } from '@/utils';

import Footer from '@/components/FooterPhone.vue';
// import ScrollNum from '@/components/ScrollNumPhone.vue';
import Home from '@/assets/home.png';
import Vector from '@/assets/vector.png';
import Factory from '@/assets/factory.png';
import Retail from '@/assets/retail.png';
import Play from '@/assets/phone-play.png';
import Stop from '@/assets/phone-stop.png';

import Navbar from '@/components/NavbarPhone.vue';
import RouterModal from '@/components/RouterModal.vue';
import FormModal from '@/components/FormModal.vue';
import CookieModal from '@/components/CookieModal.vue';

const imgList = [
  'https://oss-cn-beijing.galbot.com/online/portal/img/pic_jiating1.png',
  'https://oss-cn-beijing.galbot.com/online/portal/img/pic_chaoshi1.png',
  'https://oss-cn-beijing.galbot.com/online/portal/img/pic_gongchang1.png',
  'https://oss-cn-beijing.galbot.com/online/portal/img/pic_yaodian1.png',
];
const iconList = [Home, Vector, Factory, Retail];
let observers: any = [];
const videoPlayer = ref<any>(null);
const isPlay = ref<any>(false);
const sections = ref(['title-1', 'title-2']);
const activeSection = ref<any[]>([]);
const fadeClass = ref('');
const showNavBar = ref(true);
const showMenu = ref(false);
const showForm = ref(false);
const heightSection = ref(null);
const runtimeSection = ref(null);
const speedSection = ref(null);
const weightSection = ref(null);

const items = [
  {
    title: 'MANUFACTURING',
    text: 'In the factory, it sorts and packages parts, capable of grasping materials of any texture and shape.',
    actived: false,
  },
  {
    title: 'RETAIL',
    text: 'In supermarkets and malls, it does stocktaking and restocking all the time, and helps deliver goods.',
    actived: false,
  },
  {
    title: 'PHARMACY',
    text: 'In the pharmacy, it helps deliver medicines and maintain inventories any time, especially at night.',
    actived: false,
  },
  {
    title: 'HOME',
    text: ' Cleaning, decluttering, finding and fetching, it does everything to make people more comfortable at home.',
    actived: false,
  },
];
// 定义一个函数获取当前设备的宽度
const getWinSize = () => {
  let width = document.documentElement.clientWidth;
  // 假设设计稿宽度为750px
  // 假设已知根元素我们设置为100px（这里设置100方便后续我们好计算）
  // 动态设置根元素html的fontSize
  document.documentElement.style.fontSize = 100 * (width / 430) + 'px';
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const viewportHeight = (document as any)
    .getElementById('banner')
    .getBoundingClientRect().height;
  if (scrollTop > viewportHeight / 1.8) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

const handleIntersect = (entries: any, observer: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      const listElements = entry.target.querySelectorAll('.list');
      listElements.forEach((el: any) => el.classList.add('listactive'));
      observer.unobserve(entry.target);
    }
  });
};

const createObserver = () => {
  const options = {
    root: null,
    threshold: 0.1,
  };
  const observer = new IntersectionObserver(handleIntersect, options);

  // 为每一个有 ref 的 div 添加观察器
  heightSection.value && observer.observe(heightSection.value);
  runtimeSection.value && observer.observe(runtimeSection.value);
  speedSection.value && observer.observe(speedSection.value);
  weightSection.value && observer.observe(weightSection.value);
};

const closeHandle = () => {
  showMenu.value = false;
};

const intGsap = () => {
  gsap.registerPlugin(ScrollTrigger);
  // 创建时间轴实例
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.scene',
      start: 'top',
      end: '+=100%', // 增加滚动范围
      scrub: 1, // 调整为更大的值使滚动更慢
      pin: true,
      markers: false,
    },
  });

  // 添加动画到时间轴，并使用不同的缓动效果
  tl.fromTo(
    '.list-active-1',
    { y: 500, opacity: 0 },
    { y: 0, opacity: 1, duration: 1, ease: 'none' }
  )
    .fromTo(
      '.list-active-2',
      { y: 500, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, ease: 'none' }
    )
    .fromTo(
      '.list-active-3',
      { y: 500, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, ease: 'none' }
    );
  gsapTranslateY(tl);
};

/**
 * @description 图片平移动画
 */
const gsapTranslateY = (tl: any) => {
  tl.add(
    gsap.fromTo(
      '.list-item-0',
      { filter: 'brightness(100%)', scale: 1 },
      {
        filter: 'brightness(30%)',
        scale: 0.9,
        duration: 1,
        ease: 'none',
      }
    ),
    0 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
  )
    .add(
      gsap.fromTo(
        '.list-item-1',
        { filter: 'brightness(100%)', translateY: '100%' },
        {
          translateY: '0%',
          duration: 1,
          filter: 'brightness(100%)',
          ease: 'none',
        }
      ),
      0 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.list-item-1',
        { filter: 'brightness(100%)', scale: 1 },
        {
          filter: 'brightness(30%)',
          scale: 0.9,
          duration: 1,
          ease: 'none',
        }
      ),
      1 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.list-item-2',
        { translateY: '100%' },
        {
          translateY: '0%',
          duration: 1,
          ease: 'none',
        }
      ),
      1 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.list-item-2',
        { filter: 'brightness(100%)', scale: 1 },
        {
          filter: 'brightness(30%)',
          scale: 0.9,
          duration: 1,
          ease: 'none',
        }
      ),
      2 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    )
    .add(
      gsap.fromTo(
        '.list-item-3',
        { translateY: '100%' },
        {
          translateY: 0,
          duration: 1,
          ease: 'none',
        }
      ),
      2 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
    );
  // .add(
  //   gsap.fromTo(
  //     '.list-item-2',
  //     { height: '100%' },
  //     { height: '0%', duration: 2, ease: 'none' }
  //   ),
  //   4 // 确保新动画从时间轴的第0秒开始，与第一个动画组同时进行
  // );
};

const playVideo = () => {
  if (videoPlayer.value.paused) {
    videoPlayer.value
      .play()
      .then(() => {
        isPlay.value = true;
      })
      .catch((error: any) => {
        console.error('视频播放失败:', error);
      });
  } else {
    videoPlayer.value.pause();
    isPlay.value = false;
  }
};

onMounted(() => {
  console.log(getBrowserEngine(), 'getBrowserEngine()');
  intGsap();

  //进入页面默认获取一次
  createObserver();
  getWinSize();
  //当页面窗口视图改变的时候再次执行
  window.onresize = getWinSize;
  window.addEventListener('scroll', handleScroll);

  observeFun(sections, activeSection, observers);
  setTimeout(() => {
    fadeClass.value = 'fade-up';
  }, 500);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <div
    class="home"
    :class="{ 'home-zh': locale === 'zh', 'home-phone-zh': locale === 'zh' }"
  >
    <!-- <First />
    <Two />
    <Three /> -->
    <Navbar v-if="showNavBar" @change="showMenu = true" @close="closeHandle" />
    <div class="home-banner" id="banner">
      <video
        v-if="getBrowserEngine() === 'WebKit/Blink'"
        ref="videoPlayer"
        :autoplay="isWeChatBrowser() ? false : true"
        loop
        playsinline
        muted
        poster="https://oss-cn-beijing.galbot.com/online/portal/img/phone-home.png"
      >
        <source
          src="https://oss-cn-beijing.galbot.com/online/portal/video/phone-home.mp4"
          type="video/mp4"
        />
      </video>
      <img
        v-else
        src="https://oss-cn-beijing.galbot.com/online/portal/img/phone-home.png"
        alt=""
      />
      <div class="text" :class="fadeClass">
        <img
          v-if="isWeChatBrowser()"
          :src="isPlay ? Stop : Play"
          alt=""
          @click="playVideo"
        />
        <div>
          <span>{{ t('home.phoneBanner[0]') }}</span
          >{{ t('home.phoneBanner[1]') }}
        </div>
        <div>{{ t('home.phoneBanner[2]') }}</div>
        <div>{{ t('home.phoneBanner[3]') }}</div>
        <div>{{ t('home.phoneBanner[4]') }}</div>
      </div>
    </div>
    <div class="main">
      <div class="scene">
        <div class="title" id="title-1">
          <div :class="{ 'fade-up': activeSection.includes('title-1') }">
            {{ t(`home.application.title`) }}
          </div>
        </div>
        <div
          class="subtitle"
          :class="{ 'fade-up': activeSection.includes('title-1') }"
        >
          {{ t(`home.application.decs`) }}
        </div>
        <div
          class="content-list"
          :class="{ 'fade-up': activeSection.includes('title-1') }"
        >
          <div
            v-for="(item, index) in imgList"
            :class="`content-list-item list-item-${index}`"
          >
            <img :src="item" alt="" />
          </div>
        </div>
        <div
          class="list"
          :class="{ 'fade-up': activeSection.includes('title-1') }"
        >
          <div
            v-for="(item, index) in items"
            :key="item.title"
            :class="`content-item list-active-${index}`"
          >
            <div class="scenetitle">
              <span><img :src="iconList[index]" alt="" /></span>
              {{ t(`home.application.list[${index}].title`) }}
            </div>
            <div class="scenedes">
              {{ t(`home.application.list[${index}].decs`) }}
            </div>
          </div>
        </div>
      </div>
      <div class="product">
        <div class="title" id="title-2">
          <div :class="{ 'fade-up': activeSection.includes('title-2') }">
            {{ t(`home.galbot.title`) }}
          </div>
        </div>
        <div
          class="subtitle"
          :class="{ 'fade-up': activeSection.includes('title-2') }"
        >
          {{ t(`home.galbot.decs`) }}
        </div>
        <div class="parameter">
          <div class="parameter-item" ref="heightSection">
            <div class="itemNum">
              <div class="list">
                <div class="listItem">173</div>
                <div class="listItem">173</div>
                <div class="listItem">173</div>
                <div class="listItem">173</div>
              </div>
              <span class="test">{{ t(`home.galbot.height.unit`) }}</span>
            </div>
            <div class="item">{{ t(`home.galbot.height.span`) }}</div>
          </div>
          <div class="parameter-item" ref="runtimeSection">
            <div class="itemNum">
              <div class="list">
                <div class="listItem">190</div>
                <div class="listItem">190</div>
                <div class="listItem">190</div>
                <div class="listItem">190</div>
                <div class="listItem">190</div>
              </div>
              <span class="test">{{ t(`home.galbot.arm.unit`) }}</span>
            </div>
            <div class="item">{{ t(`home.galbot.arm.span`) }}</div>
          </div>
          <div class="parameter-item" ref="weightSection">
            <div class="itemNum">
              <div class="list">
                <div class="listItem">65</div>
                <div class="listItem">65</div>
                <div class="listItem">65</div>
                <div class="listItem">65</div>
                <div class="listItem">65</div>
              </div>
              <span class="test">{{ t(`home.galbot.lift.unit`) }}</span>
            </div>
            <div class="item">{{ t(`home.galbot.lift.span`) }}</div>
          </div>
          <div class="parameter-item" ref="speedSection">
            <div class="itemNum">
              <div class="list">
                <div class="listItem">0-240</div>
                <div class="listItem">0-240</div>
                <div class="listItem">0-240</div>
                <div class="listItem">0-240</div>
                <div class="listItem">0-240</div>
              </div>
              <span class="test">{{ t(`home.galbot.range.unit`) }}</span>
            </div>
            <div class="item">{{ t(`home.galbot.range.span`) }}</div>
          </div>
        </div>
        <div class="product-bg">
          <img
            class="product-img"
            src="https://oss-cn-beijing.galbot.com/online/portal/img/home-galbot5.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <Footer @change="showForm = true" />
    <RouterModal :class="showMenu ? 'active' : ''" @close="closeHandle" />
    <FormModal
      :class="showForm ? 'active-form' : ''"
      @close="showForm = false"
    />
    <CookieModal />
  </div>
</template>

<style lang="scss" scoped>
.home {
  width: 100%;
  width: 100%;
  &-banner {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      width: 3.73rem;
      position: absolute;
      left: 0.24rem;
      bottom: 0.65rem;
      opacity: 0;
      transform: translateY(80%);
      transition: opacity 1.5s, transform 1s;
      img {
        display: flex;
        margin-bottom: 0.1rem;
        width: 0.4rem;
        height: 0.4rem;
      }
      div {
        // position: absolute;
        color: #fff;
        font-family: MyRegular;
        font-size: 0.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.36rem; /* 112.069% */
        span {
          background: linear-gradient(
            to right,
            #a264ff,
            #83a7ff,
            #cef7ff,
            #eefcff,
            #ffffff
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
        }
      }
    }
  }

  .main {
    max-width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    .scene,
    .product {
      background-size: contain; /* 确保背景图片不溢出容器 */
      width: 100%;
    }

    .scene {
      margin-top: 0.4rem !important;
      box-sizing: border-box;
      .content-list {
        padding: 0 0.27rem;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 2.57rem;
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 2s, transform 1.5s;
        margin-top: 0.18rem;
        margin-bottom: 0.3rem;
        &-item {
          width: 3.82rem;
          position: absolute;
          overflow: hidden;
          display: flex;
          align-items: center;
          // left: 0;
          height: 2.57rem;
          background: #111112;
          img {
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            display: block;
            margin: 0 auto;
            border-radius: 0.1rem;
            position: relative;
            width: 100% !important;
            height: 100%;
          }
        }
      }
      &-img {
        width: 100%;
        height: 3.8rem;
      }
      .list {
        padding: 0 0.24rem 0.21rem 0.24rem;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 2.5s, transform 2s;
        .isShow {
          transform: translateY(0);
          opacity: 1;
        }
        .isShowImg {
          transform: translateX(0);
          opacity: 1;
        }
        .scenetitle {
          img {
            position: relative;
            top: 0.02rem;
            width: 0.21rem;
            height: 0.21rem;
            margin-right: 0.16rem;
          }
          display: flex;
          align-items: center;
          font-family: MyRegular;
          font-size: 0.2rem;
          font-style: normal;
          font-weight: 400;
          background: linear-gradient(180deg, #fff 42.11%, #c5d0d8 97.75%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .scenedes {
          font-family: Verdana;
          color: #a4abbd;
          font-family: MyRegular;
          font-size: 0.14rem;
          font-style: normal;
          line-height: 0.2rem;
          font-weight: 300;
          margin-left: 0.32rem;
          margin-top: 0.12rem;
          margin-bottom: 0.2rem;
        }
      }
    }
    .product {
      margin-top: 0.3rem;
      &-img {
        display: block;
        margin: 0 auto;
        height: 3.7rem;
        // margin-bottom: 0.5rem;
      }
      .product-bg {
        background: url('../../../../assets/parameter-bg.png') no-repeat;
        background-size: 100% 100%;
      }
      .parameter {
        width: 100%;
        padding: 0.22rem 0.24rem 0.21rem 0.24rem;
        box-sizing: border-box;
        .parameter-item {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          width: 100%;
          height: 0.4rem;
          position: relative;
          margin-bottom: 0.24rem;
          > div {
            display: flex;
            .test {
              font-family: MyRoboto;
              font-size: 0.42rem;
              display: block;
              align-items: center;
              height: 100%;
              font-weight: 300;
              background: linear-gradient(129deg, #83a7ff 0%, #d8f8ff 79.38%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -0.12rem;
            width: 100%;
            margin: 0 auto;
            height: 0.01rem;
            border: none;
            background: linear-gradient(to right, #777, #777, transparent);
          }
        }
        .right {
          align-items: flex-end; /* 设置为右对齐 */
        }
        .item,
        .itemNum {
          margin: 0;
        }
        .item {
          margin-top: 0.08rem;
          font-family: MyRegular;
          color: #b4bcd0;
          font-size: 0.13rem;
          font-weight: 500;
        }
        .itemNum {
          position: relative;
          font-family: MyRoboto;
          font-size: 0.42rem;
          font-weight: 300;
          background: linear-gradient(
            129deg,
            #a264ff 2.43%,
            #83a7ff 27.18%,
            #d8f8ff 79.38%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: flex;
          overflow: hidden;
          height: 0.4rem;

          .list {
            // display: flex;
            list-style: none;
            font-size: 0.3rem;
            height: 100%;
            .listItem {
              // background: #fff;
              height: 0.4rem;
              font-size: 0.42rem;
              margin: 0;
              background: linear-gradient(
                45deg,
                #a264ff,
                #8d91ff,
                #a3efff 100%
              );
              font-weight: 300;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          .listactive {
            /* 动画样式 */
            -webkit-animation-name: change; /* Safari 和 Chrome */
            -webkit-animation-duration: 0.3s;
            -webkit-animation-iteration-count: 1;
            -webkit-animation-timing-function: ease-in-out;
            -webkit-animation-fill-mode: forwards;

            -moz-animation-name: change; /* Firefox */
            -moz-animation-duration: 0.3s;
            -moz-animation-iteration-count: 1;
            -moz-animation-timing-function: ease-in-out;
            -moz-animation-fill-mode: forwards;

            -o-animation-name: change; /* Opera */
            -o-animation-duration: 0.3s;
            -o-animation-iteration-count: 1;
            -o-animation-timing-function: ease-in-out;
            -o-animation-fill-mode: forwards;

            animation-name: change; /* 标准 */
            animation-duration: 0.3s;
            animation-iteration-count: 1;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
}
.title {
  color: #fff;
  font-family: Arial;
  font-size: 0.33rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  > div {
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
}
.subtitle {
  width: 3rem;
  padding: 0.12rem 0.2rem 0 0.2rem;
  font-family: MyRegular;
  color: rgba(180, 188, 208, 0.6);
  font-size: 0.15rem;
  font-style: italic;
  font-weight: 600;
  line-height: 0.2rem;
  margin: 0 auto;
  text-align: center;
  opacity: 0;
  transform: translateY(110%);
  transition: opacity 1s, transform 0.5s;
}
.home-zh {
  .text {
    div {
      font-family: MyNotoRegular;
      font-size: 0.26rem;
      font-weight: 400;
      span {
        font-family: MyNotoRegular;
        font-size: 0.26rem;
        font-weight: 400;
      }
    }
  }
  .title {
    font-size: 0.3rem;
    font-family: MyNotoRegular;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-size: 0.14rem;
    font-weight: 400;
    font-style: normal;
  }
  .main {
    .scene .list {
      .scenetitle {
        font-family: MyNotoRegular;
        font-size: 0.22rem;
      }
      .scenedes {
        font-family: MyNoto;
        font-size: 0.14rem;
        line-height: 0.23rem;
      }
    }
  }
  .product {
    .parameter .item {
      font-family: MyNoto !important;
      font-size: 0.12rem !important;
    }
  }
}
.fade-up {
  opacity: 1 !important;
  transform: translateY(0) !important;
}
.slide-up {
  animation: slideUp 1s forwards;
}

.slide-down {
  animation: sildeDown 1s forwards;
}
.slide-left {
  animation: slideLeft 1s forwards;
}

.slide-right {
  animation: sildeRight 1s forwards;
}
@keyframes slideUp {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes sildeDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}
@keyframes slideLeft {
  0% {
    transform: translateX(-100vh);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes sildeRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100vh);
    opacity: 0;
  }
}
@keyframes change {
  0% {
    transform: translate3d(0, -40%, 0);
  }
  10% {
    transform: translate3d(0, -80%, 0);
  }
  20% {
    transform: translate3d(0, -120%, 0);
  }
  30% {
    transform: translate3d(0, -160%, 0);
  }
  40% {
    transform: translate3d(0, -200%, 0);
  }
  50% {
    transform: translate3d(0, -230%, 0);
  }
  60% {
    transform: translate3d(0, -260%, 0);
  }
  70% {
    transform: translate3d(0, -290%, 0);
  }
  80% {
    transform: translate3d(0, -320%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
</style>
<style lang="scss">
.home-phone-zh {
  .pin-spacer {
    height: 18.2rem !important;
    max-height: 18.2rem !important;
  }
}
</style>
