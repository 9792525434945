
export const getUserAgent = () => {
  // 另一种写法
  if (
    // navigator.userAgent.match(/Mobi/i) ||
    // navigator.userAgent.match(/Android/i) ||
    // navigator.userAgent.match(/iPhone/i)
    window.innerWidth <= 800
  ) {
    // 当前设备是移动设备
    return 'phone'
  } else {
    return 'pc'
  }

}

/**
 * @description safari 浏览器判断
 * 
 */
export const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

/**
 * @description safari 高度默认站满
 * 
 */
export const setVhProperty = () => {
  const vh = window.innerHeight;
  const vw = window.innerWidth;

  // 根据某种比例计算高度和宽度，比如保持16:9比例
  const aspectRatio = 16 / 9;
  let newHeight = vh;
  let newWidth = vw;

  // 确保高度和宽度保持等比例缩放
  if (vw / vh > aspectRatio) {
    newWidth = vh * aspectRatio;
  } else {
    newHeight = vw / aspectRatio + 40;
  }

  return {
    height: `${newHeight}px`,
    width: `${newWidth}px`,
  };
};

export const getOssUrl = () => {
  if (process.env.NODE_ENV !== 'production') {
    return 'https://oss-cn-beijing.galbot.com/online'
  } else {
    return 'https://oss-cn-beijing.galbot.com/online'
  }
}

export const observeFun = (sections: any, activeSection: any, observers: any, threshold = 1, str = 'title') => {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold,
  };

  const callback = (entries: any) => {
    entries.forEach((entry: any) => {
      console.log(entry.target, '---')
      if (entry.isIntersecting) {
        if (!activeSection.value.includes(entry.target.id)) {
          activeSection.value.push(entry.target.id);
        }
      }
    });
  };
  sections.value.forEach((section: any, index: any) => {
    console.log(section)
    const target: any = document.getElementById(`${str}-` + (index + 1));
    const observer = new IntersectionObserver(callback, options);
    observer.observe(target);
    observers.push(observer);
  });
}

export const getBrowserEngine = () => {
  const userAgent = navigator.userAgent;
  let engine;

  // 检测 Alook 浏览器
  if (userAgent.includes('alookbrowser')) {
    engine = 'WebKit'; // Alook 基于 WebKit 内核
  }
  // 检测 Trident 内核 (Internet Explorer)
  else if (userAgent.includes('Trident/')) {
    engine = 'Trident';
  }
  // 检测 Presto 内核 (旧版 Opera)
  else if (userAgent.includes('Presto/')) {
    engine = 'Presto';
  }
  // 检测 WebKit 或 Blink 内核
  else if (userAgent.includes('AppleWebKit/')) {
    if (userAgent.includes('Baidu') || userAgent.includes('BIDUBrowser')) {
      engine = 'Baidu Browser (Blink/WebKit)';
    } else if (userAgent.includes('UCBrowser')) {
      engine = 'UC Browser (U3/Blink)';
    } else {
      engine = 'WebKit/Blink';
    }
  }
  // 检测 Gecko 内核 (Firefox)
  else if (userAgent.includes('Gecko/') && !userAgent.includes('like Gecko')) {
    engine = 'Gecko';
  }
  // 检测 Blink 内核 (Chrome, Opera 新版本, Edge)
  else if (userAgent.includes('Blink/')) {
    engine = 'Blink';
  }
  // 如果没有匹配到任何已知内核
  else {
    engine = 'Unknown';
  }
  console.log(engine, 'engine')
  return engine;
}

export const isWeChatBrowser = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('micromessenger');
}
export const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
}