<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';
import Read from '@/assets/ic_read_normal.png';
import { getStoryList } from '@/api';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});

const canLoadMore = ref(false);
const upMore = ref(false);
const pageObj: any = reactive({ page: 1, page_size: 100 });
const idArr = ref<any>([]);
const storyList = ref<any>([]);
const displayedItems: any = ref([]);

const setStyle = () => {
  const screenHeight = window.innerHeight;
  const divs = document.querySelectorAll('.story-content-item');
  divs.forEach((div: any) => {
    const rect = div.getBoundingClientRect();
    const distanceFromTop = rect.top + window.scrollY;

    if (screenHeight + window.scrollY - distanceFromTop > 100) {
      if (!idArr.value.includes(div.id)) {
        idArr.value.push(div.id);
      }
      let item: any = document.querySelector(`.story-content-item`);
      let box: any = document.querySelector(`.left-${div.id} .before`);
      if (box) {
        const top =
          screenHeight +
          scrollY -
          distanceFromTop -
          item.getBoundingClientRect().height;
        box.style.transform = `translateY(${top <= 20 ? 0 : top / 100 + 2}rem)`;
      }
    } else {
      // let index = idArr.value.indexOf(div.id); // 找到元素 3 的索引
      // if (index !== -1) {
      //   idArr.value.splice(index, 1); // 删除元素 3
      // }
    }
  });
};
const loadMore = () => {
  // 收起
  if (upMore.value) {
    displayedItems.value = [];
    const nextItems = storyList.value.slice(0, 4);
    displayedItems.value = displayedItems.value.concat(nextItems);
    upMore.value = false;
  } else {
    const currentLength = displayedItems.value.length;
    const nextItems = storyList.value.slice(currentLength, currentLength + 4);
    displayedItems.value = displayedItems.value.concat(nextItems);
    // 点击更多 默认展开向上滚动
    setTimeout(() => {
      const divs = document.querySelectorAll('.story-content-item');
      const screenHeight = window.innerHeight;
      divs.forEach((div: any) => {
        const rect = div.getBoundingClientRect();
        const distanceFromTop = rect.top + window.scrollY;
        if (!idArr.value.includes(div.id)) {
          idArr.value.push(div.id);
        }
        if (div.id <= displayedItems.value.length - 1) {
          let item: any = document.querySelector(`.story-content-item`);
          let box: any = document.querySelector(`.left-${div.id} .before`);
          if (box) {
            const top =
              screenHeight +
              scrollY -
              distanceFromTop -
              item.getBoundingClientRect().height;
            box.style.top = `${top <= 120 ? 120 : top + 60}px`;
          }
        }
      });
    }, 0);
    if (displayedItems.value.length >= storyList.value.length) {
      upMore.value = true;
    }
  }
};
const getStorys = async () => {
  try {
    let res: any = await getStoryList(pageObj);
    if (res.code === 200) {
      storyList.value = res.data.list;
      // displayedItems.value = res.data.list;
      displayedItems.value = res.data.list.slice(0, 4);
      canLoadMore.value = displayedItems.value.length < res.data.list.length;
    }
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  getStorys();
  setStyle();
  window.addEventListener('scroll', () => {
    setStyle();
  });
});
</script>

<template>
  <div class="story" :class="{ 'story-zh': locale === 'zh' }">
    <div class="title" id="title-2">
      <div :class="{ 'fade-up': props.activeSection.includes('title-2') }">
        {{ t('about.story.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-2') }"
    >
      {{ t('about.story.decs') }}
    </div>
    <div id="story-content" class="story-content">
      <div
        v-for="(item, index) in displayedItems"
        :key="index"
        :id="`${index + 1}`"
        class="story-content-item"
      >
        <div
          :id="index < idArr.length - 1 ? 'scorll-active-left' : ''"
          class="left"
          :class="
            idArr.includes(`${index + 1}`)
              ? `actived-left left-${index + 1}`
              : ''
          "
        >
          <div>0{{ index + 1 }}</div>
          <div class="before"></div>
        </div>
        <div class="right">
          <div>
            <div
              :id="idArr.length - 1 === index ? 'scorll-active' : ''"
              :class="idArr.includes(`${index + 1}`) ? `actived` : ''"
            >
              <div class="right-time">
                {{ locale === 'zh' ? item.time : item.eng_time }}
              </div>
              <div class="right-title">
                {{ locale === 'zh' ? item.title : item.eng_title }}
              </div>
              {{ locale === 'zh' ? item.content : item.eng_content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="canLoadMore" class="more" @click="loadMore">
      <span v-if="!upMore">{{ locale === 'zh' ? '查看更多' : 'More' }}</span>
      <img :class="`${upMore ? 'upMore' : ''}`" :src="Read" alt="" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.story {
  position: relative;
  padding-bottom: 0.76rem;
  background: #111112;
  .title {
    color: #fff;
    font-family: Arial;
    font-size: 0.33rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    padding: 0.12rem 0.24rem 0 0.24rem;
    font-family: MyRegular;
    color: rgba(180, 188, 208, 0.55);
    font-size: 0.15rem;
    font-style: italic;
    font-weight: 600;
    line-height: 0.2rem;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .upMore {
    transform: rotate(-90deg) !important;
  }
  .more {
    position: absolute;
    cursor: pointer;
    right: 0.2rem;
    z-index: 1;
    font-family: Helvetica;
    font-size: 0.2rem;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    justify-self: center;
    color: #fff;
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-tap-highlight-color: transparent !important;
    tap-highlight-color: transparent !important;
    outline: none;
    img {
      width: 0.2rem;
      height: 0.2rem;
      margin-left: 0.1rem;
      margin-right: 0.1rem;
      // transform: rotate(90deg);
    }
  }
  &-content {
    padding: 0.23rem 0.24rem 0.11rem 0.24rem;
    box-sizing: border-box;
    overflow: hidden;
    &-item {
      color: #fff;
      display: flex;
      .left {
        overflow: hidden;
        position: relative;
        top: -0.08rem;
        font-family: Arial-Black;
        font-size: 0.3rem;
        font-weight: bold;
        background: linear-gradient(to bottom, #232b32, #232b32);
        background-size: 200% 200%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &::after {
          position: absolute;
          content: '';
          width: 0.015rem;
          top: 0.5rem;
          left: 50%;
          height: calc(100% - 0.7rem);
          background: #232b32;
        }
        .before {
          position: absolute;
          content: '';
          background: url('../../.././../assets/line.png') no-repeat;
          background-size: 100% 100%;
          width: 0.03rem;
          left: 50%;
          height: 0rem;
          border-radius: 0 0 0.1rem 0.1rem;
          transition: transform 0.2s ease; /* 添加过渡效果 */
          transform: translateY(0rem);
          opacity: 0;
          z-index: 2;
        }
      }

      .actived-left {
        > div {
          text-shadow: 0px 0px 10px rgba(222, 245, 255, 0.5);
          font-family: MyRoboto;
          font-size: 0.3rem;
          font-style: normal;
          font-weight: 500;
          background: linear-gradient(
            129deg,
            #a264ff 2.43%,
            #8d91ff 22.64%,
            #83a7ff 49.48%,
            #d8f8ff 79.38%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        // animation: gradientAnimation 1s ease 1 forwards;
        .before {
          opacity: 1;
        }
      }
      #scorll-active-left {
        > div {
          // background: linear-gradient(
          //   to bottom,
          //   rgba(162, 100, 255, 0.7),
          //   rgba(162, 100, 255, 0.7),
          //   rgba(131, 167, 255, 0.7),
          //   rgb(163, 239, 255, 0.7)
          // );
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          // text-shadow: 0 0 0.01rem rgba(47, 206, 255, 0.3);
        }
      }
      .right {
        width: 90%;
        padding-bottom: 0.25rem;
        box-sizing: border-box;
        > div > div {
          padding: 0.3rem;
          margin-left: 0.15rem;
          background: url('../../../../assets/story_bg.png') no-repeat;
          background-size: 100% 100%;
          position: relative;
          font-family: MyRegular;
          font-size: 0.13rem;
          color: #a4abbd;
          line-height: 0.23rem;
          font-weight: 300;
          animation: moveDown 1s ease-in-out;
          box-sizing: border-box;
          transform: translateY(2.2rem);
          border-radius: 0rem;
          .right-time {
            font-family: Arial;
            font-size: 0.13rem;
            color: #a1ebff;
            font-weight: 700;
            opacity: 0.8;
            font-style: normal;
            line-height: normal;
            text-transform: uppercase;
          }
          .right-title {
            font-family: Arial;
            font-size: 0.22rem;
            background: linear-gradient(180deg, #ebf2f5 42.11%, #c5d0d8 97.75%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            line-height: 0.23rem;
            font-style: normal;
            font-weight: 400;
            margin: 0.1rem 0;
          }
        }
        .actived {
          transform: translateY(0rem);
          will-change: transform;
          animation: moveUp 1s ease-in-out;
        }
      }
      #scorll-active {
        opacity: 1;
        // background: url('../../../../assets/pic_bg1_1.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .story-content-item:last-child {
      .left {
        &::after {
          width: 0;
        }
      }
      .right {
        padding-bottom: 0.1rem;
      }
      .before {
        width: 0;
      }
    }
  }
}
.story-zh {
  .title {
    font-size: 0.3rem;
    font-family: MyNotoRegular;
  }
  .more {
    font-size: 0.18rem;
    font-family: MyNoto;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-size: 0.14rem;
    font-weight: 400;
    font-style: normal;
  }
  .story-content {
    &-item {
      .right {
        > div > div {
          font-family: MyNoto;
          .right-time {
            font-family: Arial;
          }
          .right-title {
            font-family: MyNoto;
          }
        }
      }
    }
  }
}
@keyframes moveUp {
  0% {
    transform: translateY(2.2rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes moveDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(2.2rem);
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 50% 100%;
  }
}
@keyframes canleAnimation {
  0% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
</style>
