<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';

import Read from '@/assets/ic_read_normal.png';

import { getStoryList } from '@/api';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});
const canLoadMore = ref(false);
const upMore = ref(false);
const moreLength = ref(4);
const pageObj: any = reactive({ page: 1, page_size: 100 });
const idArr = ref<any>([]);
const storyList = ref<any>([]);
const displayedItems: any = ref([]);

const setStyle = () => {
  const screenHeight = window.innerHeight;
  const divs = document.querySelectorAll('.story-content-item');
  divs.forEach((div: any) => {
    const rect = div.getBoundingClientRect();
    const distanceFromTop = rect.top + window.scrollY;
    if (screenHeight + window.scrollY - distanceFromTop > 200) {
      if (!idArr.value.includes(div.id)) {
        idArr.value.push(div.id);
      }
      let item: any = document.querySelector(`.story-content-item`);
      let box: any = document.querySelector(`.left-${div.id} .before`);
      if (box) {
        const top =
          screenHeight +
          scrollY -
          distanceFromTop -
          item.getBoundingClientRect().height;
        box.style.top = `${top <= 120 ? 120 : top + 60}px`;
      }
    } else {
      // let index = idArr.value.indexOf(div.id); // 找到元素 3 的索引
      // if (index !== -1) {
      //   idArr.value.splice(index, 1); // 删除元素 3
      // }
    }
  });
};

const getStorys = async () => {
  try {
    let res: any = await getStoryList(pageObj);
    if (res.code === 200) {
      storyList.value = res.data.list;
      // displayedItems.value = res.data.list;
      displayedItems.value = res.data.list.slice(0, 4);
      canLoadMore.value = displayedItems.value.length < res.data.list.length;
    }
  } catch (error) {
    console.log(error);
  }
};

const loadMore = () => {
  // 收起
  if (upMore.value) {
    displayedItems.value = [];
    const nextItems = storyList.value.slice(0, 4);
    displayedItems.value = displayedItems.value.concat(nextItems);
    upMore.value = false;
  } else {
    const currentLength = displayedItems.value.length;
    const nextItems = storyList.value.slice(currentLength, currentLength + 4);
    moreLength.value = currentLength + 4;
    displayedItems.value = displayedItems.value.concat(nextItems);
    // 点击更多 默认展开向上滚动
    setTimeout(() => {
      const divs = document.querySelectorAll('.story-content-item');
      const screenHeight = window.innerHeight;
      divs.forEach((div: any) => {
        const rect = div.getBoundingClientRect();
        const distanceFromTop = rect.top + window.scrollY;
        if (!idArr.value.includes(div.id)) {
          idArr.value.push(div.id);
        }
        console.log(div.id, displayedItems.value.length, '-======');
        if (div.id <= displayedItems.value.length) {
          let item: any = document.querySelector(`.story-content-item`);
          let box: any = document.querySelector(`.left-${div.id} .before`);
          // let boxRight: any = document.querySelector(`.right-${div.id}`);
          if (box) {
            const top =
              screenHeight +
              scrollY -
              distanceFromTop -
              item.getBoundingClientRect().height;
            box.style.top = `${top <= 120 ? 120 : top + 60}px`;
            console.log(box.style.animation, `1111`);
          }
        }
      });
    }, 0);
    if (displayedItems.value.length >= storyList.value.length) {
      upMore.value = true;
    }
  }
};

onMounted(() => {
  getStorys();
  setStyle();
  window.addEventListener('scroll', () => {
    setStyle();
  });
});
</script>

<template>
  <div class="story" :class="{ 'story-zh': locale === 'zh' }">
    <div class="title" id="title-2">
      <div :class="{ 'fade-up': props.activeSection.includes('title-2') }">
        {{ t('about.story.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-2') }"
    >
      {{ t('about.story.decs') }}
    </div>
    <div id="story-content" class="story-content">
      <div
        v-for="(item, index) in displayedItems"
        :key="index"
        :id="`${index + 1}`"
        class="story-content-item"
      >
        <div
          class="left"
          :id="index < idArr.length - 1 ? 'scorll-active-left' : ''"
          :class="
            idArr.includes(`${index + 1}`)
              ? `actived-left left-${index + 1}`
              : ''
          "
        >
          <div>0{{ index + 1 }}</div>
          <div class="before"></div>
        </div>
        <div class="right">
          <div>
            <div
              :id="idArr.length - 1 === index ? 'scorll-active' : ''"
              :class="`right-${index + 1} ${
                idArr.includes(`${index + 1}`) ? `actived` : ''
              }`"
            >
              <div class="right-time">
                {{ locale === 'zh' ? item.time : item.eng_time }}
              </div>
              <div class="right-title">
                {{ locale === 'zh' ? item.title : item.eng_title }}
              </div>
              {{ locale === 'zh' ? item.content : item.eng_content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <a v-if="canLoadMore" class="more" @click="loadMore">
      <span v-if="!upMore">{{ locale === 'zh' ? '查看更多' : 'More' }}</span>
      <img :class="`${upMore ? 'upMore' : ''}`" :src="Read" alt="" />
    </a>
  </div>
</template>

<style lang="scss" scoped>
.story {
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 128px;
  .title {
    font-family: Arial;
    color: #fff;
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin-bottom: 12px;
    overflow: hidden;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    // height: 36px;
    color: rgba(180, 188, 208, 0.37);
    font-family: MyRegular;
    font-size: 23px;
    font-style: italic;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .upMore {
    transform: rotate(-90deg) !important;
  }
  .more {
    position: absolute;
    cursor: pointer;
    right: 0px;
    z-index: 1;
    color: #fff;
    font-family: Helvetica;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    width: 20%;
    justify-content: flex-end;
    align-items: center;
    justify-self: center;
    text-decoration: none;
    img {
      width: 35px;
      height: 35px;
      margin-left: 10px;
      margin-right: 10px;
      // transform: rotate(90deg);
    }
  }
  &-content {
    margin: 0 auto;
    padding-top: 62px;
    padding-bottom: 30px;
    overflow: hidden;

    &-item {
      color: #fff;
      display: flex;
      .left {
        overflow: hidden;
        position: relative;
        top: -30px;
        font-size: 100px;
        font-style: normal;
        font-family: MyRoboto;
        font-weight: 500;

        > div {
          background: linear-gradient(to bottom, #232b32, #232b32);
          background-size: 200% 200%;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: background 1s ease;
        }
        &::before {
          content: '02';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to bottom, #232b32, #232b32);
          filter: blur(8px);
          opacity: 0.6;
          z-index: -1;
        }
        &::after {
          position: absolute;
          content: '';
          width: 2px;
          top: 120px;
          left: 60px;
          height: 100%;
          background: #232b32;
        }
      }
      #scorll-active-left {
        > div {
          // text-shadow: 0px 0px 10px rgba(222, 245, 255, 0.5);
          font-family: MyRoboto;
          font-size: 100px;
          font-style: normal;
          font-weight: 500;
          background: linear-gradient(
            129deg,
            #a264ff 2.43%,
            #8d91ff 22.64%,
            #83a7ff 49.48%,
            #d8f8ff 79.38%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      .actived-left {
        > div {
          text-shadow: 0px 0px 10px rgba(222, 245, 255, 0.5);
          font-family: MyRoboto;
          font-size: 100px;
          font-style: normal;
          font-weight: 500;
          background: linear-gradient(
            129deg,
            #a264ff 2.43%,
            #8d91ff 22.64%,
            #83a7ff 49.48%,
            #d8f8ff 79.38%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        // background-size: 200% 200%;
        -webkit-text-fill-color: transparent;
        // animation: gradientAnimation 1s ease 1 forwards;
        transition: background 1s ease;
        .before {
          position: absolute;
          content: '';
          width: 0px;
          top: 120px;
          left: 60px;
          height: 140px;
          border-radius: 0 0 10px 10px;
          background: url('../../../../assets/line.png') no-repeat;
          background-size: cover;
          transition: top 0.3s ease; /* 添加过渡效果 */
          z-index: 9999;
        }
      }
      .right {
        width: 1200px;
        padding-bottom: 62px;
        > div > div {
          padding: 45px;
          margin-left: 45px;
          background: url('../../../../assets/pic_bg1_story.png') no-repeat;
          background-size: 100% 100%;
          position: relative;
          color: #b4bcd0;
          font-family: MyRegularLight;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          line-height: 25px;
          font-weight: 400;
          animation: moveDown 1s ease-in-out;
          box-sizing: border-box;
          top: 220px;
          opacity: 0.9;
          .right-time {
            font-family: Arial;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            background: linear-gradient(to right, #a1ebff, #a1ebff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: inline-block;
          }
          .right-title {
            font-family: Arial;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 35px;
            font-weight: 700;
            padding: 12px 0;
            background: linear-gradient(180deg, #ebf2f5 42.11%, #c5d0d8 97.75%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: i;
          }
        }
        .actived {
          top: 0px;
          animation: moveUp 1s ease-in-out;
        }
        #scorll-active {
          opacity: 1;
          background: url('../../../../assets/pic_bg1_1.png') no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .story-content-item:last-child {
      .left {
        &::after {
          width: 0;
        }
      }
      .right {
        padding-bottom: 0px;
      }
      .actived-left {
        .before {
          width: 0;
        }
      }
    }
  }
}
.story-zh {
  .title {
    font-family: MyNotoRegular;
    font-size: 68px;
    font-weight: 400;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-weight: 400;
    font-size: 23px;
    font-style: normal;
  }
  .more {
    font-family: MyNoto;
    font-weight: 300;
    font-size: 26px;
  }
  .story-content-item .actived-left > div {
    text-shadow: none;
  }
  .story-content {
    // padding-left: 120px;
    &-item {
      .right {
        > div > div {
          font-family: MyNoto;
          font-weight: 300;
          font-size: 20px;
          line-height: 32px;
          .right-time {
            font-family: Arial;
            font-size: 18px;
          }
          .right-title {
            font-family: MyNoto;
            font-weight: 400;
            font-size: 35px;
          }
        }
      }
    }
  }
}
@keyframes onShow {
  0% {
    color: #232b32;
    background: linear-gradient(
      to bottom,
      #232b32,
      #232b32,
      rgba(0, 255, 255),
      #fff
    );
  }
  100% {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 255),
      rgba(0, 255, 255),
      rgba(0, 255, 255),
      #fff
    );
  }
}
@keyframes onHide {
  0% {
    color: rgb(245, 131, 39);
  }
  100% {
    color: rgba(255, 255, 255, 0.05);
  }
}
@keyframes moveUp {
  0% {
    top: 220px;
  }
  100% {
    top: 0px;
  }
}
@keyframes moveDown {
  0% {
    top: 0px;
  }
  100% {
    top: 220px;
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 50% 100%;
  }
}
@keyframes canleAnimation {
  0% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 1230px) {
  .story {
    .more {
      // right: 150px;
    }
    .title {
      font-size: 50px;
    }
    &-content {
      &-item {
        .left {
          font-size: 76px;
          padding-left: 0;
          &::after {
            left: 55%;
          }
        }
        .right {
          // width: 80%;
        }
        .actived-left {
          .before {
            left: 55%;
          }
        }
      }
    }
  }
}
</style>
