<script setup>
import Close from '@/assets/ic_quit_nomal.png';

import { defineEmits, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { uploadMsg } from '@/api';
import loaddingImg from '@/assets/loding.png';
import normalBtn from '@/assets/button_bg.png';
import SubmitZh from '@/assets/submit.png';
import SubmitEn from '@/assets/submit-en.png';

import { useLanguageStore } from '@/store/languageStore';

import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const languageStore = useLanguageStore();
const emits = defineEmits(['close']);
const router = useRouter();
const pedding = ref(false);

const form = ref({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  msg: '',
});
const isSuccess = ref('');
const errors = reactive({
  firstName: {
    msg: null,
    require: false,
  },
  lastName: {
    msg: null,
    require: false,
  },
  email: {
    msg: null,
    require: false,
  },
  phone: {
    msg: null,
    require: false,
  },
  msg: {
    msg: null,
    require: false,
  },
});

const rules = {
  firstName: [(value) => !!value || 'First Name is required.'],
  lastName: [(value) => !!value || 'Last Name is required.'],
  email: [
    (value) => !!value || 'Email is required.',
    (value) => /.+@.+\..+/.test(value) || 'Email must be valid.',
  ],
  msg: [(value) => !!value || 'Message is required.'],
  phone: [
    (value) => {
      const phonePattern =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; // 简单的手机号验证模式
      return (
        phonePattern.test(value) || 'Please enter the correct phone number'
      );
    },
  ],
};
const validateField = () => {
  let valid = true;

  if (languageStore.language === 'en') {
    delete form.value.phone;
  }

  const validateEmptyField = (field) => {
    if (form.value[field] === '') {
      const errorMessage = errorMsg(field);
      errors[field].msg = errorMessage;
      errors[field].require = false;
      return false;
    }
    return true;
  };

  const validatePhoneEmailFields = (field) => {
    if (field === 'phone' || field === 'email') {
      const errorMessage = errorMsg(field);
      errors[field].msg = errorMessage;
      errors[field].require = false;
      return !errorMessage;
    }
    return true;
  };

  const setErrorMessage = (field, message, require) => {
    errors[field].msg = message;
    errors[field].require = require;
  };

  for (const field in form.value) {
    if (!validateEmptyField(field)) {
      valid = false;
    } else {
      if (languageStore.language === 'zh') {
        if (!validatePhoneEmailFields(field)) {
          valid = false;
        } else {
          setErrorMessage(field, null, true);
        }
      } else {
        setErrorMessage(field, null, true);
      }
    }
  }

  console.log(valid, 'valid');
  return valid;
};

const errorMsg = (field) => {
  for (const rule of rules[field]) {
    const errorMessage = rule(form.value[field]);
    if (errorMessage !== true) {
      return errorMessage;
    }
  }
};

const highlightBorder = (event) => {
  event.target.style.borderColor = 'white';
};

const resetBorder = (event) => {
  event.target.style.borderColor = '';
};

const onSubmit = async () => {
  try {
    if (validateField()) {
      pedding.value = true;
      const param = { ...form.value };
      if (languageStore.language === 'en') {
        param.phone = '';
      }
      let res = await uploadMsg(param);
      if (res.code === 200) {
        isSuccess.value = true;
        pedding.value = false;
        setTimeout(() => {
          isSuccess.value = '';
          emitClose();
        }, 1000);

        form.value.firstName = '';
        form.value.lastName = '';
        form.value.email = '';
        form.value.phone = '';
        form.value.msg = '';
      } else {
        isSuccess.value = false;
        pedding.value = false;
        setTimeout(() => {
          isSuccess.value = '';
        }, 2000);
      }
    }
  } catch (error) {
    isSuccess.value = false;
    pedding.value = false;
    console.log(error);
    setTimeout(() => {
      isSuccess.value = '';
    }, 2000);
  }
};

const emitClose = () => {
  emits('close');
};
</script>
<template>
  <div class="form-modal" :class="{ 'form-modal-zh': locale === 'zh' }">
    <div class="form-modal-close">
      <img :src="Close" @click="emitClose" />
    </div>
    <div class="form-modal-content">
      <form @submit.prevent="onSubmit">
        <div class="form-group">
          <input
            type="text"
            v-model="form.firstName"
            id="firstName"
            class="form-input"
            @focus="highlightBorder($event)"
            @blur="resetBorder($event)"
            @input="highlightBorder($event)"
            :placeholder="t('footer.form.first')"
          />
          <span
            v-if="errors.firstName && locale === 'en'"
            class="error-message"
            >{{ errors.firstName.msg }}</span
          >
          <span
            v-if="errors.firstName.msg && locale === 'zh'"
            class="error-message"
            >请输入姓氏</span
          >
        </div>
        <div class="form-group">
          <input
            type="text"
            v-model="form.lastName"
            id="lastName"
            class="form-input"
            @focus="highlightBorder($event)"
            @blur="resetBorder($event)"
            @input="highlightBorder($event)"
            :placeholder="t('footer.form.last')"
          />
          <span
            v-if="errors.lastName && locale === 'en'"
            class="error-message"
            >{{ errors.lastName.msg }}</span
          >
          <span
            v-if="errors.lastName.msg && locale === 'zh'"
            class="error-message"
            >请输入名字</span
          >
        </div>
        <div class="form-group">
          <input
            type="email"
            v-model="form.email"
            id="email"
            class="form-input"
            @focus="highlightBorder($event)"
            @blur="resetBorder($event)"
            @input="highlightBorder($event)"
            :placeholder="t('footer.form.email')"
          />
          <span v-if="errors.email && locale === 'en'" class="error-message">{{
            errors.email.msg
          }}</span>
          <span v-if="errors.email.msg && locale === 'zh'" class="error-message"
            >请输入正确邮箱</span
          >
        </div>
        <div v-if="languageStore.language === 'zh'" class="form-group">
          <input
            type="text"
            id="phone"
            v-model="form.phone"
            class="form-input"
            @focus="highlightBorder($event)"
            @blur="resetBorder($event)"
            @input="highlightBorder($event)"
            :placeholder="t('footer.form.phone')"
          />
          <span v-if="errors.phone && locale === 'en'" class="error-message">{{
            errors.phone.msg
          }}</span>
          <span v-if="errors.phone.msg && locale === 'zh'" class="error-message"
            >请输入正确手机号</span
          >
        </div>
        <div class="form-group">
          <textarea
            v-model="form.msg"
            id="message"
            class="form-input"
            @focus="highlightBorder($event)"
            @blur="resetBorder($event)"
            @input="highlightBorder($event)"
            :placeholder="t('footer.form.message')"
          >
          </textarea>
          <span v-if="errors.msg && locale === 'en'" class="error-message">{{
            errors.msg.msg
          }}</span>
          <span
            v-if="errors.msg.msg && locale === 'zh'"
            class="error-message textarea-msg"
            >{{ '请输入信息' }}</span
          >
        </div>
        <div
          :class="`successTip ${
            isSuccess !== '' && isSuccess ? 'opacity' : ''
          }`"
        >
          {{ locale === 'en' ? 'Submission successfully!' : '提交成功!' }}
        </div>
        <div
          :class="`failTip ${isSuccess !== '' && !isSuccess ? 'opacity' : ''}`"
        >
          {{ locale === 'en' ? 'Submission Failed!' : '提交失败!' }}
        </div>
        <button type="submit" class="submit-button" :disabled="pedding">
          <span class="submit-button__inner">
            <img :src="locale === 'zh' ? SubmitZh : SubmitEn" alt="" />
            <div v-show="pedding" class="loading">
              <img class="peddingIMG" :src="loaddingImg" alt="" />
            </div>
          </span>
        </button>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-modal {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #111114;
  z-index: 9999;
  transform: translateY(-100%);
  transition: transform 0.2s ease-in-out;
  overflow: hidden;
  &-close {
    display: flex;
    position: absolute;
    padding: 0.1rem;
    top: 0.15rem;
    right: 0.2rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    img {
      width: 0.15rem;
      height: 0.15rem;
    }
  }
  &-content {
    padding: 0 0.24rem;
    padding-top: 0.8rem;
    box-sizing: border-box;
    .form-group {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.1rem;
      .error-message {
        position: absolute;
        top: 0.5rem;
        color: #ffffff;
        opacity: 0.7;
        font-size: 0.14rem;
        padding-top: 0.06rem;
      }
      .textarea-msg {
        top: 2.1rem;
      }
    }
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    .tipMessage {
      height: 0.3rem;
    }
    .successTip {
      opacity: 0;
      text-align: center;
      color: #27eaff;
      font-size: 0.14rem;
      margin-left: 0.2rem;
      font-family: Verdana;
      font-weight: 400;
    }
    .failTip {
      opacity: 0;
      text-align: center;
      font-size: 0.14rem;
      margin-left: 0.2rem;
      font-family: Verdana;
      color: rgba(255, 255, 255, 0.7);
      font-weight: 400;
    }
    .opacity {
      opacity: 1;
    }
    #message {
      text-align: left;
      height: 2rem;
    }
    .form-input {
      width: 3.58rem;
      height: 0.46rem;
      padding: 0.1rem;
      color: #fff;
      box-sizing: border-box;
      background: #141414;
      opacity: 0.7;
      border: 0.01rem solid rgba(183, 183, 183, 1);
      border-radius: 0.04rem;
    }

    .form-input:hover,
    .form-input:focus,
    .form-input:active {
      border: none;
      outline: none;
      box-shadow: none;
      border: 0.02rem solid rgba(255, 255, 255, 1);
    }
    .submit-button {
      position: relative;
      display: inline-block;
      // display: flex;
      margin: 0 auto;
      width: 3.58rem;
      height: 0.62rem;
      line-height: 0.62rem;
      // bottom: 0.6rem;
      text-align: center;
      background-color: transparent; /* 背景为透明 */
      cursor: pointer;
      font-family: 'Microsoft YaHei', sans-serif;
      font-size: 0.2rem;
      color: #ffffff;
      font-weight: 700;
      border: none;
      border-radius: 1px; /* 确保默认状态下有圆角 */
      overflow: hidden;
      outline: none;
      padding: 0; /* 移除默认padding */
      z-index: 1;
      -webkit-tap-highlight-color: transparent !important;
      tap-highlight-color: transparent !important;
      outline: none;
      img {
        width: 100%;
        height: 100%;
      }
      .loading {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0.03rem;
        top: 0.03rem;
        width: 3.52rem;
        border-radius: 1px; /* 确保默认状态下有圆角 */
        height: 0.56rem;
        background-color: #141414;
        z-index: 2;
        > img {
          width: 0.4rem !important;
          height: 0.4rem !important;
          animation: rotate 2s linear infinite;
        }
      }
    }

    .submit-button::before {
      content: '';
      position: absolute;
      top: 0.02rem;
      left: 0.02rem;
      right: 0.02rem;
      bottom: 0.02rem;
      background-color: #141414;
      border-radius: inherit;
      z-index: 0;
      transition: background-color 0.3s ease-in-out;
    }

    .submit-button__inner {
      position: relative;
      z-index: 1;
      display: inline-block;
      width: 100%;
      height: 100%;
      line-height: inherit;
      text-align: center;
      color: #ffffff; /* 确保文字颜色为白色 */
    }
  }
}
.active-form {
  transform: translateY(0);
}
input[type='text']:-internal-autofill-selected,
input[type='email']:-internal-autofill-selected,
textarea:-internal-autofill-selected,
select:-internal-autofill-selected {
  background-color: #141414 !important;
  box-shadow: 0 0 0px 1000px #141414 inset !important;
  color: #ffffff !important; /* 确保文字颜色为白色 */
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #141414 !important;
  box-shadow: 0 0 0px 1000px #141414 inset !important;
  -webkit-text-fill-color: #ffffff !important; /* 使用 -webkit-text-fill-color */
}
</style>
