<template>
  <div class="footerStyle" :class="{ 'footerStyle-zh': locale === 'zh' }">
    <div class="main">
      <div class="middle">
        <img class="colorlogo" :src="colorlogo" @click.stop="toHome" />
        <div class="bottom">
          <div id="contact-form" class="contact" @click="toggleFormContainer">
            {{ t('footer.contact') }}
            <img :src="Contact" alt="" />
          </div>
        </div>
        <div class="jump">
          <div
            class="jumplink"
            :class="{ active: route.path === '/about' }"
            @click="toAbout"
          >
            {{ t('footer.links[0]') }}
          </div>
          <div
            class="jumplink"
            :class="{ active: route.path === '/news' }"
            @click="toNews"
          >
            {{ t('footer.links[1]') }}
          </div>
          <div
            class="jumplink"
            :class="{ active: route.path === '/careers' }"
            @click.stop
          >
            <span @click.stop="toCareers">{{ t('footer.links[2]') }}</span>
          </div>
        </div>
      </div>
      <div class="link">
        <div class="weixin">
          <div v-if="showWx" class="wxQrCode">
            <img :src="wxCode" alt="" />
          </div>
          <img
            @click="
              () => {
                showWx = !showWx;
                showDy = false;
                // toLink('weixin');
              }
            "
            :src="wechatSrc"
          />
        </div>
        <div>
          <img class="sina" @click="toLink('sina')" :src="sinaSrc" />
        </div>
        <div>
          <img class="bili" @click="toLink('bili')" :src="biliSrc" />
        </div>
        <div class="douyin">
          <div v-if="showDy" class="dyQrCode">
            <img :src="dyCode" alt="" />
          </div>
          <img
            @click="
              () => {
                if (isWeChatBrowser()) {
                  showDy = !showDy;
                  showWx = false;
                } else {
                  toLink('douyin');
                }
              }
            "
            :src="douyinSrc"
          />
        </div>
        <div>
          <img class="twitter" @click="toLink('twitter')" :src="twitterSrc" />
        </div>
        <div>
          <img class="youtube" @click="toLink('youtube')" :src="youtubeSrc" />
        </div>
      </div>
      <div class="list">
        <div class="place">
          <div class="number">01</div>
          <div class="text-content">
            {{ t('footer.address[0]') }}
          </div>
        </div>

        <div class="place">
          <div class="number">02</div>
          <div class="text-content">
            {{ t('footer.address[1]') }}
          </div>
        </div>

        <div class="place">
          <div class="number">03</div>
          <div class="text-content">
            {{ t('footer.address[2]') }}
          </div>
        </div>
      </div>
      <div class="agreement">
        <span @click="toAgreement('/privacy')">{{
          t('footer.agreement[0]')
        }}</span>
        <span @click="toAgreement('/agreement')">{{
          t('footer.agreement[1]')
        }}</span>
      </div>
      <div class="copyright">
        Copyright &copy;2024 {{ t('footer.copyright') }}
      </div>

      <div v-if="codeList.includes(ipCode)" class="icp" @click="toICP">
        京ICP备2023024520号-2
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineEmits } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import colorlogo from '@/assets/logo_white.png';
import twitter from '@/assets/ic_bird_normal1.png';
import twiActive from '@/assets/ic_bird_hover1.png';
import youtube from '@/assets/ic_youtobe_normal1.png';
import youActive from '@/assets/ic_youtobe_hover1.png';
import wechat from '@/assets/ic_wechat1.png';
import wxCode from '@/assets/wxcode.jpg';
import wechatActive from '@/assets/ic_wechat_1_1.png';
import douyin from '@/assets/ic_douyin1.png';
import dyCode from '@/assets/dycode.png';
import douyinActive from '@/assets/ic_douyin_1_1.png';
import sina from '@/assets/ic_sina1.png';
import sinaActive from '@/assets/ic_sina_1_1.png';
import bili from '@/assets/ic_b1.png';
import biliActive from '@/assets/ic_b_1_1.png';
import Contact from '@/assets/ic_read_normal.png';
import { useI18n } from 'vue-i18n';
import { isWeChatBrowser } from '@/utils';

const { t, locale } = useI18n();
const emits = defineEmits(['change']);
const router = useRouter();
const route = useRoute();
const type = route.query.type || null; // Extract the 'type' query parameter
const twitterSrc = ref(twiActive);
const wechatSrc = ref(wechatActive);
const douyinSrc = ref(douyinActive);
const sinaSrc = ref(sinaActive);
const biliSrc = ref(biliActive);
const youtubeSrc = ref(youActive);
const showWx = ref(false);
const showDy = ref(false);
const ipCode = ref('');
const codeList = ref(['CN', 'HK', 'MO', 'TW']);

const highlightBorder = (event) => {
  event.target.style.borderColor = 'white';
};

const resetBorder = (event) => {
  event.target.style.borderColor = '';
};

const toHome = () => {
  window.location.href = '/';
};
const toAgreement = (path) => {
  router.replace(path);
};
const toAbout = () => {
  router.replace('/about');
};
const toNews = () => {
  router.replace('/news');
};
const toCareers = () => {
  router.replace('/careers');
};
const toICP = () => {
  window.open('https://beian.miit.gov.cn/#/Integrated/index');
};

const toLink = (type) => {
  switch (type) {
    case 'sina':
      window.location.href = '//weibo.com/u/7898745089';
      break;
    case 'bili':
      window.location.href =
        '//space.bilibili.com/3546595559737798?spm_id_from=333.337.0.0';
      break;
    //mp.weixin.qq.com/mp/profile_ext?action=home&__biz=wx71f705b2744c3015
    case 'douyin':
      window.location.href = 'snssdk1128://user/profile/96923482316?refer=web';
      // case 'weixin':
      //   window.location.href =
      //     'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkxMzYyMjg5Mw==&scene=110';
      break;
    case 'twitter':
      window.location.href = '//x.com/GalbotRobotics';
      break;
    case 'youtube':
      window.location.href =
        '//www.youtube.com/channel/UCtWK-1gf-HoocxS-DUirRow';
      break;
  }
};
const toggleFormContainer = () => {
  emits('change');
};

const fetchIpInfo = () => {
  fetch('https://api.ip.sb/geoip ')
    .then((response) => response.json())
    .then((data) => {
      ipCode.value = data.country_code;
      console.log(data, ';;;;');
    });
};
const weixinOutside = () => {
  const weixinElement = document.querySelector('.weixin');
  if (!weixinElement.contains(event.target)) {
    showWx.value = false;
  }
};
const dyOutside = () => {
  const douyinElement = document.querySelector('.douyin');
  if (!douyinElement.contains(event.target)) {
    showDy.value = false;
  }
};
const handleClickOutside = () => {
  weixinOutside();
  dyOutside();
};
onMounted(() => {
  if (type === '1') {
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight});
    },0);
  }
  fetchIpInfo();
  document.addEventListener('click', handleClickOutside);
});
onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style lang="scss" scoped>
.footerStyle {
  width: 100%;
}

.main {
  padding: 1rem 0.24rem 0.2rem 0.24rem;
  box-sizing: border-box;
  overflow: hidden;
}
.main {
  width: 100%;
  .list {
    float: left;
    margin-bottom: 0.5rem;
    .place {
      display: flex;
      align-items: start; /* 调整为顶部对齐 */
      font-family: MyRegular;
      font-size: 0.12rem;
      color: #7b848f;
      line-height: 0.23rem;
      font-weight: 400;
      margin-bottom: 0.12rem;
    }
    .number {
      position: relative;
      top: -0.02rem;
      flex-shrink: 0; /* 保持固定的宽度 */
      margin-right: 0.12rem; /* 添加与文本的间距 */
      font-family: MyRegular;
      font-size: 0.12rem;
      color: #7b848f;
      line-height: 0.23rem;
      font-weight: 400;
    }
    .text-content {
      display: flex;
      align-items: center; /* 垂直居中文本内容 */
      /* 确保大于 .number 的行高进行居中 */
      line-height: 1.5;
    }
  }

  .jump {
    width: 100%;
    float: right;
    margin-top: 0.28rem;
    .jumplink {
      width: 100%;
      padding: 0.12rem;
      box-sizing: border-box;
      /* 预先设置透明的 border-bottom */
      border-bottom: 0.01rem solid transparent;
      /* 应用渐变效果 */
      border-image: linear-gradient(
          to right,
          rgba(238, 238, 238, 0),
          #d8d8d8,
          rgba(238, 238, 238, 0)
        )
        1;
      font-family: Arial;
      font-size: 0.26rem;
      color: #b4bcd0;
      text-align: center;
      font-weight: 400;
    }
    .active {
      color: #fff !important;
      opacity: 1;
    }
  }
  .copyright {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 0.08rem;
    color: rgba(180, 188, 208, 0.6);
    font-family: MyRegular;
    line-height: 0.1rem;
    font-weight: 300;
    margin-bottom: 0.05rem;
  }

  .middle {
    display: flex;
    align-items: center;
    flex-direction: column;
    .middle .logos {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .middle .logos span img {
      transition: transform 0.3s ease; /* 简单的悬停动画（可选） */
    }

    .middle .logos span img:hover {
      transform: scale(1.1); /* 悬停时放大效果（可选） */
    }

    .colorlogo {
      height: 0.26rem;
      margin-bottom: 0.07rem;
    }
  }
  .agreement {
    width: 100%;
    justify-content: center;
    display: flex;
    color: #d1d8e1;
    font-family: MyRegular;
    font-size: 0.12rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.23rem;
    opacity: 0.8;
    margin-bottom: 0.2rem;
    > span:first-child {
      margin-right: 0.2rem;
    }
  }
  .icp {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 0.08rem;
    color: rgba(180, 188, 208, 0.6);
    font-family: MyNotoRegular;
    line-height: 0.1rem;
    font-weight: 300;
    // margin-bottom: 12px;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    outline: none;
  }
  .link {
    padding: 0.2rem 0.3rem 0.4rem 0.3rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    div {
      position: relative;
      display: flex;
      margin-right: 0.16rem;
      img {
        height: 0.3rem;
      }
    }
  }
  .sina {
  }
  .bili {
  }
  .weixin {
    position: relative;
    .wxQrCode {
      position: absolute;
      top: -2rem;
      left: -0.2rem;
      width: 1.8rem;
      height: 1.85rem;
      background: url('../assets/code_bg.png') no-repeat;
      background-size: cover;
      transition: display 0.5s ease, transform 0.3s ease;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 0.1rem;
        transform: translate(-50%, -51%);
        width: 1.7rem;
        height: 1.7rem;
      }
      z-index: 10;
    }
  }
  .douyin {
    position: relative;
    .dyQrCode {
      position: absolute;
      top: -2rem;
      left: -0.4rem;
      width: 1.8rem;
      height: 1.85rem;
      background: url('../assets/code_bg.png') no-repeat;
      background-size: cover;
      transition: display 0.5s ease, transform 0.3s ease;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -51%);
        width: 1.7rem;
        height: 1.7rem;
      }
      z-index: 10;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #b4bcd0;
    font-family: MyRegular;
    font-size: 0.18rem;

    font-weight: 400;
    margin: 0.1rem 0;
    .contact {
      display: flex;
      align-items: center;
      img {
        width: 0.24rem;
        height: 0.24rem;
        margin-left: 0.08rem;
        transform: rotate(0deg);
      }
    }
  }
}
.footerStyle-zh {
  .main {
    .middle .icp {
      font-family: MyNotoRegular;
      font-weight: 400;
    }
    .list .place {
      font-family: MyNoto;
      font-size: 0.13rem;
      font-weight: 300;
    }
    .list .number {
      font-size: 0.14rem;
      font-weight: 300;
    }
    .agreement {
      font-family: MyNoto;
      font-size: 0.11rem;
      font-weight: 400;
    }
    .jump .jumplink {
      font-family: MyNoto;
      font-size: 0.25rem;
    }
    .bottom .contact {
      font-family: MyNoto;
    }
    .copyright {
      font-family: MyNoto;
      font-weight: 400;
    }
  }
}
.form-container {
  max-width: 6rem;
  padding-top: 0.23rem;
}
.form-group {
  margin-bottom: 0.18rem;
}

.form-input {
  width: 5.11rem;
  height: 0.36rem;
  padding: 0.1rem;
  box-sizing: border-box;
  background: #141414;
  opacity: 0.7;
  border: 0.02rem solid rgba(183, 183, 183, 1);
  border-radius: 0.4rem;
}

.form-input:hover,
.form-input:focus,
.form-input:active {
  border: none;
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 1);
}
.form-container .submit-button {
  position: relative;
  display: inline-block;
  width: 1.4rem;
  height: 0.48rem;
  line-height: 0.48rem;
  text-align: center;
  margin: 0.13rem 0 0.82rem;
  background-color: transparent; /* 背景为透明 */
  cursor: pointer;
  opacity: 0.7;
  font-family: 'Microsoft YaHei', sans-serif;
  font-size: 0.2rem;
  color: #ffffff;
  font-weight: 700;
  border: none;
  border-radius: 0.06rem; /* 确保默认状态下有圆角 */
  overflow: hidden;
  background-image: linear-gradient(135deg, #a264ff, #a3efff);
}

.form-container .submit-button::before {
  content: '';
  position: absolute;
  top: 0.02rem;
  left: 0.02rem;
  right: 0.02rem;
  bottom: 0.02rem;
  background-color: #141414;
  border-radius: inherit;
  z-index: 0;
  transition: background-color 0.3s ease-in-out;
}

.form-container .submit-button:hover::before {
  background-color: transparent;
}

.form-container .submit-button__inner {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: inherit;
  text-align: center;
  color: #ffffff; /* 确保文字颜色为白色 */
}
</style>
