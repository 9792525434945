<script setup lang="ts">
// import Logo1 from '@/assets/logo_1.png';
import Logo2 from '@/assets/logo_2.png';
import Logo3 from '@/assets/logo_3.png';
import Logo4 from '@/assets/logo_4.png';
import Logo5 from '@/assets/logo_5.png';
import Logo6 from '@/assets/logo_6.png';
import Logo7 from '@/assets/logo_7.png';
import Logo8 from '@/assets/logo_8.png';
import Logo9 from '@/assets/logo_9.png';
import Logo10 from '@/assets/logo_10.png';
import Logo11 from '@/assets/logo_11.png';
import Logo12 from '@/assets/logo_12.png';
import Logo13 from '@/assets/logo_13.png';
import Logo16 from '@/assets/logo_16.png';
import Logo17 from '@/assets/logo_17.png';

import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import { getLogoList } from '@/api';

const { t, locale } = useI18n();

const props: any = defineProps({
  activeSection: Array,
});
let investors1 = ref<any[]>([
  Logo7,
  Logo13,
  Logo11,
  Logo9,
  Logo3,
  // Logo1,
  Logo16,
  Logo7,
  Logo13,
  Logo11,
  Logo9,
  Logo3,
  // Logo1,
  Logo16,
]);
let investors2 = ref<any[]>([
  Logo17,
  Logo5,
  Logo12,
  Logo6,
  Logo4,
  Logo8,
  Logo10,
  Logo2,
  Logo17,
  Logo5,
  Logo12,
  Logo6,
  Logo4,
  Logo8,
  Logo10,
  Logo2,
]);
const getLogoInfo = async () => {
  try {
    let res: any = await getLogoList({
      key: '1726625950188',
      list_id: 3,
    });
    if (res.code === 200) {
      if (res.data.res_list && res.data.res_list.length) {
        const [firstHalf, secondHalf] = splitArr(res.data.res_list);
        investors1.value = [...firstHalf, ...firstHalf];
        investors2.value = [...secondHalf, ...secondHalf];
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const splitArr = (arr) => {
  const mid = Math.ceil(arr.length / 2); // 计算中间索引
  const firstHalf = arr.slice(0, mid); // 前一半
  const secondHalf = arr.slice(mid); // 后一半
  return [firstHalf, secondHalf];
};
onMounted(() => {
  getLogoInfo();
});
console.log(investors1.value, 'ppp');
</script>

<template>
  <div class="investor" :class="{ 'investor-zh': locale === 'zh' }">
    <div class="title" id="title-5">
      <div :class="{ 'fade-up': props.activeSection.includes('title-5') }">
        {{ t('about.investors.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-5') }"
    >
      {{ t('about.investors.decs') }}
    </div>
    <div class="investor-content">
      <div>
        <div class="container">
          <div class="scrolling-content">
            <div v-for="(item, index) in investors1" :key="index">
              <img :src="item.url || item" alt="" />
            </div>
          </div>
          <div class="scrolling-content">
            <div v-for="(item, index) in investors1" :key="index">
              <img :src="item.url || item" alt="" />
            </div>
          </div>
        </div>
        <div class="container1">
          <div class="scrolling-content">
            <div v-for="(item, index) in investors2" :key="index">
              <img :src="item.url || item" alt="" />
            </div>
          </div>
          <div class="scrolling-content">
            <div v-for="(item, index) in investors2" :key="index">
              <img :src="item.url || item" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.investor {
  max-width: 1640px !important;
  margin: 0 auto;
  .title {
    color: #fff;
    font-family: Arial;
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin-bottom: 12px;
    overflow: hidden;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    // height: 36px;
    color: rgba(180, 188, 208, 0.37);
    font-family: MyRegular;
    font-size: 23px;
    font-style: italic;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    border-radius: 40px;
    > div {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 96%;
        background: linear-gradient(to left, rgba(19, 19, 20), transparent);
        pointer-events: none; /* 使阴影不影响点击事件 */
        z-index: 99;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 96%;
        background: linear-gradient(to right, rgba(19, 19, 20), transparent);
        pointer-events: none; /* 使阴影不影响点击事件 */
        z-index: 99;
      }
    }
    padding-top: 70px;
    padding-bottom: 160px;
    box-sizing: border-box;
    .container {
      margin-bottom: 27px;
    }
    .container,
    .container1 {
      display: flex;
      width: 100%;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
    }
    .container .scrolling-content {
      display: inline-flex;
      animation: scroll 80s linear infinite;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 320px;
        height: 120px;
        background: #1b1d21;
        border-radius: 20px;
        margin: 0 18px;
        font-family: ArialMT, 'Arial';
        font-size: 22px;
        color: #fff;
        img {
          height: 100%;
        }
      }
    }
    .container1 .scrolling-content {
      display: inline-flex;
      animation: scroll1 80s linear infinite;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 320px;
        height: 120px;
        background: #1b1d21;
        border-radius: 20px;
        margin: 0 18px;
        font-family: ArialMT, 'Arial';
        font-size: 22px;
        color: #fff;
        img {
          height: 100%;
        }
      }
    }
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%);
      }
    }
    @keyframes scroll1 {
      0% {
        transform: translateX(-50%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }
}
.investor-zh {
  .title {
    font-family: MyNotoRegular;
    font-size: 68px;
    font-weight: 400;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-weight: 400;
    font-size: 23px;
    font-style: normal;
  }
}

@media (min-width: 769px) and (max-width: 1000px) {
  .investor {
    width: 100% !important;
  }
}
</style>
