<template>
  <div class="footerStyle" :class="{ 'footerStyle-zh': locale === 'zh' }">
    <div class="main">
      <div
        class="main-left"
        id="footer-1"
        :class="{ 'fade-left': activeSection.includes('footer-1') }"
      >
        <img
          id="contact"
          class="colorlogo"
          @mouseenter="hover = true"
          @mouseleave="hover = false"
          @click.stop="toHome"
          :src="hover ? actlogo : logo"
        />
        <div class="left-list">
          <div class="place">
            <div class="number">01</div>
            <div class="text-content">
              {{ t('footer.address[0]') }}
            </div>
          </div>
          <div class="place">
            <div class="number">02</div>
            <div class="text-content">
              {{ t('footer.address[1]') }}
            </div>
          </div>
          <div class="place">
            <div class="number">03</div>
            <div class="text-content">
              {{ t('footer.address[2]') }}
            </div>
          </div>
        </div>
        <div class="left-share">
          <div class="weixin">
            <div class="wxQrCode">
              <img :src="wxCode" alt="" />
            </div>
            <img
              @mouseover="handleMouseOver('wechat')"
              @mouseleave="handleMouseLeave('wechat')"
              :src="wechatSrc"
            />
          </div>
          <div>
            <img
              class="sina"
              @mouseover="handleMouseOver('sina')"
              @mouseleave="handleMouseLeave('sina')"
              @click="toLink('sina')"
              :src="sinaSrc"
            />
          </div>
          <div>
            <img
              class="bili"
              @mouseover="handleMouseOver('bili')"
              @mouseleave="handleMouseLeave('bili')"
              @click="toLink('bili')"
              :src="biliSrc"
            />
          </div>
          <div class="douyin">
            <div class="dyQrCode">
              <img :src="dyCode" alt="" />
            </div>
            <img
              @mouseover="handleMouseOver('douyin')"
              @mouseleave="handleMouseLeave('douyin')"
              :src="douyinSrc"
            />
          </div>
          <div>
            <img
              class="twitter"
              @mouseover="handleMouseOver('twitter')"
              @mouseleave="handleMouseLeave('twitter')"
              @click="toLink('twitter')"
              :src="twitterSrc"
            />
          </div>
          <div>
            <img
              class="youtube"
              @mouseover="handleMouseOver('youtube')"
              @mouseleave="handleMouseLeave('youtube')"
              @click="toLink('youtube')"
              :src="youtubeSrc"
            />
          </div>
        </div>
        <div class="contact">
          <div @click.stop="toggleFormContainer">
            {{ t('footer.contact') }}
            <img
              :class="`${isFormVisible ? 'active' : ''}`"
              :src="Contact"
              alt=""
            />
          </div>
          <img v-show="pedding" class="peddingIMG" :src="loaddingImg" alt="" />
          <!-- <img
          class="peddingIMG"
          :src="loaddingImg"
          alt=""
        /> -->
          <span v-if="isSuccess !== '' && isSuccess" class="successTip">{{
            locale === 'en' ? 'Submission successfully!' : '提交成功!'
          }}</span>
          <!-- <span class="successTip">Submission successfully!</span> -->
          <span v-if="isSuccess !== '' && !isSuccess" class="failTip"
            >>{{ locale === 'en' ? 'Submission Failed!' : '提交失败!' }}</span
          >
        </div>
      </div>
      <div
        class="main-right"
        id="footer-2"
        :class="{ 'fade-right': activeSection.includes('footer-2') }"
      >
        <div class="right-jump">
          <div
            class="jumplink"
            :class="{ active: route.path === '/about' }"
            @click.stop
          >
            <span @click.stop="toAbout">{{ t('footer.links[0]') }}</span>
          </div>
          <div
            class="jumplink"
            :class="{ active: route.path === '/news' }"
            @click.stop
          >
            <span @click.stop="toNews">{{ t('footer.links[1]') }}</span>
          </div>
          <div
            class="jumplink"
            :class="{ active: route.path === '/careers' }"
            @click.stop
          >
            <span @click.stop="toCareers">{{ t('footer.links[2]') }}</span>
          </div>
        </div>
        <div class="ipinfo">
          <div class="agreement">
            <span @click="toAgreement('/privacy')">{{
              t('footer.agreement[0]')
            }}</span>
            <span @click="toAgreement('/agreement')">{{
              t('footer.agreement[1]')
            }}</span>
          </div>
          <div>
            <div
              :class="{
                icp: true,
                visible: codeList.includes(ipCode),
                hidden: !codeList.includes(ipCode),
              }"
            >
              <span @click.stop="toICP">京ICP备2023024520号-2</span>
            </div>
            <div class="copyright">
              Copyright &copy;2024 {{ t('footer.copyright') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="form-container"
      :class="{
        'animate-in': isFormVisible,
        'animate-out': !isFormVisible && !animating,
      }"
      v-show="isFormVisible || animating"
      id="contact-form"
    >
      <form @submit.prevent="onSubmit">
        <div class="form-group">
          <input
            type="text"
            v-model="form.firstName"
            id="firstName"
            class="form-input"
            @focus="highlightBorder($event)"
            @blur="resetBorder($event)"
            @input="highlightBorder($event)"
            :placeholder="t('footer.form.first')"
          />
          <span
            v-if="errors.firstName && locale === 'en'"
            class="error-message"
            >{{ errors.firstName.msg }}</span
          >
          <span
            v-if="errors.firstName.msg && locale === 'zh'"
            class="error-message"
            >请输入姓氏</span
          >
        </div>
        <div class="form-group">
          <input
            type="text"
            v-model="form.lastName"
            id="lastName"
            class="form-input"
            @focus="highlightBorder($event)"
            @blur="resetBorder($event)"
            @input="highlightBorder($event)"
            :placeholder="t('footer.form.last')"
          />
          <span
            v-if="errors.lastName && locale === 'en'"
            class="error-message"
            >{{ errors.lastName.msg }}</span
          >
          <span
            v-if="errors.lastName.msg && locale === 'zh'"
            class="error-message"
            >请输入名字</span
          >
        </div>
        <div class="form-group">
          <input
            type="email"
            id="email"
            v-model="form.email"
            class="form-input"
            @focus="highlightBorder($event)"
            @blur="resetBorder($event)"
            @input="highlightBorder($event)"
            :placeholder="t('footer.form.email')"
          />
          <span v-if="errors.email && locale === 'en'" class="error-message">{{
            errors.email.msg
          }}</span>
          <span v-if="errors.email.msg && locale === 'zh'" class="error-message"
            >请输入正确邮箱</span
          >
        </div>
        <div v-if="languageStore.language === 'zh'" class="form-group">
          <input
            type="text"
            id="phone"
            v-model="form.phone"
            class="form-input"
            @focus="highlightBorder($event)"
            @blur="resetBorder($event)"
            @input="highlightBorder($event)"
            :placeholder="t('footer.form.phone')"
          />
          <span v-if="errors.phone && locale === 'en'" class="error-message">{{
            errors.phone.msg
          }}</span>
          <span v-if="errors.phone.msg && locale === 'zh'" class="error-message"
            >请输入正确手机号</span
          >
        </div>
        <div class="form-group">
          <input
            type="text"
            v-model="form.msg"
            id="message"
            class="form-input"
            @focus="highlightBorder($event)"
            @blur="resetBorder($event)"
            @input="highlightBorder($event)"
            :placeholder="t('footer.form.message')"
          />
          <span v-if="errors.msg && locale === 'en'" class="error-message">{{
            errors.msg.msg
          }}</span>
          <span
            v-if="errors.msg.msg && locale === 'zh'"
            class="error-message"
            >{{ '请输入信息' }}</span
          >
        </div>
        <button type="submit" class="submit-button">
          <span class="submit-button__inner">{{
            t('footer.form.button')
          }}</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import logo from '@/assets/logo_white.svg';
import actlogo from '@/assets/logo_color.svg';
import twitter from '@/assets/ic_bird_normal.png';
import twiActive from '@/assets/ic_bird_hover.png';
import youtube from '@/assets/ic_youtobe_normal.png';
import youActive from '@/assets/ic_youtobe_hover.png';
import wechat from '@/assets/ic_wechat.svg';
import wxCode from '@/assets/wxcode.jpg';
import wechatActive from '@/assets/ic_wechat_1.svg';
import douyin from '@/assets/ic_douyin.svg';
import dyCode from '@/assets/dycode.png';
import douyinActive from '@/assets/ic_douyin_1.svg';
import sina from '@/assets/ic_sina.svg';
import sinaActive from '@/assets/ic_sina_1.svg';
import bili from '@/assets/ic_b.svg';
import biliActive from '@/assets/ic_b_1.svg';
import Contact from '@/assets/ic_read_normal.png';
import { uploadMsg } from '@/api';
import loaddingImg from '@/assets/loding.png';

import { useLanguageStore } from '@/store/languageStore';

import { useI18n } from 'vue-i18n';
import { observeFun } from '@/utils';
let observers = [];
const { t, locale } = useI18n();
const languageStore = useLanguageStore();
const activeSection = ref([]);
const sections = ref(['footer-1', 'footer-2']);
const router = useRouter();
const route = useRoute();
const type = route.query.type || null; // Extract the 'type' query parameter
const hover = ref(false); // 新增hover状态
const twitterSrc = ref(twitter);
const wechatSrc = ref(wechat);
const douyinSrc = ref(douyin);
const sinaSrc = ref(sina);
const biliSrc = ref(bili);
const youtubeSrc = ref(youtube);
const isFormVisible = ref(false);
const isSuccess = ref('');
const ipCode = ref('');
const codeList = ref(['CN', 'HK', 'MO', 'TW']);
const animating = ref(false);
const lastScrollPosition = ref(0);
const pedding = ref(false);

const form = ref({
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  msg: '',
});

const errors = reactive({
  firstName: {
    msg: null,
    require: false,
  },
  lastName: {
    msg: null,
    require: false,
  },
  email: {
    msg: null,
    require: false,
  },
  phone: {
    msg: null,
    require: false,
  },
  msg: {
    msg: null,
    require: false,
  },
});

const rules = {
  firstName: [(value) => !!value || 'First Name is required.'],
  lastName: [(value) => !!value || 'Last Name is required.'],
  email: [
    (value) => !!value || 'Email is required.',
    (value) => /.+@.+\..+/.test(value) || 'Email must be valid.',
  ],
  msg: [(value) => !!value || 'Message is required.'],
  phone: [
    (value) => {
      const phonePattern =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; // 简单的手机号验证模式
      if (!phonePattern.test(value)) {
        return 'Please enter the correct phone number';
      } else {
        return '';
      }
    },
  ],
};

const validateField = () => {
  let valid = true;

  if (languageStore.language === 'en') {
    delete form.value.phone;
  }

  const validateEmptyField = (field) => {
    if (form.value[field] === '') {
      const errorMessage = errorMsg(field);
      errors[field].msg = errorMessage;
      errors[field].require = false;
      return false;
    }
    return true;
  };

  const validatePhoneEmailFields = (field) => {
    if (field === 'phone' || field === 'email') {
      const errorMessage = errorMsg(field);
      errors[field].msg = errorMessage;
      errors[field].require = false;
      return !errorMessage;
    }
    return true;
  };

  const setErrorMessage = (field, message, require) => {
    errors[field].msg = message;
    errors[field].require = require;
  };

  for (const field in form.value) {
    if (!validateEmptyField(field)) {
      valid = false;
    } else {
      if (languageStore.language === 'zh') {
        if (!validatePhoneEmailFields(field)) {
          valid = false;
        } else {
          setErrorMessage(field, null, true);
        }
      } else {
        setErrorMessage(field, null, true);
      }
    }
  }

  console.log(valid, 'valid');
  return valid;
};

const errorMsg = (field) => {
  for (const rule of rules[field]) {
    const errorMessage = rule(form.value[field]);
    if (errorMessage !== true) {
      return errorMessage;
    }
  }
};

const highlightBorder = (event) => {
  event.target.style.borderColor = 'white';
};

const resetBorder = (event) => {
  event.target.style.borderColor = '';
};

const toAgreement = (path) => {
  router.replace(path);
};
const toNews = () => {
  router.replace('/news');
};
const toAbout = () => {
  router.replace('/about');
};
const toCareers = () => {
  router.replace('/careers');
};
const toLink = (type) => {
  switch (type) {
    case 'sina':
      window.open('//weibo.com/u/7898745089');
      break;
    case 'bili':
      window.open(
        '//space.bilibili.com/3546595559737798?spm_id_from=333.337.0.0'
      );
      break;
    case 'twitter':
      window.open('//x.com/GalbotRobotics');
      break;
    case 'youtube':
      window.open('//www.youtube.com/channel/UCtWK-1gf-HoocxS-DUirRow');
      break;
  }
};

const toICP = () => {
  window.open('https://beian.miit.gov.cn/#/Integrated/index');
};

const toHome = () => {
  window.location.href = '/';
};

const toggleFormContainer = () => {
  animating.value = true; // 开始动画
  if (!isFormVisible.value) {
    // 记录滚动位置
    lastScrollPosition.value = window.scrollY;
    isFormVisible.value = true;
    nextTick(() => {
      const formContainer = document.getElementById('contact-form');
      formContainer.scrollIntoView({
        behavior: 'smooth',
      });
    });
  } else {
    // 添加淡出动画类
    // const formContainer = document.getElementById("contact-form");
    // formContainer.classList.add("fade-out");
    isFormVisible.value = false;
    if (type === '1') {
      window.scrollTo({
        top: window.scrollY - 500,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: lastScrollPosition.value,
        behavior: 'smooth',
      });
    }
    setTimeout(() => {
      animating.value = false;
      // // 移除淡出动画类
      // formContainer.classList.remove("fade-out");
    }, 500); // 这个时间需要与你的动画时间一致
  }
};
const handleMouseOver = (type) => {
  switch (type) {
    case 'wechat':
      wechatSrc.value = wechatActive;
      break;
    case 'douyin':
      douyinSrc.value = douyinActive;
      break;
    case 'sina':
      sinaSrc.value = sinaActive;
      break;
    case 'bili':
      biliSrc.value = biliActive;
      break;
    case 'twitter':
      twitterSrc.value = twiActive;
      break;
    case 'youtube':
      youtubeSrc.value = youActive;
      break;
  }
};
const handleMouseLeave = (type) => {
  switch (type) {
    case 'wechat':
      wechatSrc.value = wechat;
      break;
    case 'douyin':
      douyinSrc.value = douyin;
      break;
    case 'sina':
      sinaSrc.value = sina;
      break;
    case 'bili':
      biliSrc.value = bili;
      break;
    case 'twitter':
      twitterSrc.value = twitter;
      break;
    case 'youtube':
      youtubeSrc.value = youtube;
      break;
  }
};

const onSubmit = async () => {
  try {
    if (validateField()) {
      pedding.value = true;
      toggleFormContainer();
      const param = { ...form.value };
      if (languageStore.language === 'en') {
        param.phone = '';
      }
      let res = await uploadMsg(param);
      if (res.code === 200) {
        pedding.value = false;
        isSuccess.value = true;
        setTimeout(() => {
          isSuccess.value = '';
        }, 2000);
        form.value.firstName = '';
        form.value.lastName = '';
        form.value.email = '';
        form.value.msg = '';
        form.value.phone = '';
      } else {
        pedding.value = false;
        isSuccess.value = false;
        setTimeout(() => {
          isSuccess.value = '';
        }, 2000);
      }
    }
  } catch (error) {
    pedding.value = false;
    isSuccess.value = false;
    setTimeout(() => {
      isSuccess.value = '';
    }, 2000);
  }
};

const fetchIpInfo = () => {
  fetch('https://api.ip.sb/geoip ')
    .then((response) => response.json())
    .then((data) => {
      ipCode.value = data.country_code;
      languageStore.setIp(
        codeList.value.includes(data.country_code) ? '国内' : '国外'
      );
    });
};

onMounted(() => {
  if (type === '1') {
    isFormVisible.value = true;
    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }, 0);
  }
  fetchIpInfo();
  observeFun(sections, activeSection, observers, 0.1, 'footer');
});

onUnmounted(() => {
  observers.forEach((observer) => observer.disconnect());
});
</script>

<style lang="scss" scoped>
.footerStyle {
  max-width: 100%;
  // height: 900px;
  margin: 0 auto;
  background-color: #111112;
  color: white;
  padding-top: 113px;
  padding-bottom: 201px;
}

.footer-main {
  padding: 17px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  font-size: 14px;
  overflow-x: hidden;
}

.main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &-left {
    opacity: 0;
    display: flex;
    flex-direction: column;
    .colorlogo {
      cursor: pointer;
      height: 40px;
      margin-bottom: 65px;
      position: relative;
      left: -17px;
    }
    .left-list {
      float: left;
      .place {
        display: flex;
        align-items: start; /* 调整为顶部对齐 */
        width: 510px;
        color: #7b848f;
        font-family: MyRegular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 10px;
      }
      .number {
        position: relative;
        top: -2px;
        flex-shrink: 0; /* 保持固定的宽度 */
        margin-right: 10px; /* 添加与文本的间距 */
        color: #7b848f;
        text-align: justify;
        font-family: MyRegular;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
      }
      .text-content {
        display: flex;
        align-items: center; /* 垂直居中文本内容 */
        /* 确保大于 .number 的行高进行居中 */
        line-height: 1.5;
      }
    }
    .left-share {
      width: 370px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      margin-bottom: 95px;
      margin-top: 35px;
      img {
        cursor: pointer;
        height: 30px;
      }
      div {
        display: flex;
      }
      .left-share div img {
        cursor: pointer;
        height: 36px; /* 根据需要调整图标的大小 */
        margin-right: 20px;
        transition: transform 0.3s ease; /* 简单的悬停动画（可选） */
      }

      .left-share div img:hover {
        transform: scale(1.1); /* 悬停时放大效果（可选） */
      }

      .sina {
      }
      .bili {
      }
      .weixin {
        position: relative;
        .wxQrCode {
          position: absolute;
          display: none;
          top: -210px;
          left: -15px;
          width: 185px;
          height: 190px;
          background: url('../assets/code_bg.png') no-repeat;
          background-size: 100%;
          transition: display 0.5s ease, transform 0.3s ease;
          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -51%);
            width: 170px;
            height: 170px;
            border-radius: 10px;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
      .weixin:hover .wxQrCode {
        display: block;
      }
      .douyin {
        > img {
        }
        position: relative;
        .dyQrCode {
          position: absolute;
          display: none;
          top: -210px;
          left: -15px;
          width: 185px;
          height: 190px;
          background: url('../assets/code_bg.png') no-repeat;
          background-size: cover;
          transition: display 0.5s ease, transform 0.3s ease;
          > img {
            width: 170px;
            height: 170px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -51%);
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
      .douyin:hover .dyQrCode {
        display: block;
      }
      .twitter {
        height: 29px;
        cursor: pointer;
      }
      .youtube {
        cursor: pointer;
      }
    }
    .contact {
      display: flex;
      align-items: center;
      color: rgba(227, 241, 255, 0.7);
      text-align: justify;
      font-family: MyRegular;
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: color 0.3s ease-in-out;
      > div {
        display: flex;
        align-items: center;
        &:hover {
          color: #fff;
          cursor: pointer;
        }
      }
      .peddingIMG {
        width: 38px;
        height: 38px;
        animation: rotate 2s linear infinite;
      }
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }

      .successTip {
        margin-left: 30px;
        font-family: Verdana;
        font-size: 14px;
        color: #27eaff;
        font-weight: 400;
      }
      .failTip {
        margin-left: 30px;
        font-family: Verdana;
        font-size: 14px;
        color: #ffffff;
        opacity: 0.7;
        font-weight: 400;
      }
      img {
        cursor: pointer;
        width: 35px;
        height: 35px;
        margin-left: 16px;
        transition: transform 0.3s ease-in-out;
      }
      .active {
        transform: rotate(90deg);
      }
    }
  }
  &-right {
    position: relative;
    opacity: 0;
    .right-jump {
      .jumplink {
        height: 62px;
        width: 650px;
        /* 预先设置透明的 border-bottom */
        border-bottom: 1px solid transparent;
        /* 应用渐变效果 */
        border-image: linear-gradient(to right, rgba(238, 238, 238, 0), #d8d8d8)
          1;
        color: rgba(255, 255, 255, 0.8);
        text-align: right;
        font-family: MyRegular;
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 36px;
        span {
          opacity: 0.8;
          cursor: pointer;
          transition: color 0.3s ease;
          color: rgba(180, 188, 208, 1);
          &:hover {
            opacity: 1;
            // font-size: 40px;
            color: #fff;
          }
        }
      }
      .active {
        span {
          color: #fff !important;
          opacity: 1;
        }
      }
    }
    .ipinfo {
      position: absolute;
      bottom: 0;
      right: 0;

      div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .icp {
          position: relative;
          top: 1px;
          color: #b2bac4;
          opacity: 0.8;
          text-align: justify;
          font-family: MyNotoRegular;
          font-size: 12px;
          font-style: normal;
          visibility: hidden;
          margin-right: 20px;
          span:hover {
            color: #ffffff;
            cursor: pointer;
          }
        }
        .copyright {
          color: #b2bac4;
          text-align: justify;
          font-family: MyRegular;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          opacity: 0.8;
        }

        .icp.visible {
          visibility: visible;
        }
      }
    }
    .agreement {
      color: #d1d8e1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 8px;
      text-align: justify;
      font-size: 18px;
      font-family: MyRegular;
      font-weight: 400;
      opacity: 0.8;

      span {
        cursor: pointer;
        &:hover {
          color: #fff;
          opacity: 1;
          transition: color 0.3s ease, opacity 0.3s ease;
        }
      }
      span:first-child {
        margin-right: 30px;
      }
      // span:nth-child(2) {
      //   margin-right: 30px;
      // }
    }
  }
  .fade-left {
    animation: fadeLeft 1.2s ease-in-out;
    opacity: 1;
  }
  .fade-right {
    animation: fadeRight 1.2s ease-in-out;
    opacity: 1;
  }
}
.footerStyle-zh {
  .main {
    .main-right {
      .right-jump {
        .jumplink {
          font-family: MyNoto;
          font-size: 40px;
          font-weight: 400;
        }
      }
      .ipinfo {
        .agreement {
          font-family: MyNoto;
          font-size: 18px;
          font-weight: 400;
        }
        .icp {
          font-family: MyNoto;
          font-size: 14px;
          font-weight: 400;
        }
        .copyright {
          font-family: MyNotoRegular;
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
    .main-left {
      .left-list {
        .place {
          font-family: MyNoto;
          font-weight: 300;
          font-size: 18px;
          line-height: 23px;
        }
        .number {
          top: 1px;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
        }
      }
      .contact {
        > div {
          font-family: MyNoto;
          font-weight: 400;
          font-size: 28px;
        }
      }
    }
  }
}

.footerStyle-zh .main .list .place {
  font-family: MicrosoftYaHei, Verdana;
  font-size: 16px;
}
.footerStyle-zh .main .jump .jumplink {
  font-family: MicrosoftYaHei-Bold, ArialMT, 'Arial';
  font-size: 30px;
  letter-spacing: 0.33px;
  font-weight: 700;
}

.footerStyle-zh .middle .icp {
  font-family: MicrosoftYaHei, Verdana;
  font-size: 14px;
}

.footerStyle-zh .bottom .contact > div {
  font-family: MicrosoftYaHei-Bold, Verdana-Bold;
  font-size: 22px;
}
.footerStyle-zh .bottom .copyright {
  font-family: MicrosoftYaHei, Verdana;
  font-size: 14px;
}
.form-container {
  max-width: 600px;
  margin-top: 30px;
}
.form-container {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  transform: translateY(-20px);
}

.form-container.animate-in {
  opacity: 1;
  transform: translateY(0);
}

// .form-container.animate-out,
// .form-container.fade-out {
//   opacity: 0;
//   transform: translateY(-20px);
//   transition: opacity 0.5s ease-in-out;
// }
.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 68px;
  .error-message {
    color: #ffffff;
    opacity: 0.7;
    font-size: 0.875rem;
    padding-top: 6px;
  }
  // &::after {
  //   position: absolute;
  //   content: '*';
  //   width: 10px;
  //   height: 10px;
  //   color: #ffffff;
  //   opacity: 0.7;
  //   left: -12px;
  //   top: 12px;
  // }
}

.form-input {
  width: 511px;
  height: 36px;
  padding: 10px;
  box-sizing: border-box;
  background: #141414;
  color: #fff;
  opacity: 0.7;
  border: 1px solid rgba(183, 183, 183, 1);
  border-radius: 4px;
}
.form-input:hover,
.form-input:focus,
.form-input:active {
  border: none;
  outline: none;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 1);
}
.form-container .submit-button {
  position: relative;
  display: inline-block;
  width: 140px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  margin: 13px 0 82px;
  background-color: transparent; /* 背景为透明 */
  cursor: pointer;
  opacity: 0.7;
  font-family: 'Microsoft YaHei', sans-serif;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  border: none;
  border-radius: 6px; /* 确保默认状态下有圆角 */
  overflow: hidden;
  // background-image: linear-gradient(135deg, #a264ff, #a3efff);
  background: url('../assets/button3.png') no-repeat;
  background-size: cover;
  padding: 0; /* 移除默认padding */
}

.form-container .submit-button::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: #141414;
  border-radius: inherit;
  z-index: 0;
  transition: background-color 0.3s ease-in-out;
}

.form-container .submit-button:hover::before {
  background-color: transparent;
}

.form-container .submit-button__inner {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: inherit;
  text-align: center;
  color: #ffffff; /* 确保文字颜色为白色 */
}
input[type='text']:-internal-autofill-selected,
input[type='email']:-internal-autofill-selected,
textarea:-internal-autofill-selected,
select:-internal-autofill-selected {
  background-color: #141414 !important;
  box-shadow: 0 0 0px 1000px #141414 inset !important;
  color: #ffffff !important; /* 确保文字颜色为白色 */
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #141414 !important;
  box-shadow: 0 0 0px 1000px #141414 inset !important;
  -webkit-text-fill-color: #ffffff !important; /* 使用 -webkit-text-fill-color */
}

/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 1230px) {
  .footerStyle {
    width: 100%;
  }
}

@keyframes fadeLeft {
  0% {
    transform: translateX(-400px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeRight {
  0% {
    transform: translateX(400px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
