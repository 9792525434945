<script setup lang="ts">
import { reactive, ref, onMounted } from 'vue';
import Read from '@/assets/ic_read_normal.png';
import { getRolesList } from '@/api';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});

const pageObj: any = reactive({ page: 1, page_size: 100 });

const tableList: any = ref([]);

/**
 * @description 获取新闻列表
 */
const getRoles = async () => {
  try {
    try {
      let res = await getRolesList(pageObj);
      if (res.code === 200) {
        tableList.value = res.data;
      }
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  } catch (error) {
    console.log(error);
  }
};

const jumpCategory = (category: string) => {
  // 创建a元素
  const link = document.createElement('a');
  // 设置链接的目标URL
  link.href =
    category === 'Social Recruitment' || category === '社会招聘'
      ? 'https://owm6ymi5v9b.jobs.feishu.cn/870797'
      : 'https://owm6ymi5v9b.jobs.feishu.cn/584984/';

  // 设置target属性为_blank
  link.target = '_blank';

  // 将a元素添加到文档中（这一步可以不做）
  document.body.appendChild(link);

  // 模拟点击该链接
  link.click();

  // 如果不想在文档中保留该链接，可以移除
  document.body.removeChild(link);
};

const tolink = (item: any) => {
  window.open(item.url);
};

onMounted(() => {
  getRoles();
});
</script>

<template>
  <div class="roles" :class="{ 'roles-zh': locale === 'zh' }">
    <div class="title" id="title-2">
      <div :class="{ 'fade-up': props.activeSection.includes('title-2') }">
        {{ t('careers.positions.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-2') }"
    >
      {{ t('careers.positions.decs') }}
    </div>
    <div class="roles-content">
      <template v-for="(table, index) in tableList">
        <div class="table" v-if="table.list.length" :key="index">
          <div class="table-head">
            <div class="head-title">
              {{ locale === 'zh' ? table.cn_category : table.category }}
            </div>
            <div @click="jumpCategory(table.category)" class="head-more">
              <span>{{ locale === 'zh' ? '查看更多' : 'More' }}</span>
              <img :src="Read" alt="" />
            </div>
          </div>
          <div class="table-body">
            <div class="body-line"></div>
            <div class="table-body-content">
              <template v-for="item in table.list">
                <div class="table-body-item" @click="tolink(item)">
                  <div>
                    {{ locale === 'zh' ? item.cn_post_name : item.post_name }}
                  </div>
                  <div class="area">
                    {{ locale === 'zh' ? item.cn_base_loc : item.base_loc }}
                    <div class="apply">
                      <a>{{ locale === 'zh' ? '立即申请' : 'Apply' }}</a>
                      <div class="arrow"></div>
                    </div>
                  </div>
                </div>
                <div class="item-line"></div>
              </template>
            </div>
            <div class="body-line"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.roles {
  .title {
    color: #fff;
    font-family: Arial;
    font-size: 0.33rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    padding: 0.14rem 0.24rem 0 0.24rem;
    font-family: MyRegular;
    color: rgba(180, 188, 208, 0.55);
    font-size: 0.15rem;
    font-style: italic;
    font-weight: 600;
    line-height: 0.2rem;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .about-main .us .subtitle[data-v-9626e804] {
    line-height: 0.2rem;
    font-weight: 400;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    padding: 0.21rem 0.24rem 0.21rem 0.24rem;
    box-sizing: border-box;
    .table {
      &-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.16rem;
        .head-title {
          box-sizing: border-box;
          font-family: Arial;
          font-size: 0.16rem;
          color: #ffffff;
          line-height: normal;
          font-weight: 400;
        }
        .head-more {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          justify-self: center;
          font-family: Arial;
          font-size: 0.16rem;
          background: linear-gradient(180deg, #ebf2f5 42.11%, #c5d0d8 97.75%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          font-weight: 400;
          text-decoration: none;
          -webkit-tap-highlight-color: transparent !important;
          tap-highlight-color: transparent !important;
          outline: none;
          // &:focus {
          //   outline: none;
          //   background: transparent;
          //   -webkit-tap-highlight-color: transparent;
          // }
          // &:active {
          //   background: transparent;
          //   outline: none;
          //   -webkit-tap-highlight-color: transparent;
          // }
          img {
            width: 0.24rem;
            height: 0.24rem;
            margin-left: 0.08rem;
            transform: rotate(0deg);
          }
          span {
            font-family: Verdana;
            font-weight: 400;
          }
          span:hover {
            color: #ffffff;
            font-weight: 500;
          }
        }
      }
      &-body {
        width: 100%;
        &-content {
          width: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          .table-body-item {
            padding: 0.13rem 0.1rem 0.13rem 0px;
            box-sizing: border-box;
            font-family: MyRegular;
            font-size: 0.14rem;
            line-height: 0.2rem;
            color: #b4bcd0;
            font-weight: 300;
            -webkit-tap-highlight-color: transparent !important;
            tap-highlight-color: transparent !important;
            outline: none;
            opacity: 0.9;
            &:focus {
              outline: none;
              background: transparent;
              -webkit-tap-highlight-color: transparent;
            }
            &:active {
              background: transparent;
              outline: none;
              -webkit-tap-highlight-color: transparent;
            }
            .area {
              font-size: 0.13rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 0.12rem;
            }
            &:hover {
              cursor: pointer;
              color: rgba(255, 255, 255, 0.9);
              .apply a {
                color: #ffffff;
              }
              .apply .arrow {
                width: 0.12rem;
                height: 0.1rem;
                background: url('../../../../assets/ic_arrow_hover.png')
                  no-repeat;
                background-size: cover;
              }
            }
            .apply {
              font-size: 0.13rem;
              display: flex;
              justify-content: center;
              align-items: center;
              -webkit-tap-highlight-color: transparent !important;
              tap-highlight-color: transparent !important;
              outline: none;
              &:focus {
                outline: none;
                background: transparent;
                -webkit-tap-highlight-color: transparent;
              }
              &:active {
                background: transparent;
                outline: none;
                -webkit-tap-highlight-color: transparent;
              }
              a {
                position: relative;
                color: #b4bcd0;
                text-decoration: none;
                margin-right: 0.1rem;
                z-index: 10;
                -webkit-tap-highlight-color: transparent !important;
                tap-highlight-color: transparent !important;
                outline: none;
                &:focus {
                  outline: none;
                  background: transparent;
                  -webkit-tap-highlight-color: transparent;
                }
                &:active {
                  background: transparent;
                  outline: none;
                  -webkit-tap-highlight-color: transparent;
                }
              }
              .arrow {
                position: relative;
                top: 0.01rem;
                width: 0.12rem;
                height: 0.12rem;
                background: url('../../../../assets/ic_arrow.png') no-repeat;
                background-size: cover;
              }
            }
          }
          .item-line {
            position: relative;
            display: block;
            width: 100%;
            height: 0.01rem;
            margin: 0 auto;
            border-bottom: 0.01rem solid #bcbcbc;
            // background: linear-gradient(
            //   to right,
            //   transparent,
            //   #bcbcbc,
            //   #bcbcbc,
            //   #bcbcbc,
            //   #bcbcbc,
            //   #bcbcbc,
            //   transparent
            // );
            opacity: 0.2;
          }
        }
        .body-line {
          position: relative;
          width: 100%;
          margin: 0 auto;
          height: 0.01rem;
          border: none;
          top: -0.01rem;
          border-bottom: 0.01rem solid #777;
        }
      }
    }
    .table:first-child {
      margin-bottom: 0.4rem;
    }
  }
}
.roles-zh {
  .title {
    font-size: 0.3rem;
    font-family: MyNotoRegular;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-size: 0.14rem;
    font-weight: 400;
    font-style: normal;
  }
  .roles-content {
    .table-head .head-title {
      font-family: MyNoto;
      font-size: 0.17rem;
    }
    .table-head .head-more {
      font-family: MyNoto;
      font-size: 0.15rem;
      font-weight: 400;
    }
    .table-body-content .table-body-item {
      font-family: MyNoto;
    }
  }
}
</style>
