<script setup lang="ts">
// import Logo1 from '@/assets/logo_1.png';
import Logo2 from '@/assets/logo_2.png';
import Logo3 from '@/assets/logo_3.png';
import Logo4 from '@/assets/logo_4.png';
import Logo5 from '@/assets/logo_5.png';
import Logo6 from '@/assets/logo_6.png';
import Logo7 from '@/assets/logo_7.png';
import Logo8 from '@/assets/logo_8.png';
import Logo9 from '@/assets/logo_9.png';
import Logo10 from '@/assets/logo_10.png';
import Logo11 from '@/assets/logo_11.png';
import Logo12 from '@/assets/logo_12.png';
import Logo13 from '@/assets/logo_13.png';
import Logo16 from '@/assets/logo_16.png';
import Logo17 from '@/assets/logo_17.png';
import { useI18n } from 'vue-i18n';
import { getLogoList } from '@/api';
import { onMounted, ref } from 'vue';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});

let investors1 = ref<any[]>([
  Logo7,
  Logo13,
  Logo11,
  Logo9,
  Logo3,
  // Logo1,
  Logo16,
  Logo7,
  Logo13,
  Logo11,
  Logo9,
  Logo3,
  // Logo1,
  Logo16,
]);
let investors2 = ref<any[]>([
  Logo17,
  Logo5,
  Logo12,
  Logo6,
  Logo4,
  Logo8,
  Logo10,
  Logo2,
  Logo17,
  Logo5,
  Logo12,
  Logo6,
  Logo4,
  Logo8,
  Logo10,
  Logo2,
]);

const getLogoInfo = async () => {
  try {
    let res: any = await getLogoList({
      key: '1726625950188',
      list_id: 3,
    });
    if (res.code === 200) {
      if (res.data.res_list && res.data.res_list.length) {
        const [firstHalf, secondHalf] = splitArr(res.data.res_list);
        investors1.value = [...firstHalf, ...firstHalf];
        investors2.value = [...secondHalf, ...secondHalf];
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const splitArr = (arr) => {
  const mid = Math.ceil(arr.length / 2); // 计算中间索引
  const firstHalf = arr.slice(0, mid); // 前一半
  const secondHalf = arr.slice(mid); // 后一半
  return [firstHalf, secondHalf];
};
onMounted(() => {
  getLogoInfo();
});
</script>

<template>
  <div class="investor" :class="{ 'investor-zh': locale === 'zh' }">
    <div class="title" id="title-5">
      <div :class="{ 'fade-up': props.activeSection.includes('title-5') }">
        {{ t('about.investors.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-5') }"
    >
      {{ t('about.investors.decs') }}
    </div>
    <div class="investor-content">
      <div>
        <div class="container">
          <div class="scrolling-content">
            <div v-for="(item, index) in investors1" :key="index">
              <img :src="item.url || item" alt="" />
            </div>
          </div>
        </div>
        <div class="container1">
          <div class="scrolling-content">
            <div v-for="(item, index) in investors2" :key="index">
              <img :src="item.url || item" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.investor {
  .title {
    color: #fff;
    font-family: Arial;
    font-size: 0.33rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    width: 3rem;
    padding: 0.12rem 0.24rem 0 0.24rem;
    font-family: MyRegular;
    color: rgba(180, 188, 208, 0.55);
    font-size: 0.15rem;
    font-style: italic;
    font-weight: 600;
    line-height: 0.2rem;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    overflow: hidden;
    padding: 0 0.24rem 0.21rem 0.24rem;
    > div {
      overflow: hidden;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 96%;
        background: linear-gradient(to left, rgba(19, 19, 20), transparent);
        pointer-events: none; /* 使阴影不影响点击事件 */
        z-index: 100;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 96%;
        background: linear-gradient(to right, rgba(19, 19, 20), transparent);
        pointer-events: none; /* 使阴影不影响点击事件 */
        z-index: 100;
      }
    }
    .container {
      margin-bottom: 0.2rem;
      margin-top: 0.18rem;
    }
    .container,
    .container1 {
      display: inline-flex;
      float: left;
      width: 100%;
      overflow: hidden;
      position: relative;
    }
    .container1 {
      position: relative;
    }
    .container .scrolling-content {
      display: inline-flex;
      animation: scroll 65s linear infinite;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.2rem;
        height: 0.7rem;
        background: #1b1d21;
        border-radius: 0.1rem;
        margin: 0 0.13rem;
        font-family: ArialMT, 'Arial';
        color: #fff;
        img {
          height: 100%;
        }
      }
    }
    .container1 .scrolling-content {
      display: inline-flex;
      animation: scroll1 65s linear infinite;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.2rem;
        height: 0.7rem;
        background: #1b1d21;
        border-radius: 0.1rem;
        margin: 0 0.13rem;
        font-family: ArialMT, 'Arial';
        color: #fff;
        img {
          height: 100%;
        }
      }
    }
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%);
      }
    }
    @keyframes scroll1 {
      0% {
        transform: translateX(-50%);
      }
      100% {
        transform: translateX(0);
      }
    }
  }
}
.investor-zh {
  .title {
    font-size: 0.3rem;
    font-family: MyNotoRegular;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-size: 0.14rem;
    font-weight: 400;
    font-style: normal;
  }
}
</style>
